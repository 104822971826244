@import "../../../../../../../styles/fonts";
@import "../../../../../../../styles/colors";

.container {
  max-height: 638px;

  .gridView {
    width: 100%;
    max-height: 638px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
    margin-top: 12px;
    z-index: 0;
    overflow-x: hidden;
    overflow-y: scroll;
    scroll-behavior: smooth;
    scrollbar-color: rgba($color-purple, .4) rgba($color-purple, .1);
    scrollbar-width: thin;
    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba($color-purple, .4);
      border-radius: 8px;
    }

    &::-webkit-scrollbar-track {
      background-color: rgba($color-purple, .1);
      border-radius: 8px;
    }
  }

  .create {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 197px;
    height: 193px;
    border: 2px dashed rgba(255, 255, 255, 1);
    border-radius: 8px;
    background: $purpleGradient;
    cursor: pointer;
    z-index: 0;
    &:after{
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 6px;
      background: white;
      z-index: -1;
    }

    img {
      width: 22.5px;
      height: 22.5px;
    }

    .title {
      margin: 12px 0;
      @extend %font_purple_paragraph
    }
  }
}
