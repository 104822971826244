.wrap {
  display: grid;
  grid-template-columns: 460px 420px;
  gap: 32px 50px;
  margin-bottom: 56px;

  .grid_item {
    display: flex;
    flex-direction: column;
    gap: 16px;

    h4 {
      margin-bottom: 8px;
      margin-top: 0;

      font-family: 'Nunito Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      color: #222222;
    }
  }

  input[type=text] {
    height: 51px;
  }
}

.dates {
  display: flex;
  gap: 16px;
}

.disabled {
  opacity: 0.5;
}