@import "../../../../../styles/colors";

.container {
  width: 438px;

  .viewControl {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    align-items: center;
  }

  .viewControlIcons {
    display: flex;
    align-items: flex-start;
    stroke: $color-gray-blue;
    .icon {
      margin-left: 15px;
    }
    .active{
      stroke: $color-lilac;
    }
  }
}

.scaleLogo {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: none;
  span {
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    color: #9b7ffc;
    margin-left: 5px;
  }
}