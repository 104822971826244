@import "../../../styles/colors.scss";


.container {
  display: none;
  transition: all 0.5s ease-in;
}

.backdrop {
  position: fixed;
  z-index: 5;
  height: 100%;
  width: 100%; // some bug..
  background-color: $color-transparent-gray;
  filter: blur(4px);
}

.show {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  height: calc(100vh - 1px);
  width: calc(100vw - 1px); // some bug..
}