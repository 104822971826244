.wrap {
  display: flex;
  gap: 8px;
  cursor: pointer;
}

.box {
  width: 20px;
  height: 20px;
}

.label {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;

  color: #222222;
}

.disabled {
  opacity: 0.5;
}