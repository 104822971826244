.code {
  margin-top: 20px;

  .inputs {
    display: flex;
    justify-content: space-between;

    input {
      padding: 12px 16px;
      border: 1px solid rgba(162, 171, 201, 0.5);
      border-radius: 8px;
      width: 74px;

      font-family: 'Nunito';
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 33px;
      color: #222222;
      text-align: center;
    }
  }

  &.error {
    .inputs input {
      border: 1px solid #FF5980;
    }

    .error_text {
      font-family: 'Nunito Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      color: #FF5980;
    }
  }
}