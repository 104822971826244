@import "../../../../../styles/colors.scss";
@import "../../../../../styles/fonts.scss";

// 1158px is card width
$left-space-size: calc((1158px - 100vw) / 2);
//763 is card heigh and 80 is position top value
$top-space-size: calc(((763px - 100vh) / 2) - 80px);

.container {
  transform: translate($left-space-size, $top-space-size);
}
.modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $color-white;
  height: 194px;
  width: 530px;
  z-index: 10;
  box-shadow: 8px 8px 40px $color-modal-shadow;
  border-radius: 8px;
}

.closeButton {
  position: absolute;
  background-repeat: no-repeat;
  // 32px is width button and 37 is margin right
  margin-left: calc(530px - 32px);
  margin-right: 52px;
  margin-top: 20px;
}

.header {
  margin-top: 40px;
  margin-bottom: 0;
  @extend %font_input;
}

.goInitialButton {
  margin-top: 40px;
  width: 269px;
  height: 52px;
  justify-content: center;
}
