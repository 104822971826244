.table {
  font-family: Nunito Sans;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  td {
    text-align: left;
  }

  .id {
    text-align: left;

    .number {
      color: var(--black, #8289A1);
    }

    .count {
      color: var(--gray-blue, #A2ABC9);
    }

    .date {
      color: var(--gray-blue, #222);
    }
  }

  .dedlineDate {
    text-align: left;

    .date {
      color: var(--gray-text, #8289A1);

    }

    .status {
      color: var(--green-text, #39C286);

      &.expired {
        color: var(--pink, #FF5980);
      }
    }
  }

  .not_enough {
    background-color: #ffdfe7;
  }

  .rates,
  .items_count,
  .balance {
    display: flex;
    flex-direction: column;
    align-items: stretch;

    padding: 0 !important;
    // border-left: 1px solid #EBECFF;

    .wrap {
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;

      padding: 12px 0;
      border-bottom: 1px solid #EBECFF;

      &:last-child {
        border-bottom: none;
      }
    }
  }

  .rates {
    text-align: left;
    border-left: 1px solid #EBECFF;

    .wrap {
      padding-left: 24px;

      .item {
        display: flex;
        flex-direction: column;
        gap: 4px;
        flex-grow: 1;

        .product {
          color: #9B7FFC;
          text-decoration: underline;
        }

        .code {
          color: var(--gray-blue, #A2ABC9);
        }
      }
    }
  }

}

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  margin-top: 20px;
  
  .info {
    color: var(--gray-dark-text, #5B637E);

    font-family: Nunito Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}