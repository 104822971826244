.wrap {
  margin-right: 30px;
  margin-bottom: 30px;
  // max-width: calc(100vw - 310px);
}

.stars {
  display: flex;
  align-items: center;
  gap: 3px;
}