.calendar_day {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: stretch;
  padding: 0px;
  gap: 4px;


  .calendar_day_title {
    height: 48px;

    background: linear-gradient(0deg, rgba(155, 127, 252, 0.2), rgba(155, 127, 252, 0.2)), #EBECFF;
    border-radius: 0px;

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 1;

    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;

    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    text-transform: uppercase;

    color: #9B7FFC;
  }

  .body {
    padding: 16px;

    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 23px;

    background: #EBECFF;
    border-radius: 0px 0px 0px 8px;



  }

  .body .content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    gap: 12px;

    background: #FFFFFF;
    border-radius: 0px 0px 8px 8px;

    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #222222;
  }

  .body .content .description_text {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #A2ABC9;
  }

  .body .content .period {
    display: flex;
    gap: 4px;
  }
}


.day_action {
  filter: drop-shadow(8px 8px 40px rgba(131, 103, 228, 0.2));

  cursor: pointer;

  .description {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 4px;
  }

  .title {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 16px;
    gap: 10px;
    height: 54px;

    border-radius: 8px 8px 0px 0px;

    color: #FFFFFF;
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
  }
}