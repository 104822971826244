@import "../../../styles/fonts.scss";
@import '../../../styles/backgrounds.scss';

.layout {
  display: flex;
  flex-direction: column;
  min-width: 1440px;
  // max-width: 1900px;
  width: 100%;
}

.contentLayoutGrid {
  display: flex;
  align-items: flex-start;
  align-content: stretch;
}

.content {
  flex-grow: 1;
  min-width: 0;
}

.mainFooter {
  display: flex;
  width: 100%;
  background-color: black;
  border: 1px solid black;
}

.v2 {
  padding-right: 30px;
  // .content {
  //   flex-grow: 1;
  // }

}