@import "../../../../../../../styles/fonts.scss";
@import "../../../../../../../styles/colors.scss";


.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 100%;

  padding-left: 30px;
  padding-right: 30px;
  margin-right: -80px;

  .content {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;

    margin-left: -10px;
    margin-right: -10px;
    z-index: 1;


    &:hover {
      .title {
        text-decoration: underline;
      }
    }
  }

  &.active {
    .content {
      background-color: #FFFFFF;
    }
  }


  &::before,
  &::after {
    content: "";
    background-repeat: no-repeat;
    background-size: auto;
    width: 50px;
    height: 100%;
  }

  &.active::before {
    background-position: left;
    background-image: url("../../../../../../../assets/icons/goods_list_tab_btn.svg");
  }

  &.active::after {
    background-position: right;
    background-image: url("../../../../../../../assets/icons/goods_list_tab_btn.svg");
  }

}

.title {
  white-space: nowrap;

  @extend %font_btn_navigate_goods_list;
}

.count_goods {
  margin-left: 7px;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $color-blue;
  background: rgba(71, 115, 235, 0.16);
  @extend %font_button_navigate_goods_numbers;
}