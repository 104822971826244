@import "src/styles/fonts";
@import "src/styles/animations";

.modal_container {
  position: fixed;
  top: 0;
  left: 0;

  font-family: 'Nunito Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;

  text-align: center;
  color: #222222;

  width: 100vw;
  height: 100vh;
  z-index: 100;
}

.modal_underflow {
  cursor: pointer;
  position: relative;
  display: block;

  width: 100vw;
  height: 100vh;

  background-color: rgba(213, 213, 213, 0.9);
  backdrop-filter: blur(5px);

  z-index: 101;
}

.content {
  animation: slider-open 0.5s forwards;
  position: fixed;
  top: 2%;
  left: 50%;
  transform: translateX(-50%) scale(0);
  width: 96vw;
  height: 96vh;
  overflow-y: auto;

  padding: 20px 50px;

  background-color: #FFFFFF;
  box-shadow: 8px 8px 40px rgba(131, 103, 228, 0.2);
  border-radius: 8px;

  z-index: 200;
  overflow: hidden;

  h1 {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    text-transform: uppercase;
    color: #222222;

    margin-top: 48px;
    margin-bottom: 40px;
  }
}

.head {
  max-width: 100%;
  position: relative;
  margin-bottom: 40px;
}

.filter {
  display: flex;
  gap: 24px;
  position: relative;
}

.filters {
  display: flex;
  gap: 24px;
  overflow-y: auto;
  margin-right: 420px;

  // overflow-y: scroll;
  scroll-behavior: smooth;
  scrollbar-color: rgba($color-purple, .4) rgba($color-purple, .1);
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    height: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba($color-purple, .4);
    border-radius: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: rgba($color-purple, .1);
    border-radius: 8px;
  }

}

.filters_panel {
  z-index: 1000;
  position: absolute;
  top: calc(100% + 11px);
  background: #FFFFFF;
  box-shadow: 8px 8px 40px rgba(131, 103, 228, 0.2);
  border-radius: 8px;
  padding: 24px;
  width: 80vw;
}

.search {
  position: absolute;
  right: 0;
  top: 0;
  input[type=text] {
    width: 416px;
  }
}

.products_list {
  position: absolute;
  top: 195px;
  bottom: 60px;
  left: 2%;
  width: 96%;
}

.footer {
  position: absolute;
  bottom: 60px;
  // width: 100%;
  // left: 0;
  left: calc(50% - 30px);

  // display: flex;
  // justify-content: center;
}