$transitionTime: 0.3s;
$chartWidth: 1200px;
$chartHeight: 400px;
$xLabelsHeight: 80px;
$yLabelsWidth: 70px;
$controlsHeight: 50px;


.chart_wrap {
  width: $chartWidth + $yLabelsWidth;
  height: $chartHeight + $xLabelsHeight + $controlsHeight;
  padding-left: $yLabelsWidth;
  padding-bottom: $xLabelsHeight;
  // padding: 50px;
  margin: auto;

  .chart_controls {
    display: flex;
    gap: 30px;
    align-items: center;
    justify-content: flex-end;
    // justify-content: flex-end;
    height: $controlsHeight;

    margin-top: 25px;
    margin-bottom: 40px;

    .warning {
      color: var(--pink, #FF5980);
      font-size: 14px;
      font-family: Nunito Sans;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-align: right;
    }

    &.not_switchable_series {
      margin-bottom: 12px;

      .serie_label {
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 300;
        font-size: 10px;
        line-height: 14px;
        color: #222222;
      }
    }

    .serie_cb {
      display: flex;
      align-items: center;
      gap: 6px;
    }
  }

  .chart {
    width: $chartWidth;
    height: $chartHeight;
    position: relative;
    // border: 1px dashed rgba(162, 171, 201, 0.2);
    // margin: 50px;
    // border: 1px solid;

    .yaxis_name {
      position: absolute;
      left: -42px;
      top: -21px;
      font-family: 'Nunito Sans';
      font-style: normal;
      font-weight: 300;
      font-size: 10px;
      line-height: 14px;
      color: #222222;
    }

    // svg {
    //   overflow: visible;
    // }

    .grid {
      .grid_line {
        stroke: rgba(162, 171, 201, 0.2);
        stroke-dasharray: 3 1;

        &.new_month {
          stroke: rgba(162, 171, 201, 1);
        }
      }
    }

    .grid_label {
      position: absolute;
      white-space: nowrap;

      font-family: 'Nunito Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 10px;
      line-height: 14px;

      .grid_label_year {
        font-weight: 300;
        margin-right: 4px;
      }


      &.grid_label_y {
        transform: translateX(-100%) translateY(-50%);
        padding-right: 6px;

        font-weight: 300;
        color: #222222;

        .grid_label_value {
          font-weight: 700;
        }

      }

      &.grid_label_x {
        // transform-origin: right center;
        transform-origin: right top;
        transform: translate(-100%) rotate(-45deg);

        &:nth-child(2n) {
          color: #A2ABC9;
        }
      }
    }

    .frame_line {
      stroke: rgba(162, 171, 201, 0.5);
      stroke-width: 1;
    }

    .line {
      // stroke: #39C286;
      stroke-width: 1;
      transition: all $transitionTime ease-out;

      &.active {
        stroke-width: 2;
      }
    }

    .chart_startdot,
    .chart_enddot {
      transition: all $transitionTime ease-out;
    }




    .cursor_line {
      stroke: #222222;
      stroke-width: 1;
    }

    .chart_overlay {
      width: 100%;
      height: 100%;
      position: absolute;
    }


    .chart_tooltip {
      pointer-events: none;
      position: absolute;
      transition: all $transitionTime ease-out;
      filter: drop-shadow(8px 8px 40px rgba(131, 103, 228, 0.2));
      z-index: 10;
      transform: translate(-50%, 10px);

      .tooltip_arrow {
        background: #FFFFFF;
        width: 14px;
        height: 14px;
        position: absolute;
        left: calc(50% - 7px);
        top: -7px;
        rotate: 45deg;
      }

      .tooltip_wrap {
        display: flex;
        flex-direction: column;
        gap: 4px;

        padding: 12px 16px;
        background: #FFFFFF;
        border-radius: 8px;

        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 300;
        font-size: 10px;
        line-height: 14px;
        color: #222222;
        text-align: center;

        white-space: nowrap;

        .price {
          font-weight: 700;
          font-size: 14px;
          line-height: 19px;
          align-items: center;
          color: #222222;
        }
      }
    }



    .chart_dot {
      transition: all $transitionTime ease-out;
      stroke-width: 3;
      stroke: #000;

      &.active {
        stroke-width: 8;
      }
    }
  }
}