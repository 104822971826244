.product_selector_slider {
  font-family: 'Nunito Sans';
  font-style: normal;

  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #222222;

  .search {
    display: flex;
    align-items: center;
    gap: 24px;

    .search_input {
      width: 450px;
    }
  }

  .history {
    margin-top: 23px;
  }
  
  .date_selector {
    margin-top: 48px;
    display: flex;
    align-items: center;
    
    gap: 24px;

    .period_selector {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    .dates {
      display: flex;
      align-items: center;
      gap: 24px;
    }
  }

  .btn {
    font-weight: 700;
    font-size: 14px;
    line-height: 140%;

    color: #CC8AFF;

    background: linear-gradient(310.28deg, #CC8AFF 0%, #8AB9FF 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;

    cursor: pointer;

    // &:hover {
    //   // text-decoration: underline;
    //   // border-bottom: 1px solid #CC8AFF;
    // }
  }

  .build_report {
    margin-top: 44px;
  }

}