@import "../../../../../styles/colors";
@import "../../../../../styles/fonts";

h1 {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  text-transform: uppercase;
  color: #222222;
}

.wrap {
  border: 1px solid rgba(162, 171, 201, 0.5);
  border-radius: 8px;

  height: 358px;
  padding: 16px 8px;
  
}

.info {
  padding: 0 8px;
  
  height: 100%;

  display: flex;
  flex-direction: column;
  gap: 18px;

  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 35px;

  text-align: left;

  overflow-y: auto;

  scrollbar-color: rgba($color-purple, .4) rgba($color-purple, .1);
  scrollbar-width: thin;
  scroll-behavior: smooth;

  user-select: none;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba($color-purple, .4);
    border-radius: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: rgba($color-purple, .1);
    border-radius: 8px;
  }

  .blue_text {
    color: #4773EB;
  }

  .red_text {
    color: #FF5980;
  }

  // .list {}
}