.wrap {

  .controls {
    display: flex;
    flex-direction: column;
    gap: 32px;
    align-items: flex-start;

    .period {
      display: flex;
      align-items: center;
      gap: 8px;

      color: var(--black, #222);
      font-size: 14px;
      font-family: Nunito Sans;
      font-weight: 600;

      .period_selector {
        display: flex;
        align-items: center;
        gap: 24px;
      }
    }
  }

  .report {
    margin-top: 32px;
  }

  .search_wrap {
    display: flex;
    align-items: center;
    gap: 24px;

    .search {
      width: 450px;
    }

    .open_serach_dialog {
      color: var(--unnamed, #9B7FFC);
      font-size: 14px;
      font-family: Nunito Sans;
      font-weight: 700;
      line-height: 140%;

      cursor: pointer;
    }
  }

  h1 {
    text-align: left;
    margin-top: 34px;
    margin-bottom: 34px;
  }


  .list {
    color: var(--gray-text, #A2ABC9);
    text-align: center;
    font-size: 12px;
    font-family: Nunito Sans;
    font-weight: 700;
  }

  .storage_title {
    // height: 36px;
    // word-wrap: normal;
    // text-overflow: ellipsis;
    white-space: normal;
    word-wrap: normal;
    width:100%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}