// .wrap {}

.content {
  display: flex;
  gap: 28px;
  flex-direction: column;
  align-items: flex-start;
}
.modalUnderflow {
  position: fixed;
  left: 0;
  top: 0;
  display: block;
  width: 100%;
  height: 100%;

  z-index: -1;
}

.limit {
  background: #FFFFFF;
  border-radius: 8px;
  border-color: #fff;
  outline: none;

  select {
    background: #FFFFFF;
    border-radius: 8px;
    border-color: #fff;
    outline: none;
    padding: 10px;
  }
}

.filter {
  h2 {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    text-transform: uppercase;
    color: #222222;
    margin-bottom: 8px;
    margin-top: 0;
    text-align: left;
  }

  .options {
    display: flex;
    gap: 16px;
    cursor: pointer;
    user-select: none;
    flex-wrap: wrap;
  }

  .filter_option {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 18px;

    color: #8289a1;

    border: 2px solid #FFFFFF;
    border-radius: 100px;

    display: flex;
    gap: 8px;
    align-items: center;
    height: 32px;
  }



  .remove_option {
    display: none;
    width: 24px;
    height: 24px;
  }

  .selectd_option,
  .filter_option:hover {
    color: #222222;
  }
  .filter_default_option.selectd_option {
    padding-right: 16px;
  }
  .selectd_option {
    border-color: #A2ABC9;
    padding-left: 16px;
    padding-right: 8px;

    .remove_option {
      display: block;
    }
  }

  .info {
    display: flex;
    align-items: center;
    font-style: italic;
    font-weight: 100;
    font-size: 12px;
    color: #8289a1a6;
  }
}

.footer {
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}