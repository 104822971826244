// .wrap {
//   display: flex;
//   align-items: center;
//   gap: 8px;

//   font-family: 'Nunito Sans';
//   font-style: normal;
//   font-weight: 600;
//   font-size: 14px;
//   line-height: 19px;
//   color: #222222;

//   z-index: 2;
// }


.wrap {
  display: flex;
  align-items: center;
  // gap: 8px;

  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #222222;

  z-index: 2;
}

.disabled {
  opacity: 0.5;
}


.date_input {
  display: flex;
  align-items: center;
  // width: 180px;
  &.fixed_width {
    width: 180px;
  }
  height: 51px;

  margin-left: 8px;
  padding-left: 16px;

  border: 1px solid rgba(162, 171, 201, 0.5);
  border-radius: 8px;

  &::after {
    content: "";
    width: 10px;
    height: 7px;
    position: absolute;
    right: 16px;
    background-image: url("./assets/input_arrow.svg");
  }
}

