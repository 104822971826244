.wrap {

  display: flex;
  flex-direction: column;
  gap: 24px;

  .search {
    width: 450px;
  }

  .info {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .warnings {
      color: var(--pink, #FF5980);
      /* text */
      font-family: Nunito Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;

      display: flex;
      flex-direction: column;
      gap: 6px;
    }

    a {
      color: #9B7FFC;
      font-family: Nunito Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      text-decoration-line: underline;
    }
  }

  .warehouse {
    width: 300px;
  }

  .controls {
    display: flex;
    justify-content: space-between;

    .filters {
      display: flex;
      gap: 2px;




      .btn {
        display: flex;
        padding: 7px 16px;
        align-items: center;
        gap: 8px;
        border-radius: 100px;
        border: 2px solid #A2ABC900;
        user-select: none;
        cursor: pointer;

        color: #A2ABC9;
        font-family: Nunito Sans;
        font-size: 13px;
        font-style: normal;
        font-weight: 700;

        &.selected {
          color: var(--black, #222);
          border-radius: 100px;
          border: 2px solid var(--gray-blue, #A2ABC9);
        }
      }
    }
  }

  .value_input {
    width: 120px !important;
  }

  .save_leftovers {
    display: flex;
    flex-direction: column;
    gap: 48px;
    align-items: center;

    .info {
      display: flex;
      flex-direction: column;
      gap: 2px;

      color: #5B637E;

      font-family: Nunito Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      text-align: center;
      li

      a {
        color: #9B7FFC;
        text-decoration-line: underline;
        cursor: pointer;
      }
    }
  }
}