@import "../../../../../../../../../styles/fonts.scss";

.boxes {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;

  padding-bottom: 24px;
  // overflow-x: auto;

  // scrollbar-color: rgba($color-purple, .4) rgba($color-purple, .1);
  // scrollbar-width: thin;
  // scroll-behavior: smooth;

  // &::-webkit-scrollbar {
  //   height: 6px;
  // }

  // &::-webkit-scrollbar-thumb {
  //   background-color: rgba($color-purple, .4);
  //   border-radius: 8px;
  // }

  // &::-webkit-scrollbar-track {
  //   background-color: rgba($color-purple, .1);
  //   border-radius: 8px;
  // }


  .box {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 316px;
    height: 280px;
    padding: 16px;
    gap: 6px;
    flex-shrink: 0;

    border-radius: 12px;
    background: var(--white, #FFF);
    box-shadow: 0px 4px 20px 0px rgba(131, 103, 228, 0.10);

    font-family: Nunito Sans;
    font-size: 12px;
    font-style: normal;
    user-select: none;
    position: relative;

    .menu {
      display: none;
      position: absolute;
      right: 16px;
      top: 16px;
      width: 242px;

      box-shadow: 0px 4px 20px 0px rgba(131, 103, 228, 0.10);
      background-color: #FFF;
      border-radius: 8px;
      overflow: hidden;

      .bg {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1000;
      }

      &.active {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        align-items: stretch;
      }

      .items {
        z-index: 1001;
        display: flex;
        flex-direction: column;

        .menu_item {
          &:hover {
            background: #EBECFF;
          }

          padding: 16px;
          color: var(--black, #222);
          font-family: Nunito Sans;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;

          display: flex;
          gap: 6px;
        }
      }
    }

    .head {
      display: flex;
      color: var(--gray-blue, #A2ABC9);
      font-family: Nunito Sans;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      justify-content: space-between;

      .head_body {
        display: flex;
        flex-direction: column;
        gap: 12px;

        .title {
          color: var(--black, #222);
          font-weight: 700;
        }
        .error {
          font-weight: bold;
          color: red;
        }
      }
    }

    .box_body {
      display: flex;
      flex-direction: column;
      // gap: 6px;
      flex-grow: 1;

      overflow-y: auto;



      scrollbar-color: rgba($color-purple, .4) rgba($color-purple, .1);
      scrollbar-width: thin;
      scroll-behavior: smooth;

      &::-webkit-scrollbar {
        width: 6px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: rgba($color-purple, .4);
        border-radius: 8px;
      }

      &::-webkit-scrollbar-track {
        background-color: rgba($color-purple, .1);
        border-radius: 8px;
      }


      &.empty {
        align-items: center;
        justify-content: center;

        .info {
          color: var(--gray-blue, #A2ABC9);
          font-weight: 400;
          height: 100px;
        }
      }
    }

    .item .arrow_btn {
      display: none;
    }
  }
}

.item {
  width: 264px;
  padding: 12px;
  display: flex;
  justify-content: space-between;

  border-radius: 9px;
  border: 1px solid rgba(162, 171, 201, 0.50);
  background: var(--white, #FFF);

  font-family: Nunito Sans;
  font-size: 13px;
  font-style: normal;

  touch-action: none;
  user-select: none;
  cursor: grab;

  margin-bottom: 6px;
  
  .item_body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;
  }

  &.overlay {
    background: #F5F3FF;
    // cursor: grabbing;
    .arrow_btn {
      display: none;
    }
  }

  // &.active {
  //   display: none;
  // }

  .name {
    color: var(--gray-dark-text, #5B637E);
    font-weight: 600;
    text-decoration: underline;
  }

  .code {
    color: var(--gray-blue, #A2ABC9);
    font-weight: 400;
  }

  &:hover {
    border: 1px solid #A2ABC9;
    background: var(--white-bg, #F8F9FC);
    // border-radius: 9px;
    // border: 1px solid rgba(162, 171, 201, 0.50);
    // background: var(--white, #FFF);
    // box-shadow: 0px 4px 40px 0px rgba(131, 103, 228, 0.20);
  }
}

.box .item,
.order_items .item {
  &.active {
    display: none;
  }

  &.target_place {
    display: flex;
    background: var(--white-bg, #F8F9FC) !important;
    opacity: 0.5;

    .arrow_btn {
      display: none;
    }
    .name,
    .code {
      color: #00000000;
    }
  }
}


.order {

  border-bottom: 1px solid #EBECFF;

  .create_box_btn {
    display: flex;
    align-items: center;
    // justify-content: space-between;
    gap: 5px;
    cursor: pointer;
    margin-bottom: 16px;

    color: var(--gray-blue, #A2ABC9);
    font-family: Nunito Sans;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .order_number {
    color: var(--black, #222);
    font-family: Nunito Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .itemslist_title {
    color: var(--gray-text, #A2ABC9);
    font-family: Nunito Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    margin-bottom: 20px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    .btn {
      cursor: pointer;
    }
  }

  .order_body {
    display: flex;
    align-items: stretch;
    padding: 26px 0;

    .order_items {
      display: flex;
      flex-direction: column;
      // gap: 6px;
      width: 264px;
    }

    .line {
      width: 1px;
      background-color: #EBECFF;
      margin: 0 24px;
      flex-shrink: 0;
    }
  }
}

// .newboxbuilder_body {
//   // display: flex;
//   // flex-direction: column;
//   // gap: 53px;
//   // align-items: center;

//   .inputs {
//     display: flex;
//     gap: 24px;
//     flex-wrap: wrap;

//     .input {
//       width: 105px;
//     }
//   }
// }