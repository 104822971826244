.menu {

  display: none;
  flex-direction: column;
  margin: 0;
  padding: 0;
  gap: 12px;

  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  text-align: left;

  user-select: none;


  // max-height: 0;
  // transition: max-height 0.5s ease-out;
  overflow: hidden;




  &.lvl-0 .item_head {
    font-weight: 700;
    color: #222222;
    text-transform: uppercase;
  }

  &.lvl-1 .item_head {
    font-weight: 600;
    color: #8289A1;
    text-transform: none;

    &.active, &.active_link {
      color: #222222;
    }
  }


  .menu_item {
    display: flex;
    flex-direction: column;

    &:first-child {
      margin-top: 20px;
    }

    &:last-child {
      margin-bottom: 20px;
    }

    .item_head {
      display: flex;
      align-items: center;
      position: relative;
      gap: 16px;
      cursor: pointer;
      padding-right: 12px;
      text-decoration: none;

      &.closable::after {
        content: '';
        display: block;
        width: 10px;
        height: 10px;
        background-image: url('../assets/v.svg');
        background-position: center;
        background-repeat: no-repeat;
        position: absolute;
        right: 0;

        transition: transform 0.2s;
      }

      &.active.closable::after {
        transform: scale(1, -1);
      }

      &.has_notification {
        .menu_icon {
          display: none;
        }
      }

    }


  }


  &.open {
    display: flex;

    // max-height: 3000px;
    // transition: max-height 0.5s ease-in;
  }

}


.menu_icon {
  width: 16px;
  height: 16px;
  flex-shrink: 0;

  background-position: center;
  background-repeat: no-repeat;

  &.has_notification::after {
    content: '';
    position: relative;
    display: block;

    height: 14px;
    width: 14px;
    // background: #333;
    background-image: url("../assets/n.svg");
    left: 50%;
    bottom: 50%;
  }

  &.home {
    background-image: url('../assets/home.svg');
    
    &.active, .active_link & {
      background-image: url('../assets/home_active.svg');
    }
  }

  &.product {
    background-image: url('../assets/product.svg');

    &.active, .active_link & {
      background-image: url('../assets/product_active.svg');
    }
  }

  &.price {
    background-image: url('../assets/price.svg');

    &.active, .active_link & {
      background-image: url('../assets/price_active.svg');
    }
  }

  &.logistics {
    background-image: url('../assets/logistics.svg');

    &.active, .active_link & {
      background-image: url('../assets/logistics_active.svg');
    }
  }

  &.analytics {
    background-image: url('../assets/analytics.svg');

    &.active, .active_link & {
      background-image: url('../assets/analytics_active.svg');
    }
  }

  &.statistic {
    background-image: url('../assets/statistic.svg');

    &.active, .active_link & {
      background-image: url('../assets/statistic_active.svg');
    }
  }

  &.warehouses {
    background-image: url('../assets/location.svg');

    &.active, .active_link & {
      background-image: url('../assets/location_active.svg');
    }
  }

  &.marketing {
    background-image: url('../assets/rocket.svg');

    &.active, .active_link & {
      background-image: url('../assets/rocket_active.svg');
    }
  }

  &.account {
    background-image: url('../assets/user.svg');

    &.active, .active_link & {
      background-image: url('../assets/user_active.svg');
    }
  }

  &.employees {
    background-image: url('../assets/employees.svg');

    &.active, .active_link & {
      background-image: url('../assets/employees_active.svg');
    }
  }

  &.about {
    background-image: url('../assets/about.svg');

    &.active, .active_link & {
      background-image: url('../assets/about_active.svg');
    }
  }

  &.documents {
    background-image: url('../assets/documents.svg');

    &.active, .active_link & {
      background-image: url('../assets/documents_active.svg');
    }
  }

  &.schedule {
    background-image: url('../assets/schedule.svg');

    &.active, .active_link & {
      background-image: url('../assets/schedule_active.svg');
    }
  }

  &.certificate {
    background-image: url('../assets/certificate.svg');

    &.active, .active_link & {
      background-image: url('../assets/certificate_active.svg');
    }
  }

  &.instruction {
    background-image: url('../assets/instruction.svg');

    &.active, .active_link & {
      background-image: url('../assets/instruction_active.svg');
    }
  }

  &.accounting {
    background-image: url('../assets/accounting.svg');

    &.active, .active_link & {
      background-image: url('../assets/accounting_active.svg');
    }
  }

  &.what_to_sell {
    background-image: url('../assets/what_to_sell.svg');

    &.active, .active_link & {
      background-image: url('../assets/what_to_sell_active.svg');
    }
  }

  &.notifications {
    background-image: url('../assets/notifications.svg');

    &.active, .active_link & {
      background-image: url('../assets/notifications_active.svg');
    }
  }

  &.chat {
    background-image: url('../assets/chat.svg');

    &.active, .active_link & {
      background-image: url('../assets/chat_active.svg');
    }
  }

}