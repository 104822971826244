@import "../../../styles/fonts.scss";
@import "../../../styles/colors.scss";

$purpleGradient: linear-gradient(92.93deg, $color-pink 2.99%, $color-purple 94.03%);

.container {
  display: flex;
  width: max-content;
  align-items: center;
  justify-content: space-between;
  height: 22px;
  cursor:pointer;
}

.border {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 15px;
  background: $purpleGradient;
  border-radius: 50%;
  pointer-events: none;
}

.radioInput {
  height: 9px;
  width: 9px;
  background-image: url('../../../assets/icons/disabledCheckInputIco.svg');
  background-repeat: no-repeat;
  background-size: contain;
}

.checked {
  background-image: url('../../../assets/icons/activeCheckInputIco.svg');
}

.title {
  @extend %font_input;
  margin: 0;
  margin-left: 11px;
  color: $color-purple-p;
}