@import "../../../../../styles/fonts.scss";
@import "../../../../../styles/colors.scss";

.container {
  padding: 195px 0 64px 60px;
}

.input {
  width: 400px;
  margin-top: 24px;
  &:first-child {
    margin-top: 0;
  }
}

.error {
  position: absolute;
  margin-top: 24px;
}

.buttonsContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 173px;
  width: 100%;
}

.disableButton {
  background-color: transparent;
  padding: 0;
  border: 0;
  margin-right: 32px;
  @extend %font_gray_blue_p;
  color: $color-lilac;
  &:hover {
    text-decoration: underline;
  }
}

.confirmButton {
  width: 139px;
  justify-content: center;
}

@media screen and (max-height: 900px) {
  .container {
    padding: 130px 0 0px 45px;
  }
  .input {
    width: 350px !important;
    margin-top: 15px;
  }
  .buttonsContainer {
    margin-top: 100px;
  }
}

@media screen and (max-height: 770px) {
  .container {
    padding: 80px 0 0px 25px;
  }
  .input {
    width: 250px !important;
    margin-top: 10px;
    height: min-content;
    h2 {
      font-size: 11px !important;
    }
    p {
      padding: 6px 10px;
      min-height: 30px !important;
      font-size: 10px !important;
      line-height: unset;
    }
    input {
      padding: 6px 10px;
      height: 30px !important;
      font-size: 10px !important;
      line-height: unset;
      width: 100% !important;
    }
  }
  .buttonsContainer {
    margin-top: 80px;
  }
  .disableButton {
    font-size: 12px;
  }
  .confirmButton {
    width: 100px;
    padding: 8px 16px;
    height: 40px;
    justify-content: center;
  }
}