.contentWrapper {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    // padding: 30px 25px;
    max-width: 1200px;
}

// .tabs {
//     display: flex;
//     list-style-type: none;
//     text-align: center;
//     align-items: center;
//     color: #a2abc9;

//     li {
//         padding: 15px;
//         cursor: pointer;
//         position: relative;
//     }
// }

// .tabsAcrive {
//     transition: 0.3s;
//     background-color: #ffff;
//     color: #222222;
//     border-top-right-radius: 20px;
//     border-top-left-radius: 20px;
//     padding: 15px;
//     &::after {
//         width: 0;
//         height: 0;
//          border-style: solid;
//         border-width: 0 5px 26px 5px;
//         border-color: transparent transparent white transparent;
//         position: absolute;
//         content: ' ';
//         right: -5px;
//         bottom: 5px;
//         margin-top: 8px;
//         z-index: -1;
//     }
//     &::before {
//         width: 0px;
//         height: 0px;
//         border-style: solid;
//         border-width: 0 5px 26px 5px;
//         border-color: transparent transparent white transparent;
//         position: absolute;
//         content: ' ';
//         left: -5px;
//         bottom: 5px;
//         z-index: -1;
//         // width: 50px;
//         // height: 50px;
//         // left: -41px;
//         // bottom: 0;
//         // background-repeat: no-repeat;
//         // background-position: center;
//         // background-size: contain;
//         // background-image: url("../../../../../assets/icons/bn.png");
//     }
// }

// .tabsWrapper {
//     width: 600px;
//     margin-bottom: -20px;
// }
