@import "../../../../../../../../../styles/fonts.scss";

.wrap {
  position: fixed;
  top: 0;
  left: 0;

  font-family: 'Nunito Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;

  // text-align: center;
  color: #222222;

  width: 100vw;
  height: 100vh;
  z-index: 100;

  .modalUnderflow {
    position: relative;
    display: block;

    width: 100vw;
    height: 100vh;

    background-color: rgba(213, 213, 213, 0.9);
    backdrop-filter: blur(5px);

    z-index: 101;
  }

  .content {
    animation: slider-open 0.5s forwards;
    position: fixed;
    top: 2%;
    left: 50%;
    transform: translateX(-50%); // scale(0);
    width: 1400px;
    height: 95vh;

    padding: 40px 20px;

    background-color: #FFFFFF;
    box-shadow: 8px 8px 40px rgba(131, 103, 228, 0.2);
    border-radius: 8px;

    // max-height: 80vh;

    z-index: 200;

    h2 {
      color: var(--black, #222);
      text-align: center;
      font-family: Nunito Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      text-transform: uppercase;
      margin-top: 0;
    }
  }

}

.maps {
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;



  .map_wrap {
    position: relative;

    .dp_info {
      position: absolute;
      right: 0;
      top: 0;
      width: 299px;
      // bottom: 0;
      max-height: 100%;
      overflow-y: auto;

      // overflow-y: scroll;
      scroll-behavior: smooth;
      scrollbar-color: rgba($color-purple, .4) rgba($color-purple, .1);
      scrollbar-width: thin;

      &::-webkit-scrollbar {
        width: 8px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: rgba($color-purple, .4);
        border-radius: 8px;
      }

      &::-webkit-scrollbar-track {
        background-color: rgba($color-purple, .1);
        border-radius: 8px;
      }

      border-radius: 10px;
      background: #FFF;
      box-shadow: 0px 4px 40px 0px rgba(45, 27, 105, 0.10);
    }

    .map {
      margin: 0;
      padding: 0;
      border: 0;
      width: 100%;
      height: 350px;
    }
  }

  .search {
    width: 400px;
  }

  .deliverypoints {
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    gap: 13px;
    flex-wrap: wrap;
    align-items: stretch;
    max-height: 350px;
    overflow-y: auto;


    // overflow-y: scroll;
    scroll-behavior: smooth;
    scrollbar-color: rgba($color-purple, .4) rgba($color-purple, .1);
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba($color-purple, .4);
      border-radius: 8px;
    }

    &::-webkit-scrollbar-track {
      background-color: rgba($color-purple, .1);
      border-radius: 8px;
    }

    .dp {
      border-radius: 8px;
      border: 1px solid rgba(162, 171, 201, 0.50);
      width: 244px;
      cursor: pointer;


      display: flex;
      padding: 16px;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      justify-content: space-between;

      // .dp_info {
      //   display: flex;
      //   flex-direction: column;
      //   gap: 4px;
      // }

      &.selected {
        border: 1px solid #9B7FFC;
        background: rgba(155, 127, 252, 0.12);
      }

      .title {
        color: var(--black, #222);
        font-family: Nunito Sans;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 140%;
        /* 16.8px */
        text-transform: uppercase;
      }

      .address {
        color: var(--gray-dark-text, #5B637E);
        font-family: Nunito Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        /* 19.6px */
      }

      .work_times {

        .work_time {
          color: var(--black, #222);
          font-family: Nunito Sans;
          font-size: 10px;
          font-style: normal;
          font-weight: 300;
          line-height: 140%;
          /* 14px */
          display: flex;
          gap: 12px;

          .days {
            color: var(--black, #222);
            font-family: Nunito Sans;
            font-size: 10px;
            font-style: normal;
            font-weight: 300;
            line-height: 140%;
            /* 14px */
            width: 34px;
          }
        }
      }
    }
  }

  .ok_btn {
    margin: auto;
  }
}