@import '../../../styles/fonts.scss';
@import '../../../styles/colors.scss';

.wrap {}

.text_above,
.text_below {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-size: 13px;
  color: #A2ABC9;
}

.text_above {
  font-weight: 700;
  line-height: 18px;
  display: block;
  margin-bottom: 8px;
}

.required {
  color: #FF5980;
  margin-left: 4px;
}

.text_below {
  font-weight: 600;
  line-height: 140%;
  display: block;
  margin-top: 4px;
}

.input {
  display: flex;
  align-items: center;
  position: relative;

  input {
    width: 100%;
    height: 43px;

    border: 1px solid rgba(162, 171, 201, 0.5);
    border-radius: 8px;
    padding: 12px 16px;
    outline: none;

    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #5B637E;
  }

  .icon {
    width: 16px;
    height: 16px;
    margin-left: -32px;
  }
  .postfix {
    position: absolute;
    margin-left: 16px;
    // margin-top: 1px;
    pointer-events: none;
    white-space: pre;

    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #5B637E;
  }
}

.large {
  input {
    height: 51px;
  }
}

.hint {
  margin-left: 12px;
}