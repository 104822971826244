.wrap {
  margin-right: 30px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  user-select: none;
}

.info {
  font-family: 'Nunito Sans';
  display: flex;
  gap: 24px;
}

.info_item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 8px;
  width: 50%;

  background: rgba(255, 255, 255, 0.8);
  border-radius: 8px;

  .title {
    display: flex;
    gap: 10px;
    align-items: center;

    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 140%;
    color: #FF5980;

    img {
      width: 24px;
      height: 24px;
    }
  }
}

.calendar {
  background: rgba(255, 255, 255, 0.8);
  border-radius: 8px;
  padding: 16px;
}

.row {
  display: flex;
  gap: 16px;
  align-items: center;
  user-select: none;
}

.year_and_month {
  margin-bottom: 40px;
}

.period_and_date {}

.year_selector {
  max-width: 200px;
  min-width: 85px;
}

// .option_selector {
//   display: flex;
//   flex-direction: row;
//   align-items: flex-start;
//   padding: 0px;
//   gap: 8px;
//   flex-grow: 1;
//   .option {
//     height: 51px;
//     background: #EBECFF;
//     border-radius: 4px;
//     display: flex;
//     flex-direction: row;
//     justify-content: center;
//     align-items: center;
//     padding: 12px 16px;
//     flex: none;
//     align-self: stretch;
//     flex-grow: 1;
//     font-family: 'Nunito Sans';
//     font-style: normal;
//     font-weight: 600;
//     font-size: 14px;
//     line-height: 19px;
//     text-align: right;
//     color: #222222;
//     cursor: pointer;
//     max-width: 120px;
//   }
//   .option:hover,
//   .option.selected {
//     background: #9B7FFC;
//     color: #FFFFFF;
//   }
// }

.switch_wrap {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  text-align: center;

  color: #222222;

  img {
    cursor: pointer;
  }
}

.prev_next_switch {
  display: flex;
  flex-direction: row;
  gap: 12px;
}

.controls {
  margin-bottom: 8px;
}




.calendar_item:nth-child(1n) {
  .title {
    background: linear-gradient(0deg, rgba(254, 87, 171, 0.5), rgba(254, 87, 171, 0.5)), #9B7FFC;
  }
}

.calendar_item:nth-child(2n) {
  .title {
    background: linear-gradient(0deg, rgba(254, 87, 171, 0.3), rgba(254, 87, 171, 0.3)), #9B7FFC;
  }
}

.calendar_item:nth-child(3n) {
  .title {
    background: #9B7FFC;
  }
}

.calendar_item:nth-child(4n) {
  .title {
    background: linear-gradient(0deg, rgba(98, 140, 253, 0.5), rgba(98, 140, 253, 0.5)), #9B7FFC;
  }
}

.calendar_item:nth-child(5n) {
  .title {
    background: linear-gradient(0deg, rgba(50, 187, 252, 0.5), rgba(50, 187, 252, 0.5)), #9B7FFC;
  }
}

.calendar_item:nth-child(6n) {
  .title {
    background: linear-gradient(0deg, rgba(8, 248, 157, 0.4), rgba(8, 248, 157, 0.4)), #9B7FFC;
  }
}

.calendar_item:nth-child(7n) {
  .title {
    background: linear-gradient(0deg, rgba(8, 248, 157, 0.55), rgba(8, 248, 157, 0.55)), #9B7FFC;
  }
}

.calendar_item:nth-child(8n) {
  .title {
    background: linear-gradient(0deg, rgba(50, 187, 252, 0.2), rgba(50, 187, 252, 0.2)), #37CB8D;
  }
}