@import "../../../styles/colors.scss";
@import "../../../styles/fonts.scss";

.question {
  position: relative;
  display: block;
  height: 20px;
  width: 20px;
  text-align: center;
  user-select: none;
  white-space: pre-wrap;
  background-image: url("../../../assets/icons/btnAskLogo.svg");
  background-repeat: no-repeat;
  background-size: contain;
  transition: background 0.2s linear;
  transition-delay: 0.3s;

  border-radius: 50%;

  &:hover {
    transition-delay: 0s;
    background-color: #FF5980;
    background-image: url("../../../assets/icons/btnAskLogo__active.svg");
    background-repeat: no-repeat;
  }

  &:before {
    z-index: 7;
    position: absolute;
    visibility: hidden;
    content: attr(data-hover);
    white-space: pre-wrap;
    text-align: left;
    width: max-content;
    top: calc(100% + 8px);
    max-width: 555px;
    transition: all 0.2s linear;
    transition-delay: 0.3s;
    @extend %font_hint;
    color: $color-input;

    box-shadow: 8px 8px 40px rgba(131, 103, 228, 0.2);
    border-radius: 8px;
  }
}

.question:hover:before {
  height: fit-content;
  visibility: visible;
  background: $color-white;
  border-radius: 8px;
  padding: 24px;
  min-height: 15px;
  transition-delay: 0s;
  bottom: 10px;
}
