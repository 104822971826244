@import '../../../../../styles/fonts.scss'; 

.container {
  display: flex;
  align-items: center;
  margin: 0;
}

.middleText {
  @extend %font_gray_dark_paragraph;
  margin: 0;
  width:fit-content;
  margin: 0 5px;
}

.paragraphButton {
  padding-bottom: 1.7px;
}