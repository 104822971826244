@import "../../../styles/fonts";


.wrap {
  display: flex;
  flex-direction: column;

  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #222222;

  user-select: none;

  width: 100%;

  .background {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    // width: 100vw;
    // height: 100vh;

    z-index: 1001;
  }

  .select_wrap {
    // min-height: 73px;
    position: relative;
  }

  .select {
    width: 100%;
    position: absolute;
    transition: 0.2s;
  }

  .menu_wrap {
    height: 51px;
  }

  .label {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 120%;
    color: #A2ABC9;

    display: flex;
    gap: 5px;

    margin-bottom: 6px;

  }

  .selected_value {
    height: 51px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 16px;
    padding-right: 16px;
    position: relative;
    background-color: #FFFFFF;

    cursor: pointer;

    border: 1px solid rgba(162, 171, 201, 0.5);
    border-radius: 8px;

    input {
      border: 0;
      outline: none;
      font-family: 'Nunito Sans';
      font-style: normal;
      width: 100%;
      height: 100%;
    }

    &.empty_value {
      color: rgba(91, 99, 126, 0.5);
      // font-style: italic;
    }

    &::after {
      // position: absolute;
      content: "";
      display: block;
      width: 12px;
      height: 12px;
      // top: 45%;
      // right: 10px;
      // transform: translateY(-50%);
      cursor: pointer;

      background-image: url("./assets/openArrow.png");
      background-size: contain;
      background-repeat: no-repeat;

      transition: transform 0.2s;
    }
  }

  &.readonly {

    .selected_value {
      cursor: text;
      user-select: text;
      border-radius: 0;
      border: none;
      border-bottom: 1px solid rgba(162, 171, 201, 0.5);
      padding-left: 0;
      height: 40px;

      &::after {
        display: none;
      }
    }
  }

  .empty_list {
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 0.5;
  }

  .list {
    display: none;
    width: 100%;
    background: #FFFFFF;

    border-radius: 0 0 8px 8px;
    // box-shadow: 8px 8px 40px rgba(131, 103, 228, 0.2);

    max-height: 300px;
    overflow-y: auto;

    scroll-behavior: smooth;
    scrollbar-color: rgba($color-purple, .4) rgba($color-purple, .1);
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba($color-purple, .4);
      border-radius: 8px;
    }

    &::-webkit-scrollbar-track {
      background-color: rgba($color-purple, .1);
      border-radius: 8px;
    }

    .option {
      // height: 40px;
      padding: 16px;
      display: flex;
      align-items: center;

      cursor: pointer;

      &:hover {
        background-color: #9B7FFC;
      }
    }

    .spinner {
      display: flex;
      gap: 4px;
      // justify-content: space-evenly;  
      align-items: center;
      width: 32px;
      margin: auto;
      height: 50px;

      @keyframes dotPulse {
        0% {
          background-color: #EEE6F7;
        }

        30% {
          background-color: #A984D5;
        }

        // 60% {
        //   background-color: #D4C1EA;
        // }

        100% {
          background-color: #EEE6F7;
        }
      }

      .dot {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        animation: dotPulse .9s infinite;
        background-color: #EEE6F7;
      }

      .dot:nth-child(2) {
        animation-delay: 0.3s;
        background-color: #EEE6F7;
      }

      .dot:nth-child(3) {
        animation-delay: .6s;
        background-color: #EEE6F7;
      }

    }
  }

  .error {
    height: 20px;

    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #FF5980;
  }

  &.required:not(.readonly) {

    .label {
      // @extend %font_input;

      &::after {
        content: "*";
        position: relative;
        // top: 0;
        // right: -10px;
        // height: 0;
        color: red;
        // z-index: 10;
      }
    }
  }

  &.disabled .selected_value {
    opacity: 0.5;
  }

  &.open {

    .select {
      z-index: 1002;

      background: #FFFFFF;
      box-shadow: 8px 8px 40px rgba(131, 103, 228, 0.2);
      border-radius: 8px;
    }

    .selected_value {
      border: none;
      border-radius: 8px 8px 0 0;

      &::after {
        // rotate: 180deg;
        transform: scale(1, -1);
        // transform: translateY(50%);
      }
    }

    .list {
      display: block;
    }

    .background {
      display: block;
    }
  }

  &.has_error {
    .selected_value {
      border: 1px solid #FF5980;
    }
  }

  // &.hide_error .select_wrap {
  // min-height: 60px;
  // }

  &.mini {
    .selected_value {
      border: none;
    }
  }

  &.reverse {
    .select {
      display: flex;
      flex-direction: column-reverse;
      bottom: 0;
    }

    .list {
      border-radius: 8px 8px 0 0;
    }
  }
}





// .wrapper {
//   width: 100%;
// }

// .selectWrapper {
//   width: 98%;
//   position: relative;
//   pointer-events: all;

//   &::after {
//     position: absolute;
//     content: "";
//     display: block;
//     width: 12px;
//     height: 12px;
//     top: 45%;
//     right: 10px;
//     transform: translateY(-50%);
//     cursor: pointer;

//     background-image: url("./assets/openArrow.png");
//     background-size: contain;
//     background-repeat: no-repeat;
//   }
// }

// .select {
//   width: 100%;
//   // min-width: 180px;
//   // max-width: 542px;
//   border: 1px solid rgba(162, 171, 201, 0.5);
//   border-radius: 8px;
//   padding: 7px 10px;
//   height: 50px;
//   outline: none;
//   appearance: none;
//   @extend %font_input;

//   &:hover {
//     cursor: pointer;
//   }
// }

// .label {
//   position: relative;
//   font-size: 13px;
//   color: #a2abc9;

//   @extend %font_input;
// }



// .option {
//   @extend %font_input;
// }