@import "../../../../../styles/fonts.scss";
@import "../../../../../styles/colors.scss";

.addresses {

  font-family: 'Nunito Sans';
  font-style: normal;


  h1 {
    margin: 0 0 24px 0;
    text-align: left;
  }

  .row {
    display: flex;
    gap: 52px;

    .address_pannel {
      flex-grow: 1;
    }
  }

  .addresses_group {
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-bottom: 56px;
  }

  .address_pannel {
    .label {
      color: rgb(162, 171, 201);
      font-size: 13px;
      font-weight: 700;
    }

    .inputs {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .inputs_group {
        display: flex;
        gap: 20px;
      }
    }

    .input {
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 124px;

      .input_wrap {
        box-sizing: border-box;
        border: 1px solid rgba(162, 171, 201, 0.5);
        border-radius: 8px;
        padding: 16px;

        input {
          width: 100%;
        }
      }
    }

  }

  input {
    outline: none;
    border: none;
    background-color: #ffffff00;

    color: rgb(34, 34, 34);
    font-family: Nunito Sans;
    font-size: 14px;
    font-weight: 600;
  }

  .fulladdresses {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .input_wrap {
      border-radius: 8px;
      box-shadow: 8px 8px 40px 0px rgba(131, 103, 228, 0.2);
      background: rgb(255, 255, 255);

      padding: 16px;


      display: flex;
      flex-direction: column;
      gap: 16px;

      color: rgb(34, 34, 34);
      font-family: Nunito Sans;
      font-size: 14px;
      font-weight: 600;
    }
  }
}


.info {
  display: flex;
  flex-direction: column;

  gap: 16px;
}