@import "../../../../../styles/colors";
@import "../../../../../styles/fonts";

.cards {

  .grid_head {
    margin-bottom: 12px;

    .title {
      font-family: 'Nunito Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      text-transform: uppercase;
      color: #222222;
    }

    .update_time {
      font-family: 'Nunito Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      margin-left: 16px;
      color: #A2ABC9;
    }
  }

  .grid_body_wrap {
    background: #EBECFF80;
    border-radius: 8px;
    padding-left: 12px;
    padding-right: 16px;
    padding-top: 24px;
    padding-bottom: 24px;

    row-gap: 24px;
    column-gap: 12px;

    min-height: 30vh;
  }

  .grid_body {

    padding-right: 8px;
    padding-left: 12px;
    // padding-top: 24px;
    // padding-bottom: 24px;

    overflow-y: auto;
    max-height: 500px;

    scrollbar-color: rgba($color-purple, .4) #FFFFFF;
    scrollbar-width: thin;
    scroll-behavior: smooth;

    user-select: none;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba($color-purple, .4);
      border-radius: 8px;
    }

    &::-webkit-scrollbar-track {
      background-color: #FFFFFF;
      border-radius: 8px;
    }

    .gag {
      font-family: 'Nunito Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      margin-left: 16px;
      color: #A2ABC9;

      text-align: center;
      margin-top: 100px;
    }

    .grid_row {
      height: 245px;
      margin-bottom: 24px;
      transition: height .1s ease-in-out;

      &.selected {
        height: 293px;
      }
    }

    .grid_cel {
      height: 100%;
      width: 50%;
      padding-left: 6px;
      padding-right: 6px;
    }
  }

  .grid_row {
    display: flex;
  }

  .grid_cel {
    flex: 1 1 0;
  }
}