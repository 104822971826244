@import "../../../../../styles/colors";
@import "../../../../../styles/fonts";

h1 {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  text-transform: uppercase;
  color: #222222;
}

.info {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #5B637E;

  margin-bottom: 30px;
}

.stars {
  display: flex;
  white-space: nowrap;
  align-items: center;

  gap: 16px;
  margin-bottom: 32px;

  label {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    color: #000000;
  }

  .select {
    width: 300px;
  }
}

.textarea_label {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 18px;
  color: #A2ABC9;

  text-align: left;
  margin-bottom: 8px;
}

.textarea {
  width: 840px;
  height: 120px;

  resize: none;
  padding: 16px;

  border: 1px solid rgba(162, 171, 201, 0.5);
  border-radius: 8px;
  outline: none;

  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #222222;
}

.templates {
  margin-top: 32px;
  display: flex;
  white-space: nowrap;
  gap: 16px;
  align-items: center;

  .templates_label {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    color: #000000;
  }

  .list {
    display: flex;
    gap: 16px;
    align-items: center;
    overflow-y: auto;

    padding-top: 5px;
    padding-bottom: 5px;

    scrollbar-color: rgba($color-purple, .4) rgba($color-purple, .1);
    scrollbar-width: thin;
    scroll-behavior: smooth;

    &::-webkit-scrollbar {
      height: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba($color-purple, .4);
      border-radius: 8px;
    }

    &::-webkit-scrollbar-track {
      background-color: rgba($color-purple, .1);
      border-radius: 8px;
    }

    .template_label {
      font-family: 'Nunito Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 19px;
      color: #9B7FFC;

      padding: 8px 16px;
      gap: 8px;

      border: 2px solid #9B7FFC;
      border-radius: 100px;

      cursor: pointer;
    }

  }
}