@import "../../../../../styles/fonts.scss";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.darkGrayFont {
  width: 363px;
  height: 40px;
  margin: 24px 0;
  @extend %font_gray_dark_paragraph;
}

.sendAgainFont {
  @extend %font_gray_dark_paragraph;
  font-size: 16px;
}

.button {
  margin-top: 21px;
  justify-content: center;
}

.underline {
  text-decoration: underline;
  text-decoration-skip-ink: none;
  cursor: default;
}

.sendPushButton {
  margin-top: 54px;
}

.sendSmsButton {
  margin-top: 10px;
}

.errorText {
  position: absolute;
  margin-top: 164px;
}

.sendSMS {
  @extend %font_gray_dark_paragraph;
  font-size: 16px;
  text-decoration: underline;
  text-decoration-skip-ink: none;
  cursor: default;
}

@media screen and (max-height: 645px) {
  .confirmCode {
    width: 270px;
    input {
      font-size: 16px !important;
      height: 40px !important;
      width: 59px !important;
      &::placeholder {
        font-size: 16px !important;
      }
    }
  }
  .container {
    top: 60px;
    width: 465px;
    height: 300px;
  }
  .darkGrayFont {
    margin: 10px 0 20px 0;
    height: 30px;
    font-size: 11px !important;
  }
  .button {
    margin-top: 10px;
    height: 40px;
    padding: 8px 24px !important;
    font-size: 10px !important;
  }
  .sendPushButton, .sendSmsButton {
    margin-top: 0px;
    button,
    p {
      font-size: 10px !important;
    }
  }
  .errorText {
    font-size: 10px;
    margin-top: 104px;
  }

}
