.label {
  display: flex;
  gap: 8px;
  height: 24px;
  align-items: center;
  // padding: 4px 4px 4px 8px;
  padding: 4px 8px;

  border: 1px solid #9B7FFC;
  border-radius: 100px;

  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;

  /* lilac */

  color: #9B7FFC;

  white-space: nowrap;

  &:hover {
    text-decoration: underline;
  }
}