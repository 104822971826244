@import "../../../styles/fonts.scss";
@import "../../../styles/colors.scss";

.labels {
  display: flex;
  gap: 10px;
  user-select: none;

  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;

  color: #222222;
  
  height: 43px;
}

.content_wrap {
  display: flex;
  flex-direction: row;
  gap: 24px;
}

.right_side_bar{
  width: 212px;
}

.content {
  flex-grow: 1;
  min-width: 0;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 8px;
  padding: 32px 24px;

  min-height: 500px;
}


.label {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 100%;

  padding-left: 30px;
  padding-right: 30px;
  margin-right: -80px;

  .label_text {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;

    z-index: 1;

    &:hover {
      text-decoration: underline;
    }
  }

  &.active {
    .label_text {
      background-color: #ffffffcc;
    }
  }


  &::before,
  &::after {
    content: "";
    background-repeat: no-repeat;
    background-size: auto;
    width: 50px;
    height: 100%;
    opacity: 0.8;
  }

  &.active::before {
    background-position: left;
    background-image: url("../../../assets/icons/goods_list_tab_btn.svg");
  }

  &.active::after {
    background-position: right;
    background-image: url("../../../assets/icons/goods_list_tab_btn.svg");
  }

}

.title {
  white-space: nowrap;

  @extend %font_btn_navigate_goods_list;
}

.count_goods {
  margin-left: 7px;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $color-blue;
  background: rgba(71, 115, 235, 0.16);
  @extend %font_button_navigate_goods_numbers;
}

