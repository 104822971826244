@import "../../../../../../../../../styles/fonts";

.search {
  border: 1px solid rgba(162, 171, 201, 0.5);
  border-radius: 8px;
  height: 43px;
  width: 197px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 14px;
  max-width: 400px;
  
  &.grow {
    width: auto;
    min-width: 197px;
    flex-grow: 1;
  }

  input {
    border: none;
    background: none;
    outline: none;
    text-align: left;
    @extend %font_gray_dark_paragraph;
    min-width: 100px;
  }

  img {
    height: 16px;
    width: 16px;
    left: 302px;
    top: 13.5px;
    border-radius: 0px;
  }
}