.wrap {}

.title {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  text-transform: uppercase;
  color: #222222;
}

.filters {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 28px;
}

.filters_wrap {
  display: flex;
  gap: 16px;
}

.view_selectors {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 50px;
}

