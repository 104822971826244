.content {
  margin-top: 24px;
  min-height: 1000px;

  border-radius: 8px;
  background: rgba(255, 255, 255, 0.80);

  padding: 32px 24px;
  display: flex;
  flex-direction: column;
  gap: 48px;
}