.link {
  display: flex;
  gap: 5px;
  align-items: center;

  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;

  color: #9B7FFC;
  cursor: pointer;

  &.file {
    color: #5B637E;
  }

  &:hover {
    text-decoration-line: underline;
  }
}