@import '../../../styles/fonts.scss';
@import '../../../styles/colors.scss';

.wrap {

  display: flex;
  gap: 12px;
  flex-wrap: wrap;

  .rbtn {
    border-radius: 8px;
    padding: 16px;
    border: 1px solid rgba(162, 171, 201, 0.50);
    background: #FFF;
    cursor: pointer;

    color: var(--gray-dark-text, #5B637E);
    font-family: Nunito Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    /* 19.6px */


    // flex-grow: 1;
    text-align: center;

    &.selected,
    &:hover {
      border: 1px solid var(--unnamed, #9B7FFC);
      background: linear-gradient(0deg, rgba(155, 127, 252, 0.12) 0%, rgba(155, 127, 252, 0.12) 100%), #FFF;
    }

    .content {}
  }

  &.v1 {
    .rbtn {
      // flex-grow: 0;

      border-radius: 8px;
      border: 1px solid rgba(162, 171, 201, 0.50);
      background: var(--white-bg, #F8F9FC);

      color: var(--black, #222);
      font-family: Nunito Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;

      padding: 12px 16px;

      &.selected,
      &:hover {
        border: 1px solid var(--unnamed, #9B7FFC);
        background: linear-gradient(0deg, rgba(155, 127, 252, 0.12) 0%, rgba(155, 127, 252, 0.12) 100%), #FFF;
      }

      &.disabled {
        border-radius: 8px;
        border: 1px solid rgba(162, 171, 201, 0.50);
        background: var(--white, #FFF);

        color: var(--gray-blue, #A2ABC9);
        font-family: Nunito Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }
  }
}