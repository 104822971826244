@import "../../../../../../../styles/colors";
@import "../../../../../../../styles/fonts";

.container {
  height: 638px;
  width: 100%;
  box-sizing: border-box;
  overflow-y: scroll;
  scrollbar-color: rgba($color-purple, .4) rgba($color-purple, .1);
  scrollbar-width: thin;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba($color-purple, .4);
    border-radius: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: rgba($color-purple, .1);
    border-radius: 8px;
  }

  .create {
    position: relative;
    display: flex;
    width: calc(100% - 24px);
    border-radius: 8px;
    align-items: center;
    justify-content: center;
    margin: 0 0 14px 0;
    padding: 12px 16px;
    border: 2px dashed rgba(255, 255, 255, 1);
    background: $purpleGradient;
    cursor: pointer;
    z-index: 0;
    @extend %font_purple_paragraph;
    &:after{
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 6px;
      background: white;
      z-index: -1;
    }

    .title{
      margin-left: 12px;
    }
  }
}
