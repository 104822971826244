@import "../../../../../../../../styles/fonts";
@import "../../../../../../../../styles/colors";

.wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 197px;
  height: 193px;
  border: 1px solid $color-gray;
  border-radius: 8px;
  flex: none;
  // margin-bottom: 16px;
  box-sizing: border-box;
  transition: 0.6s;

  &.selected {
    border-radius: 12px;
    border: 2px solid $color-purple;
  }

  &:hover {
    .setLogoForProduct {
      opacity: 0.9;
      pointer-events: inherit;
      transform: translateY(0);
    }
  }

  &:hover {
    .edit {
      opacity: 1;
      pointer-events: inherit;
    }
  }


  .logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      width: 80px;
      height: 80px;
      margin: 12px;
      z-index: 1;
    }

    div {
      font-weight: 600;
      @extend %font_h3
    }
  }

  .edit {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 25px;
    height: 25px;
    stroke: $color-gray-dark;
    opacity: 0;
    cursor: pointer;
    pointer-events: none;
    transition: ease .4s;

    .active {
      stroke: $color-pink !important;
    }

    &:hover {
      .info {
        opacity: 1;
        transform: translateY(0);
        pointer-events: inherit;
      }
    }

    // :nth-child(4n) {
    //   &.info {
    //     left: -139px
    //   }
    // }

  }

  .info {
    z-index: 2;
    position: absolute;
    top: 29px;
    left: 0;
    width: 164px;
    height: 70px;
    padding: 24px;
    background: white;
    box-shadow: 8px 8px 40px rgba(131, 103, 228, 0.2);
    border-radius: 8px;
    overflow: visible !important;
    opacity: 0;
    pointer-events: none;
    transform: translateY(-10%);
    transition: ease-in .2s;
    &_alignLeft{
      left: -139px
    }
  }




  .setLogoForProduct {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    background: $color-purple;
    border: none;
    padding: 8px 0;
    border-radius: 0 0 8px 8px;
    color: white;
    opacity: 0;
    pointer-events: none;
    transform: translateY(10%);
    transition: ease .25s .25s;
    @extend %font_white_paragraph
  }
}