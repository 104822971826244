@import './src/styles/fonts';
@import './src/styles/colors';


.custom_react_datepicker_wrap {
  .react-datepicker-popper {
    z-index: 3;
  }

  // в этом блоке ниже стили который были написаны до меня другим разработчиком
  // перенёс их "как есть" из глобальных стилей чтобы не ломать вёрстку


  // .react-datepicker__input-container input {
  //   display: block;
  //   width: 200px !important;
  //   font-family: 'Nunito Sans' !important;
  //   border: none;
  //   padding-left: 16px;
  //   font-weight: 600 !important;
  //   font-size: 16px !important;
  //   line-height: 19px !important;
  //   font-style: normal !important;
  //   border: 1px solid $color-light-border;
  //   border-radius: 8px;
  // }

  .react-datepicker-ignore-onclickoutside {
    box-shadow: 8px 8px 40px $color-modal-shadow;
    border-radius: 8px;
    border: none !important;
    outline: none;

    :focus {
      outline: none;
    }
  }

  .react-datepicker {
    font-family: 'Nunito Sans' !important;
    border: none !important;
    box-shadow: 8px 8px 40px $color-modal-shadow;
    border-radius: 8px;
  
    &__header {
      background-color: $color-white !important;
      border-bottom: none !important;
      border: none !important;
    }
  
    &__day-name {
      color: rgba(39, 39, 39, 0.3) !important;
      font-weight: 700;
      font-size: 13px !important;
      line-height: 18px;
    }
  
    &__day {
      color: $color-input !important;
      font-size: 15px !important;
      font-style: normal;
      font-weight: 400;
  
      &--weekend {
        color: $color-pink !important;
      }
  
      &--keyboard-selected,
      &--selected {
        border-radius: 25px !important;
        background: $color-lilac !important;
        color: $color-white !important;
      }
  
      &--disabled {
        color: #c7c7c7 !important;
      }
    }
  }
  
  .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before,
  .react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle::before,
  .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::after,
  .react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle::after {
    visibility: hidden !important;
  }
  
  .react-datepicker__day-name {
    &:last-child {
      color: $color-pink !important;
    }
  
    &:nth-last-child(2) {
      color: $color-pink !important;
    }
  }
  
  .react-datepicker__day--outside-month {
    color: rgba(39, 39, 39, 0.15) !important;
  }
  
  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    font-family: 'Nunito Sans' !important;
  }
  
  // .DatePick_selectDateBlock__Nmdhy{
  //     align-items: center;
  // }


}

.custom_react_datepicker {

  // .react-datepicker__day react-datepicker__day--012 react-datepicker__day--selected

  display: flex !important;
  flex-direction: column;
  user-select: none;

  .react-datepicker__day-names {
    display: flex;
    justify-content: space-evenly;
  }

  .react-datepicker__day {
    font-family: 'Nunito Sans' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 15px !important;
    line-height: 20px !important;
    text-align: right !important;
    color: #222222 !important;

    width: 46px !important;
    height: 44px !important;

    display: inline-flex !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected {
    color: #FFFFFF !important;
    background-color: #9B7FFC !important;
  }

  .react-datepicker__day--outside-month {
    color: rgba(39, 39, 39, 0.15) !important;
  }


  .months {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 8px;
    margin: 25px;
  }

  .month {
    padding: 8px;
    background: #EBECFF;
    border-radius: 4px;
    cursor: pointer;
  }

  .selected_month {
    margin-top: 24px;
    margin-bottom: 16px;
  }

  .month.selected {
    color: #FFFFFF;
    background: #9B7FFC;
  }

  // .react-datepicker__current-month,
  // .react-datepicker__navigation {
  //   display: none;
  // }

  .footer {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 140%;
    color: #9B7FFC;
    margin: 10px auto 24px;

    .save {
      cursor: pointer;
    }

    .save:hover {
      text-decoration: underline;
    }
  }

  .year_selector {
    margin: 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 16px;

    .scroll_years_right {
      rotate: 180deg;
    }
  }

  .years {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    gap: 8px;
    flex-grow: 1;

    .year {
      font-family: 'Nunito Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      text-align: right;
      color: #222222;

      padding: 8px 10px;
      background: #EBECFF;
      border-radius: 4px;

      cursor: pointer;
    }

    .year.selected {
      color: #FFFFFF;
      background-color: #9B7FFC;
    }
  }

}