@import '../../../styles/fonts.scss';
@import '../../../styles/colors.scss';

.wrap {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #222222;

  display: flex;
  gap: 16px;
}

.rbtn {
  display: flex;
  gap: 8px;
  align-items: center;
  cursor: pointer;

  img {
    width: 12px;
    height: 12px;
  }

  // .label {}
}