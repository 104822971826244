@import "../../../styles/colors.scss";
@import "../../../styles/fonts.scss";
@import "../../../styles/backgrounds.scss";


.body {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.body__warning {
  position: relative;
  border-radius: 8px;

  display: flex;
  padding: 14px 12px 12px 12px;
  flex-direction: column;
  gap: 10px;
}

.body__warning_top {
  border-radius: 8px;
  background-color: $bg_goods_list_wornings;

  h3 {
    @extend %font_pink_error;
  }
}

.title {
  display: flex;
  color: $color-pink;

  h3 {
    @extend %font_pink_error;
  }
}

.icons {
  margin-right: 10px;

  img {
    display: block;
  }
}

.text {
  // @extend %font_hint;
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
}
