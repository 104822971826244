$color-white: #ffffff;
$color-gray: #ebecff;
$color-blue: #4773eb;
$color-pink: #ff8ef6;
$color-purple: #9265fb;
$color-black: #000;
$color-light-border: rgba(162, 171, 201, 0.5);
$color-gray-dark: #5b637e;
$color-gray-blue: #a2abc9;
$color-lilac: #9b7ffc;
$color-pink: #FF5980;
$color-transparent-gray: rgba(213, 213, 213, 0.4);
$color-warning-good_list-color: rgba(255, 255, 255, 0.8);

/* Only font colors*/
$color-disabled-font: rgba(155, 127, 252, 0.5);
$color-placeholder-input: rgba(91, 99, 126, 0.5);
$color-input: #222222;
$color-purple-p: #5b637e;

/* shadow colors */
$color-modal-shadow: rgba(131, 103, 228, 0.2);

$color-product-list-total-pages: rgba(71, 115, 235, 0.16); ;

/* gradients */
$purpleGradient: linear-gradient(90deg, $color-pink 2.99%, $color-purple 94.03%);