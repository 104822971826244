.slider_gag {
  height: 246px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slider {
  overflow: hidden;
  position: relative;
  user-select: none;

  .products {
    display: flex;
    gap: 24px;
    position: relative;
    transition: all 0.5s;
  }


  .arrow_next,
  .arrow_back {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 112px;
    opacity: 0;
    transition: all 0.5s;
    pointer-events: none;

    background: linear-gradient(270deg, rgba(0, 0, 0, 0.3) 0%, rgba(217, 217, 217, 0) 100%);
    z-index: 1;

    &.visible {
      opacity: 1;
    }

    &::after {
      content: '';
      position: absolute;
      right: 24px;
      width: 32px;
      height: 32px;
      top: calc(50% - 16px);
      background-image: url('./assets/arrow.svg');
      pointer-events: all;
    }
  }

  .arrow_back {
    right: auto;
    left: 0;
    rotate: 180deg;

    &::after {
      // left: 24px;
    }
  }

  .product_wrap {
    // max-width: 250px;
    position: relative;
    padding: 1px;
    border-radius: 8px;
    background: rgba(162, 171, 201, 0.5);

    &.selected {
      background: linear-gradient(310.28deg, #CC8AFF 0%, #8AB9FF 100%);
      // background-color: ;
    }


    .hint {
      position: absolute;
      left: 10px;
      top: 10px;
      z-index: 1;
      width: 222px;

      display: flex;
      flex-direction: column;
      gap: 10px;
      padding: 12px;

      user-select: text;

      transform-origin: top left;
      transition: scale 0.2s ease-in;
      transition-delay: 0.3s;
      scale: 0;

      background: #FFFFFF;
      box-shadow: 8px 8px 40px rgba(131, 103, 228, 0.2);
      border-radius: 8px;

      font-family: 'Nunito Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 18px;
      color: #5B637E;

      &.active,
      &:hover {
        scale: 1;
      }
    }

    &:last-child {
      .hint {
        left: auto;
        right: 10px;
      }
    }


    .product {
      width: 180px;
      height: 244px;
      flex-shrink: 0;

      // border: 1px solid rgba(162, 171, 201, 0.5);
      border: 0px solid;
      border-radius: 7px;
      overflow: hidden;

      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;

      font-family: 'Nunito Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 18px;
      color: #222222;
      text-align: center;
      background-size: cover;

      position: relative;



      &.selected {
        // border: 1px solid;
        // border-image-source: linear-gradient(310.28deg, #CC8AFF 0%, #8AB9FF 100%);
        // border-style: solid;
        // border-width: 1px;
        // border-image-source: linear-gradient(310.28deg, #CC8AFF 0%, #8AB9FF 100%) 1;
        // border-image: radial-gradient(rgb(0, 143, 104), rgb(250, 224, 66)) 1;
        // border-radius: 8px;
      }

      .color,
      .info {
        background: #FFFFFF;
        opacity: 0.8;
        right: 0px;
        left: 0px;

        text-overflow: ellipsis;
        word-wrap: revert;
        white-space: nowrap;
        overflow: hidden;
        // right: -1px;
        // left: -1px;
      }

      .info {
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-items: center;
        padding: 8px 2px;

        position: absolute;
        height: 56px;
        top: 0px;
        // top: -1px;
        // border-top-left-radius: 7px;
        // border-top-right-radius: 7px;
      }

      .color {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        height: 48px;
        bottom: 0px;
        // bottom: -1px;
        // border-bottom-left-radius: 7px;
        // border-bottom-right-radius: 7px;
      }

      .product_name {
        max-width: 90%;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}