.wrap {
  margin-right: 30px;
  margin-bottom: 30px;
  // max-width: calc(100vw - 310px);
}

.panels_column {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.warnings {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 24px;

  .warning {
    background: #DA79D5;
    border-radius: 8px;
    padding: 16px 32px;

    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    text-transform: uppercase;
    color: #FFFFFF;
    cursor: pointer;

    a {
      text-decoration: underline;
    }
  }
}

.content {
  margin-top: 24px;
  display: flex;
  gap: 24px;

  .body {
    flex-grow: 1;
  }

  .sidebar {
    width: 330px;
    flex-shrink: 0;
  }
}

.chart_content {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #222222;

  h1 {
    text-align: left;
    margin: 24px 0;
  }

  .chart_btns {
    margin-top: 8px;
    display: flex;
    gap: 8px;
  }
}

// .indicators {
//   display: flex;
//   justify-content: space-between;
//   gap: 12px;

//   .indicators_list {
//     display: flex;
//     flex-flow: row wrap;
//     flex-grow: 1;
//     gap: 12px;

//     .indicator {
//       flex-grow: 1;
//       width: calc(100%/6);
//       transition: all .2s ease-in-out;

//       font-family: 'Nunito Sans';
//       font-style: normal;
//       line-height: 140%;
//       color: #222222;

//       .indicator_message {
//         font-weight: 700;
//         font-size: 16px;
//       }

//       .indicator_growth {
//         font-weight: 400;
//         font-size: 14px;
//         display: flex;
//         align-items: center;
//         gap: 6px;

//         &::after {
//           content: '';
//           width: 14px;
//           height: 15px;
//           background-image: url('./assets/arrow_up.svg');
//           background-position: center;
//           background-repeat: no-repeat;
//         }

//         &.down {
//           &::after {
//             background-image: url('./assets/arrow_down.svg');
//           }
//         }
//       }

//       &:hover, .active {
//         // filter: drop-shadow(4px 4px 40px rgba(155, 127, 252, 0.4));

//         box-shadow: 4px 4px 40px rgba(155, 127, 252, 0.4);
//       }
//     }
//   }

//   .indicators_btn {
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     gap: 10px;

//     background: linear-gradient(310.28deg, #CC8AFF 0%, #8AB9FF 100%);
//     border-radius: 8px;
//     width: 126px;

//     font-family: 'Nunito Sans';
//     font-style: normal;
//     font-weight: 700;
//     font-size: 14px;
//     line-height: 140%;

//     color: #FFFFFF;

//     cursor: pointer;

//     &::after {
//       content: '';
//       background-image: url('./assets/arrow.svg');
//       background-repeat: no-repeat;
//       width: 8px;
//       height: 10px;
//     }
//   }
// }

.news_panel {

  display: flex;
  flex-direction: column;
  gap: 32px;

  .news_list {
    display: flex;
    flex-direction: column;
    gap: 24px;


    h1 {
      font-family: 'Nunito Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      text-transform: uppercase;
      color: #222222;
      text-align: left;
      margin: 0;
    }


    .news_item {
      display: flex;
      gap: 16px;

      .news_img {
        flex-shrink: 0;
        display: flex;
        justify-content: center;
        width: 188px;
        height: 124px;

        border-radius: 8px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        // background-size: contain;

        // img {
        //   max-width: 100%;
        //   max-height: 100%;
        // }
      }

      font-family: 'Nunito Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      color: #222222;

      .news_title {
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
      }

      .news_text {
        margin-top: 8px;
        margin-bottom: 10px;
      }

      .news_date {
        color: #A2ABC9;
      }
    }
  }

  .notifications_list {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;

    .row {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 34px;
    }

    .skeleton {
      width: 100%;
    }

    .notifications_item {
      .link {
        color: #A984D5;
      }
      &:hover .link {
        color: #FFFFFF;
      }
    }

    // .notifications_item {
    //   height: 51px;
    //   padding: 16px;
    //   cursor: pointer;

    //   background: #EFEAF4;
    //   border-radius: 8px;

    //   transition: background-color 0.5s ease;
    //   position: relative;
    //   z-index: 1;

    //   overflow: hidden;


    //   font-family: 'Nunito Sans';
    //   font-style: normal;
    //   font-weight: 700;
    //   font-size: 14px;
    //   line-height: 19px;
    //   display: flex;
    //   align-items: center;
    //   color: #222222;


    //   &::after {
    //     transition: opacity 0.2s ease-in-out;
    //     content: "";
    //     display: block;
    //     opacity: 0;
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     width: 100%;
    //     height: 100%;
    //     background-image: linear-gradient(310.28deg, #CC8AFF 0%, #8AB9FF 100%);

    //     z-index: -1;
    //   }

    //   &:hover {
    //     color: #FFFFFF;

    //     &::after {
    //       opacity: 1;
    //     }
    //   }

    //   .link {
    //     color: #A984D5;
    //   }
    // }
  }
}

.sidebar_content {
  display: flex;
  flex-direction: column;

  gap: 12px;
  text-align: center;


  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;

  color: #222222;



  .lv {
    display: flex;
    flex-direction: column;

    .label {
      text-align: center;
      color: #A2ABC9;
    }

    .value {
      font-weight: 700;
      font-size: 20px;

      display: flex;
      align-items: center;
      justify-content: center;

      .star_icon {
        width: 24px;
        height: 24px;
      }
    }
  }

  .sidebar_btns {
    display: flex;
    justify-content: center;
  }

  .level_name {
    color: #A984D5;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }


  &.fbo_fbs {

    .warning_text {
      display: flex;
      flex-direction: column;
      gap: 16px;
      align-items: center;

      font-family: 'Nunito Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 19px;
      text-align: center;
      text-transform: uppercase;
      color: #222222;

      &::before {
        display: block;
        content: '';
        width: 24px;
        height: 24px;
        background-image: url('./assets/warning.svg');
        background-repeat: no-repeat;
      }
    }

    .panel {
      padding: 16px;
      background: #EFEAF4;
      border-radius: 8px;
      margin-top: 16px;
      text-align: left;

      .panel_title {
        font-weight: 700;
        font-size: 20px;
        line-height: 27px;
        text-align: center;
        color: #222222;
      }

      table {
        font-size: 13px;
        line-height: 18px;

        td {
          padding: 12px 6px;
          border-bottom: 1px solid rgba(162, 171, 201, 0.5);

          &:nth-child(2) {
            width: 60px;
          }
        }

        tr:last-child td {
          border-bottom: none;
        }
      }
    }
  }

  .actions {

    display: flex;
    flex-direction: column;
    gap: 16px;

    h1 {
      // margin: 0 0 16px 0;
      margin: 0;
    }

    .actions_btns {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .action {
      filter: drop-shadow(8px 8px 40px rgba(131, 103, 228, 0.2));
      // width: 500px;
      margin-bottom: 8px;
      margin-left: 4px;
      text-align: left;

      cursor: pointer;



      .action_head {
        height: 54px;

        background: #9B7FFC;
        border-radius: 8px 8px 0px 0px;
        padding: 16px;

        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
        color: #FFFFFF;
      }

      .action_body {
        display: flex;
        flex-direction: column;
        gap: 10px;

        background: #FFFFFF;
        border-radius: 0px 0px 8px 8px;
        padding: 16px;


        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: #222222;

        img {
          vertical-align: middle;
          margin-right: 4px;
        }
      }


      

    }



    .action:nth-child(1n) {
      .action_head {
        background: linear-gradient(0deg, rgba(254, 87, 171, 0.5), rgba(254, 87, 171, 0.5)), #9B7FFC;
      }
    }
    
    .action:nth-child(2n) {
      .action_head {
        background: linear-gradient(0deg, rgba(254, 87, 171, 0.3), rgba(254, 87, 171, 0.3)), #9B7FFC;
      }
    }
    
    .action:nth-child(3n) {
      .action_head {
        background: #9B7FFC;
      }
    }
    
    .action:nth-child(4n) {
      .action_head {
        background: linear-gradient(0deg, rgba(98, 140, 253, 0.5), rgba(98, 140, 253, 0.5)), #9B7FFC;
      }
    }
    
    .action:nth-child(5n) {
      .action_head {
        background: linear-gradient(0deg, rgba(50, 187, 252, 0.5), rgba(50, 187, 252, 0.5)), #9B7FFC;
      }
    }
    
    .action:nth-child(6n) {
      .action_head {
        background: linear-gradient(0deg, rgba(8, 248, 157, 0.4), rgba(8, 248, 157, 0.4)), #9B7FFC;
      }
    }
    
    .action:nth-child(7n) {
      .action_head {
        background: linear-gradient(0deg, rgba(8, 248, 157, 0.55), rgba(8, 248, 157, 0.55)), #9B7FFC;
      }
    }
    
    .action:nth-child(8n) {
      .action_head {
        background: linear-gradient(0deg, rgba(50, 187, 252, 0.2), rgba(50, 187, 252, 0.2)), #37CB8D;
      }
    }
  }
}