.icon {
  width: 32px;
  height: 32px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center;
}

.disabled {
  opacity: 0.5;
}

.tarsh {
  background-image: url('./assets/trash.svg');

  &:hover,
  &.active {
    background-image: url('./assets/trash_active.svg');
  }
}

.box_arrow {
  background-image: url('./assets/box_arrow.svg');

  &:hover,
  &.active {
    background-image: url('./assets/box_arrow_active.svg');
  }
}

.menu_tarsh {
  background-image: url('./assets/menu_trash.svg');
}

.menu_copy {
  background-image: url('./assets/menu_copy.svg');
}

.menu_edit {
  background-image: url('./assets/menu_edit.svg');
}

.lock_open {
  background-image: url('./assets/lock_open.svg');

  &:hover,
  &.active {

    // background-image: url('./assets/lock_open.svg');
    path {
      stroke: #F35A91 !important;
    }

  }
}

.lock_close {
  background-image: url('./assets/lock_close.svg');

  &:hover,
  &.active {
    background-image: url('./assets/lock_close_active.svg');
  }
}

.save {
  background-image: url('./assets/save.svg');

  &:hover,
  &.active {
    background-image: url('./assets/save_active.svg');
  }
}

.edit {
  background-image: url('./assets/edit.svg');

  &:hover,
  &.active {
    background-image: url('./assets/edit_active.svg');
  }
}

.reload {
  background-image: url('./assets/reload.svg');

  &:hover,
  &.active {
    background-image: url('./assets/reload_active.svg');
  }
}

.upload {
  background-image: url('./assets/upload.svg');

  &:hover,
  &.active {
    background-image: url('./assets/upload_active.svg');
  }
}

.v {
  background-image: url('./assets/v.svg');

  &:hover,
  &.active {
    background-image: url('./assets/v_active.svg');
  }
}

.x {
  background-image: url('./assets/x.svg');

  &:hover,
  &.active {
    background-image: url('./assets/x_active.svg');
  }
}

.plus {
  background-image: url('./assets/plus.svg');

  &:hover,
  &.active {
    background-image: url('./assets/plus_active.svg');
  }
}

.dots {
  background-image: url('./assets/dots.svg');

  &:hover,
  &.active {
    background-image: url('./assets/dots_active.svg');
  }
}