@import "./colors.scss";

@keyframes gradient-on-hover {
  80% {
    background-size: 200%;
  }
  100% {
    background-size: 150%;
  }
}

@keyframes gradient-on-blur {
  100% {
    background-size: 100%;
  }
}

@keyframes button-on-press {
  0% {
    filter: brightness(100%);
  }
  100% {
    filter: brightness(110%);
  }
}

@keyframes spinning-ball {
  0% {
    rotate: 0deg;
  }
  100% {
    rotate: 360deg;
  }
}

@keyframes slider-open {
  0% {
    transform: translateX(-50%) translateY(-50%) scale(0.5);
  }
  100% {
    transform: translateX(-50%) translateY(0) scale(1);
  }
}

@keyframes btn_add_goods {
  0% {
  }
  100% {
    background: #9265fb;
    border: 2px dotted $color-pink;
  }
}
