.wrap {

  .sizes {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #222222;
  }

  .thead {
    display: flex;
    align-items: center;
  }

  .size {
    display: flex;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(162, 171, 201, 0.5);
    min-height: 56px;

    input {
      padding: 8px 0px 8px 8px;
      height: 35px;
      border: 1px solid rgba(162, 171, 201, 0.5);
      outline: none;

      font-family: 'Nunito Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      color: #222222;
      text-align: center;

    }
  }
  
  .checkbox {
    width: 30%;
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .price {
    display: none;
    width: 30%;
  }

  .man_barcode {
    width: 30%;
  }

  .man_barcode {
    width: 47%;
  }

  .footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 16px;
    margin-top: 16px;
  }
}