.turnover {

  .title {
    text-align: left;
    margin-top: 45px;
    margin-bottom: 36px;
  }

  .period {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #222222;

    .period_selector {
      display: flex;
      gap: 24px;
      align-items: center;
      margin-top: 8px;

      .calendar_period_selector {
        flex-grow: 0;
      }
    }

  }

  .controls {
    display: flex;
    align-items: center;
    gap: 24px;
    margin-top: 32px;

    .fbo_select {
      width: 300px;
    }
  }

  .info {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #8289A1;
    width: 650px;
    margin-top: 48px;

    b {
      color: #222222;
    }

    h1 {
      text-align: left;
      margin-bottom: 24px;
    }

    .formula {
      font-family: 'Nunito Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 19px;
      color: #222222;

      display: flex;
      gap: 8px;
      align-items: center;

      margin-top: 24px;

      .formula_fraction {
        display: flex;
        flex-direction: column;
        gap: 6px;

        color: #8289A1;

        .line {
          border-bottom: 1px solid #8289A1;
        }
      }

    }
  }

  .table {
    width: 100%;

    text-align: center;

    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;

    color: #5B637E;

    thead {
      font-weight: 700;
      color: #222222;
    }

    td {
      height: 48px;
      border-bottom: 1px solid #EBECFF;

      &.positive {
        color: #FF5980;
      }

      &.negative {
        color: #47F2A7;
      }
    }
  }

  .download {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 24px;
  }

  .chart_tooltip {
    display: inline-flex;
    padding: 12px 16px;
    flex-direction: column;
    align-items: center;
    gap: 4px;

    width: 190px;
    white-space: normal;

    .days {
      color: #222;
      font-size: 14px;
      font-family: Nunito Sans;
      font-weight: 700;
    }

    .date {
      color: #222;
      font-size: 10px;
      font-family: Nunito Sans;
      font-weight: 300;
    }

    .info_text {
      color: var(--green-text, #39C286);
      text-align: center;
      font-size: 10px;
      font-family: Nunito Sans;
      font-weight: 600;
      line-height: 100%;
    }

    .error_text {
      color: var(--pink, #FF5980);
      text-align: center;
      font-size: 10px;
      font-family: Nunito Sans;
      font-weight: 600;
    }

    .warning_text {
      color: var(--gray-text, #8289A1);
      text-align: center;
      font-size: 10px;
      font-family: Nunito Sans;
      font-weight: 600;
    }
  }
}