@import "../../../styles/colors";
@import "../../../styles/fonts";

.textarea {
  position: relative;

  .label {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 120%;
    color: #A2ABC9;

    display: flex;
    gap: 5px;

    margin-bottom: 6px;

  }

  .text {
    // max-height: 120px;
    overflow: hidden;
    white-space: pre-wrap;

    scrollbar-color: rgba($color-purple, .4) rgba($color-purple, .1);
    scrollbar-width: thin;
    scroll-behavior: smooth;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba($color-purple, .4);
      border-radius: 8px;
    }

    &::-webkit-scrollbar-track {
      background-color: rgba($color-purple, .1);
      border-radius: 8px;
    }
  }

  textarea.text {
    width: 100%;
    resize: none;

    // max-height: 120px;
    overflow-y: auto;

    border: 1px solid rgba(162, 171, 201, 0.5);
    border-radius: 8px;
    padding: 16px;

    outline: none;

    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #222222;


    &::placeholder {
      color: rgba(91, 99, 126, 0.5) !important;
    }

  }

  .text_readonly {
    border: 1px solid rgba(162, 171, 201, 0.5);
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0px;
    min-height: 40px;
    padding: 10px;
    padding-left: 0;
  }

  .maxlen {
    text-align: right;
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 120%;
    color: #A2ABC9;

    &.v2 {
      position: absolute;
      bottom: 30px;
      right: 14px;

      font-family: 'Nunito Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      color: #A2ABC9;
    }
  }

  .maxlen.warning {
    color: #FF5980;
  }

  &.has_error {
    textarea {
      border-color: #FF5980;
    }
  }

  .error {
    height: 20px;

    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #FF5980;
    text-align: left;
  }


  &.is_empty {

    .input,
    .input::placeholder {
      color: rgba(91, 99, 126, 0.5) !important;
      // font-style: italic;
    }
  }

}