.wrap {

  .grid {
    display: grid;
    align-items: center;
    // gap: 24px;
    grid-template-columns: 17% 20% 30% 30%;
    gap: 0 1%;

    &>div,
    input {
      width: 100%;
      // text-align: left;
    }

    &.thead {
      align-items: start;
      // display: flex;
      // align-items: center;
    }
  }

  .sizes {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #222222;
  }



  .size {
    // display: flex;
    // align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(162, 171, 201, 0.5);
    min-height: 56px;

    input {
      padding: 8px 0px 8px 8px;
      height: 35px;
      border: 1px solid rgba(162, 171, 201, 0.5);
      outline: none;

      font-family: 'Nunito Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      color: #222222;
      // text-align: center;
      border-radius: 0;
    }
  }

  .checkbox {
    width: 30%;
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .price {
    // display: none;
    width: 30%;
  }

  .man_barcode {
    width: 30%;
  }

  .man_barcode {
    width: 47%;
  }

  .footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 16px;
    margin-top: 16px;
  }
}