@import "src/styles/fonts";
@import "src/styles/animations";

.modal_container {
  position: fixed;
  top: 0;
  left: 0;

  font-family: 'Nunito Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;

  text-align: center;
  color: #222222;

  width: 100vw;
  height: 100vh;
  z-index: 100;

  user-select: none;
}

.modal_underflow {
  cursor: pointer;
  position: relative;
  display: block;

  width: 100vw;
  height: 100vh;

  background-color: rgba(213, 213, 213, 0.9);
  backdrop-filter: blur(5px);

  z-index: 101;
}

.content {
  animation: slider-open 0.5s forwards;
  position: fixed;
  top: 5vh;
  left: 50%;
  transform: translateX(-50%) scale(0);
  width: 80vw;
  height: 70vh;
  min-width: 920px;
  min-height: 608px;
  overflow-y: auto;

  padding: 20px 50px;

  background-color: #FFFFFF;
  box-shadow: 8px 8px 40px rgba(131, 103, 228, 0.2);
  border-radius: 8px;

  z-index: 200;
}


.head {
  max-width: 100%;
  position: relative;
  margin-bottom: 40px;

  .hint {
    display: inline-block;
    vertical-align: middle;

    &::before {
      width: 190px;
    }
  }
}

.body {
  display: flex;
  justify-content: center;
  gap: 60px;
}

.footer {
  position: absolute;
  bottom: 60px;
  width: 100%;
  left: 0;

  display: flex;
  justify-content: center;
}

.close {
  position: absolute !important;
  width: 32px !important;
  height: 32px !important;
  right: 32px;
  top: 32px;
  // cursor: pointer;
}

.controls {
  margin-top: 30px;
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 32px;

  label {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #5B637E;
    display: block;
    margin-bottom: 16px;
  }

}

.btns {
  display: flex;
  justify-content: space-between;

  .arrow {
    width: 30px;
    height: 30px;
    border: 1.3px solid #A2ABC9;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .arrow_left,
  .arrow_right {
    width: 12px;
    height: 10px;
  }

  .arrow_left {
    rotate: 180deg;
  }

  .product_title {
    display: flex;
    flex-direction: column;
    justify-content: center;


    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #222222;
  }
}

.icon_shape {
  height: 32px !important;
  width: 32px !important;
}

.icon_color {
  height: 32px !important;
  width: 32px !important;
  border-radius: 8px;
}

.preview {
  display: flex;
  flex-direction: column;
  gap: 17px;

  .image_wrap {
    display: flex;
    width: 300px;
    height: 320px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

  .image {
    position: relative;
    max-width: 100%;
    max-height: 100%;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}

