%bg_auth {
  background-image: url("../assets/images/authBackground.svg");
  background-size: cover;
  background-repeat: repeat;
  width: 100%;
  height: 100%;
}

%bg_goods_list {
  background: linear-gradient(
    117.58deg,
    #00dcff 1.21%,
    #4773eb 50.56%,
    #ff8ef6 99.92%
  );
}

// Кнопки добавления товара
$bg_goods_list_btns_add: rgba(255, 255, 255, 0.8);

// Ворнинги на странице товара
$bg_goods_list_wornings: rgba(255, 255, 255, 0.8);
