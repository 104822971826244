.wrap {
  margin-right: 30px;
  margin-bottom: 30px;

  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;


  h1 {
    margin-top: 0;
    margin-bottom: 40px;
  }

  .brands_certs {
    .search input {
      height: 51px;
    }


    .brands_info {
      li {
        margin-bottom: 7px;
      }
    }

    .warning {
      color: #FF5980;
      display: flex;
      gap: 5px;
      margin-bottom: 24px;

      &::before {
        content: '!';
        color: #FF5980;
      }
    }

    h1 {
      margin-top: 56px;
      margin-bottom: 30px;
      text-align: left;
    }

    .grid {
      width: 100%;
      table-layout: auto;

      font-weight: 600;
      font-size: 13px;
      line-height: 18px;
      color: #5B637E;
      text-align: left;

      th {
        padding-bottom: 5px;
        user-select: none;

        span {
          display: flex;
          align-items: center;
          gap: 5px;


        }

        &.sort span::after {
          display: block;
          content: '';
          width: 8px;
          height: 10px;
          background-image: url('./assets/arrow.svg');
          rotate: 180deg;
        }


      }

      .order_desc .sort span::after {
        rotate: 0deg;
      }

      th {
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        color: #A2ABC9;
      }

      td,
      th {
        min-height: 62px;
        border-bottom: 1px solid #F0EEF9;
      }

      .brand {
        width: 28%;
      }

      .status {
        width: 20%;
        color: #FF5980;

        &.active {
          color: #67E8C1;
        }
      }

      .documents {
        width: 35%;

        .doc {
          font-size: 13px;
          line-height: 18px;

          background: linear-gradient(310.28deg, #CC8AFF 0%, #8AB9FF 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
        }

        .uploaded_doc {
          color: #67E8C1;
        }
      }

      .btns_wrap {
        display: flex;
        gap: 24px;
        align-items: center;
      }

      &.certs {

        td {
          font-weight: 300;
          font-size: 13px;
          line-height: 18px;
          color: #5B637E;
        }

        .cert {
          width: 25%;
          // font-weight: 400;
          // color: #000000;
          font-weight: 600;
          color: var(--gray-dark-text, #5B637E);
        }

        .from_date {
          width: 18%;
        }

        .to_date {
          width: 18%;
        }

        .items_count {
          width: 10%;
        }

        .btns {
          display: flex;
          justify-content: flex-end;
        }
      }

    }

    .cert_btns {
      margin-top: 70px;
    }

  }


  .create_cert {

    .row {
      display: grid;
      grid-template-columns: 48% 48%;
      gap: 4%;
    }

    .dropzone {
      margin-top: 40px;
      margin-bottom: 20px;
    }

    .date_label {
      font-family: 'Nunito Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 13px;
      line-height: 120%;
      color: #A2ABC9;
      display: flex;
      gap: 5px;
      margin-bottom: 6px;
    }

    .date {
      width: 100%;
    }

    .uploaded_cert {
      font-family: 'Nunito Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;

      display: flex;
      flex-direction: column;
      gap: 9px;
      margin-top: 30px;
      margin-bottom: 30px;

      .cert_file {
        display: flex;
        align-items: center;
        gap: 4px;

        background: linear-gradient(310.28deg, #CC8AFF 0%, #8AB9FF 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;

        &::before {
          content: '';
          width: 24px;
          height: 24px;
          background-image: url('./assets/file.svg');
        }
      }

      color: #A2ABC9;
    }
  }

  .cert_card {
    h1 {
      display: flex;
      gap: 10px;

      img {
        cursor: pointer;
      }
    }

    .btns {
      display: flex;
      justify-content: center;
    }
  }

  .cert_products {
    margin-top: 48px;

    .filters {
      display: flex;
      gap: 16px;
      align-items: center;

      .filter {
        display: flex;
        gap: 4px;

        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 13px;
        line-height: 18px;
        color: #A2ABC9;

        .value {
          display: flex;
          align-items: center;
          justify-content: center;

          font-weight: 600;
          font-size: 10px;
          line-height: 14px;

          width: 19px;
          height: 19px;
          border-radius: 100px;
          color: #8289A1;
          background: linear-gradient(310.28deg, rgba(204, 138, 255, 0.2) 0%, rgba(138, 185, 255, 0.2) 100%);
        }
      }

      .filter.selected {
        border: 2px solid #A2ABC9;
        border-radius: 100px;
        padding: 7px 16px;

        color: #222222;

        .value {
          color: #FFFFFF;
          background: #39C286;
        }
      }
    }

    .products_list {
      margin-top: 23px;
      width: 100%;
      text-align: left;
      font-family: 'Nunito Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      color: #5B637E;

      thead {
        font-weight: 600;
        font-size: 13px;
        line-height: 18px;
        color: #A2ABC9;
      }

      .product_img {
        max-width: 50px;
        max-height: 54px;
      }
    }
  }
}