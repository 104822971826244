@import "../../../../../styles/fonts.scss";

.sidebar {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  align-self: stretch;

  width: 220px;
  min-width: 220px;

  padding-top: 45px;

  margin-right: 40px;

  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(8px);
}

.logoBlock {
  display: flex;
  margin-bottom: 30px;
  justify-content: center;
  align-items: center;

  .img_wrap {
    width: 85px;
    overflow: hidden;

    img {
      width: 100%;
      height: auto;
    }
  }
}

.navBlock:not(:last-child) {
  margin-bottom: 50px;
}

.sidebarLogoutMenu {
  margin-top: 250px;
}

.title {
  font-family: 'Nunito Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 18px;

  text-transform: uppercase;

  color: #222222;

  margin-bottom: 30px;
}

.navigation {
  margin-left: 23px;
  margin-right: 23px;
}

.navList {
  display: flex;
  flex-direction: column;

  margin: 0;
  padding: 0;
  list-style: none;

  margin-left: 10px;
}

.navList__item:not(:last-child) {
  margin-bottom: 17px;
}

.navLink {
  display: flex;
  gap: 16px;

  font-family: 'Nunito Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;

  // color: black;
  color: #A2ABC9;
  text-decoration: none;

  &.active {
    color: #222222;
  }

}



.submenu {

  .submenu_list {
    max-height: 0;
    transition: max-height 0.15s ease-out;
    overflow: hidden;

    .navList {
      margin: 20px 0 10px 32px;
    }

    &.open {
      max-height: 500px;
      transition: max-height 0.25s ease-in;
    }
  }
}