// $height: 64px;
$transition: 0.5s;

.wrap {
  z-index: 100;
  user-select: none;
  position: absolute;
  right: 30px;
  top: 68px; // 32+36
}

.bg {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.user_menu {
  display: flex;
  flex-direction: column;
  position: relative;

  height: 50px;

  // margin-top: 32px;
  // margin-right: 30px;
  transition: $transition ease-in-out;

  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  text-align: right;
  color: #222222;

  filter: drop-shadow(8px 8px 40px transparent);


  &.open {
    filter: drop-shadow(8px 8px 40px rgba(131, 103, 228, 0.2));

    .menu {
      .menu_content {
        padding-top: 60px;
        height: 185px;
      }
    }

    .head_content::after {
      transform: scale(1, -1);
    }

  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    height: 64px; // $height;
    width: 100%;
    border-radius: 100px;
    z-index: -1;
    background-color: #FFFFFF;
  }

  &::before {
    top: -8px;
  }



  .head,
  .menu {
    padding-left: 24px;
    padding-right: 24px;
    display: flex;
  }

  .head {

    cursor: pointer;

    .head_content {
      display: flex;
      align-items: center;
      gap: 12px;
      width: 100%;
      background-color: #FFFFFF;
      min-width: 200px;

      &::after {
        content: '';
        display: block;
        width: 12px;
        height: 6px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        background-image: url('./assets/arrow.svg');
        transition: transform $transition;
      }

      .alert {
        height: 100%;
        padding-top: 9px;
        position: relative;

        .alerts_count {
          position: absolute;
          right: 0;

          width: 14px;
          height: 14px;
          border-radius: 100px;
          background-color: #47F2A7;

          display: flex;
          align-items: center;
          justify-content: center;
          overflow: hidden;

          font-family: 'Nunito Sans';
          font-style: normal;
          font-weight: 700;
          font-size: 9px;
          line-height: 12px;
          text-align: center;
          color: #222222;
        }

      }

      .premium {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 3px;
        color: #FFC83A;

        &::before {
          content: '';
          display: block;
          width: 12px;
          height: 12px;
          background-image: url('./assets/premium.svg');
          background-position: center;
          background-repeat: no-repeat;
        }
      }

      .user_img {
        border-radius: 100px;
        width: 48px;
        height: 48px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
      }
    }
  }

  .menu {
    display: flex;
    flex-direction: column;
    text-align: left;
    background-color: #FFFFFF;

    position: absolute;
    width: 100%;
    top: 24px;

    z-index: -1;

    .menu_content {
      display: flex;
      flex-direction: column;
      gap: 14px;


      overflow: hidden;
      transition: $transition;
      padding-top: 0px;
      height: 0px;

      font-family: 'Nunito Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      text-align: left;
      color: #A2ABC9;
    }


    .menu_item {
      // background: linear-gradient(0deg, #A2ABC9, #A2ABC9),
      // linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));

      display: flex;
      gap: 14px;
      cursor: pointer;

      &::before {
        content: '';
        display: block;
        width: 16px;
        height: 16px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        // background-image: url('./assets/user.svg');
      }

      &.menu_item_profile::before {
        background-image: url('./assets/user.svg');
      }

      &.menu_item__alerts::before {
        background-image: url('./assets/bell_mini.svg');
      }

      &.menu_item__settings::before {
        background-image: url('./assets/config.svg');
      }

      &.menu_item__logout::before {
        background-image: url('./assets/logout.svg');
      }

    }





    &::after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      border-radius: 100px;
      z-index: -1;
      background-color: #FFFFFF;
      height: 47px;
      bottom: -23.5px; // 47/2
      right: 0;
    }
  }
}