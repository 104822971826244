@import "../../../../../styles/fonts.scss";

.header {
  display: flex;
  justify-content: space-between;

  font-family: "Nunito Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;

  // text-align: right;

  color: #222222;

  width: 100%;
  //width: 1156px;
  // min-width: 1156px;
  min-width: 1080px;
  min-height: 136px;

  // padding-top: 25px;
  // padding-bottom: 25px;
  padding-top: 61px;
  padding-bottom: 46px;
}
.left {
  position: relative;
  display: flex;
  align-items: center;
  gap: 20px;
}

@keyframes open-info {
  0% {
    transform: scaleX(0);
  }
  100% {
    transform: scaleX(1);
  }
}
.info {
  animation: open-info 0.5s forwards;
  position: absolute;
  left: 95%;
  top: 70%;

  width: 548px;
  padding: 25px;

  text-align: left;

  color: #5b637e;
  background-color: #ffffff;

  box-shadow: 8px 8px 40px rgba(131, 103, 228, 0.2);
  border-radius: 8px;
}
.btnBack {
  position: relative;
  font-family: "Nunito Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  text-transform: uppercase;
  margin: 0;
  padding: 0;

  // padding-left: 35px;

  color: #222222;

  border: 0;
  background-color: transparent;

  // cursor: pointer;

  // &::before {
  //   content: "";
  //   position: absolute;
  //   top: 50%;
  //   left: 0;
  //   transform: translateY(-50%);

  //   width: 25px;
  //   height: 15px;

  //   background-color: transparent;
  //   background-image: url("../../../../../assets/icons/arrow_left_icon.svg");
  //   background-repeat: no-repeat;
  //   background-position: center;
  //   background-size: cover;
  // }
}

.btnAsk {
  width: 20px;
  height: 20px;
  background-color: transparent;
  border: 0;
  border-radius: 50%;

  background-image: url("../../../../../assets/icons/btnAskLogo.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

  margin-left: 5px;

  cursor: pointer;

  &__active {
    background-color: #FF5980;
    background-image: url("../../../../../assets/icons/btnAskLogo__active.svg");
  }
}

.right {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px 8px 24px;
  gap: 16px;

  width: 259px;
  height: 64px;

  margin-right: 30px;

  background: rgba(255, 255, 255, 0.4);
  border-radius: 100px;
}

.alert {
  position: relative;
}
.alert__count {
  position: absolute;
  top: 2px;
  right: 0;

  width: 14px;
  height: 14px;

  background-color: #47f2a7;

  border-radius: 50%;

  font-weight: 700;
  font-size: 9px;
  line-height: 12px;
  text-align: center;
}
.userData {
  flex-grow: 1;
}
.user {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 45px;
  height: 45px;

  border-radius: 50%;

  overflow: hidden;
}
.user__name {
  margin: 0;
  padding: 0;
  white-space: nowrap;
}
.user__premium {
  position: relative;
  display: inline-block;

  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  text-align: right;

  color: #FFC83A;

  &::before {
    content: "";
    position: absolute;
    top: 4px;
    left: -20px;
    //transform: translateX(-50%);

    width: 12px;
    height: 12px;

    background-image: url("../../../../../assets/icons/iconKing.svg");
    background-repeat: no-repeat;
  }
}

.btnOpenMenu {
  width: 10px;
  height: 10px;

  border: 0;
  background-color: transparent;
  background-image: url("../../../../../assets/icons/addSelectIcon.svg");
  background-repeat: no-repeat;

  cursor: pointer;
}


.arrow {
  cursor: pointer;
  width: 24px;
  height: 24px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("../../../../../assets/icons/arrow_left_icon.svg");
}