.title {

  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 120%;
  color: #A2ABC9;

  user-select: none;

  &.required::after {
    content: "*";
    height: 0;
    color: red;
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 120%;
    margin-left: 5px;
  }
}