.wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  gap: 8px;
}

.text {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 18px;
  color: #222222;
  white-space: nowrap;
}

.remove {
  width: 24px;
  height: 24px;
}