// .additionButtons {}
// .wrap  {}

.low {
  color: #FFC83A;
}

.warning,
.empty {
  color: #FF5980;
}