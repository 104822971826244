.controls {
  display: flex;
  align-items: center;
  width: 120px;
  gap: 12px;

  .btn {
    width: 32px;
    height: 32px;
  }

  &.first_row {
    margin-top: 25px;
  }
  img {
    cursor: pointer;
  }
}

.season {
  // height: 62px;
  display: flex;
  gap: 12px;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 12px;
}