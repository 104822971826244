.container {
  display: flex;
  // justify-content: center;
  // margin: 0 24px 0 0;
  width: 100%;
  padding-right: 30px;
  max-width: 1156px;
  margin: auto;
}

.wrapper {
  width: 100%;
  border-radius: 8px;
  flex-grow: 1;
}

.wrapper_side {
  width: 212px;
  margin-left: 23px;
  flex-shrink: 0;
}

.inner {
  background: rgba(255, 255, 255, 0.8);
  border-radius: 8px;
  margin-top: 12px;
  padding: 24px;
  min-width: 919px;

  .input_label {
    color: rgb(162, 171, 201);
    font-family: Nunito Sans;
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0%;
    text-align: left;

    height: 19px;
    margin-bottom: 8px;
  }

  .content {
    display: flex;
    flex-direction: row;
    gap: 48px;
  }
}