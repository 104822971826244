.wrapper {
  width: 100%;
  box-sizing: border-box;
}

.body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.body_goods_list {
  background-color: #FFFFFF;
  border-radius: 8px 8px 0 0;
  width: 100%;
}

.body_wrapper__navigate {
  height: 43px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}