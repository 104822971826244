.account {

  .input {
    width: 424px;
  }


  .info,
  .warning {
    display: flex;
    gap: 8px;
    align-items: center;

    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;

    &::before {
      content: '';
      width: 24px;
      height: 24px;
    }

  }

  .info {
    color: #67E8C1;

    &::before {
      background-image: url('../../assets/v.svg');
    }
  }

  .warning {
    color: #DA79D5;

    &::before {
      background-image: url('../../assets/I.svg');
    }
  }

  .btns {
    margin-top: 56px;
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
  }

  .edit_btn,
  .save_btn {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  .edit_btn {
    background-image: url('./assets/edit.svg');
  }

  .save_btn {
    background-image: url('./assets/save.svg');
  }
}

.row_wrap {
  display: flex;
  align-items: center;
  gap: 24px;
}