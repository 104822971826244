* {
  box-sizing: border-box;
}

html,
body,
#root,
#root > div {
  min-height: 100%;
  margin: 0;   
  // display: inline-block;
  position: relative;
  min-width: 1600px;
}

body {
  font-family: Roboto;
  font-size: 14px;
  line-height: 1.4;
  // background-image: url("../assets/images/authBackground.svg");
  background-image: url("../assets/images/Group 8080.jpg");
  // background-size: cover;
  background-repeat: repeat;
}

input,
button {
  font-family: Roboto;
  font-size: 14px;
  line-height: 16px;
  cursor: pointer;
}

// fonts
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@700&display=swap");
