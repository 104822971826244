@import "../../../../../../../../../styles/fonts.scss";
@import "../../../../../../../../../styles/colors.scss";

.table_body {
  width: 80vw;
}

.wraper_row {
  width: 80vw;

  display: flex;
  justify-content: space-around;
  align-items: center;

  min-height: 60px;
  border-bottom: 1px solid $color-gray;
  @extend %font_products_items;
  padding: 10px 0px;

  &:hover,
  &.selected {
    td {
      background-color: $color-gray;
    }
  }

  .size {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

}

// .checkbox {}

.foto_product {
  div {
    width: 40px;
    height: 100%;
    // border: 1px solid black;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

// .checkbox {}

// .foto_prodoct {}

// .article {}

// .name_prod {}

// .brand {}

// .sex {}

// .category {}

// .subcategory {}

td.align_center {
  align-items: center;
  justify-content: center;
  text-align: center;
}

td.season {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

// td.price {
//   text-align: right;
//   justify-content: flex-end;
// }

.status {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 2px;

  span {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 11px;
    line-height: 15px;

    text-align: center;
  }

  img {
    width: 13px;
    height: 12px;
  }
}


// .barcode {}

// .create_data {}

.low {
  color: #FFC83A;
}

.warning,
.empty {
  color: #FF5980;
}