.leftovers {

  color: var(--gray-dark-text, #5B637E);

  /* text */
  font-family: Nunito Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  a {
    color: var(--lilac, #9B7FFC);
    text-decoration-line: underline;
  }

  .controls {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .search {
      width: 420px;
    }

    .btns {
      display: flex;
      gap: 12px;
    }
  }

}