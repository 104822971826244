$photo-width: 188px;
$photo-height: 254px;

.hiddenDZ {
  display: none;
}

.variant_photos_wrap {
  display: flex;
  flex-direction: column;
  gap: 32px;
  min-width: 390px;

  .variant_photos {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .upload_btn {
      display: flex;
      gap: 8px;
      align-items: center;

      font-family: 'Nunito Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 19px;

      color: #9B7FFC;

      cursor: pointer;


      &:before {
        content: '';
        width: 16px;
        height: 16px;
        background-image: url('../../assets/upload.svg');
      }
    }
  }
}

.remove_controls {
  display: flex;
  gap: 32px;
  align-items: center;
}

.photos_wrap {
  display: flex;
  gap: 18px;
  // margin-top: 10px;
  // margin-bottom: 16px;
}

.photos {
  // width: $photo-width;
  // display: flex;
  // justify-content: space-between;
  // align-items: center;
}


.photo_dropzone,
.photo {
  background-color: #FFFFFF;

  border: 1px dashed #A2ABC9;
  border-radius: 8px;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #222222;

  cursor: pointer;
}

.photo_dropzone {
  gap: 8px;
  padding: 27px;

  &.is_empty {
    width: $photo-width;
    height: $photo-height;
  }

  &.is_not_empty:before {
    content: '';
    width: 24px;
    height: 24px;
    background-image: url('../../assets/plus.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
}

.photo {
  width: $photo-width;
  height: $photo-height; // 314px;

  border: none;
  position: relative;
  overflow: hidden;

  img {
    max-width: 100%;
    max-height: 100%;
  }

  .set_as_main_photo_label {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 32px;
    background: #9B7FFC;
    // opacity: 0.9;

    display: flex;
    align-items: center;
    gap: 8px;
    padding-left: 12px;

    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;

    color: #FFFFFF;

    opacity: 0;
    transition: opacity 0.1s ease-in;

    cursor: pointer;
  }

  &:hover {
    .set_as_main_photo_label {
      opacity: 0.9;
    }
  }
}


.has_photos {
  .photos {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 14px 15px;
  }

  .photo,
  .photo_dropzone {
    // max-width: 110px;
    // max-height: 150px;
    width: $photo-width;
    height: $photo-height;
  }

  .photo_dropzone {
    display: flex;

    &:after {
      content: '';
      background-image: url('../../assets/plus.svg');
      width: 20px;
      height: 20px;
      background-size: contain;
    }

    .dropzone_content {
      display: none;
    }
  }
}

.has_photo {
  .photo_dropzone {
    display: none;
  }
}

.upload_info {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #FF5980;

  // width: 232px;

  // margin-top: 8px;
  // margin-bottom: 32px;
}

.upload_text {
  color: #9B7FFC;
}

// .mask {
//   position: absolute;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   background-color: rgba(0, 0, 0, 0.5);
//   opacity: 0;

//   display: flex;
//   justify-content: center;
//   align-items: center;
//   transition: opacity 0.2s ease-out;

//   &:hover {
//     opacity: 1;
//   }

//   .delete {
//     position: relative;
//     padding: 12px 10px;
//     border: 2px solid #fff;
//     border-radius: 8px;
//     color: #fff;

//     display: flex;
//     gap: 5px;
//     justify-content: space-between;
//     align-items: center;

//     font-family: "Nunito Sans";
//     font-style: normal;
//     font-weight: 600;
//     font-size: 13px;
//     line-height: 18px;
//     cursor: pointer;

//     &::before {
//       content: "";
//       display: block;
//       // position: absolute;
//       // top: 50%;
//       // left: 5px;
//       // transform: translateY(-50%);
//       width: 20px;
//       height: 20px;

//       background-image: url("../../assets/trash_icon.svg");
//       background-size: contain;
//       background-repeat: no-repeat;
//     }
//   }
// }

// readonly
.readonly {
  display: flex;
  flex-direction: column;
  gap: 40px;
  // padding-right: 80px;

  .readonly_title {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 120%;
    color: #A2ABC9;
    margin-bottom: 20px;
  }

  .readonly_photo {
    width: 188px;
    height: 254px;
    background: #FFFFFF;
    border-radius: 4.78981px;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    justify-content: center;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .readonly_photos_list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
    margin-right: 40px;
  }

  .readonly_main_photo_label {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 32px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #47F2A7;
    opacity: 0.9;

    display: flex;
    align-items: center;
    gap: 8px;
    padding-left: 12px;

    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;

    color: #FFFFFF;

    &::before {
      content: '';
      width: 10px;
      height: 9px;
      background-image: url('../../assets/v.svg');
      background-repeat: no-repeat;
    }
  }


}

.preview {

  .photo_wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;

    width: 534px;
    height: 534px;
    overflow: hidden;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .btn {
    width: 80px;
    height: 80px;
    background-image: url('../../assets/arrow.svg');
    position: absolute;
    top: 50%;
    cursor: pointer;
  }

  .btn_prev {
    left: 50px;
  }

  .btn_next {
    right: 50px;
    rotate: 180deg;
  }

  .info {

    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    text-align: left;
    color: #A2ABC9;
  }
}

.remove_cb_bg {
  position: absolute;
  width: 130px;
  height: 60px;
  left: -45px;
  top: -5px;
  background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
  transform: rotate(-45deg);
}

.remove_cb {
  position: absolute;
  top: 12px;
  left: 12px;
}

.main_photo_label {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 32px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #47F2A7;
  opacity: 0.9;

  display: flex;
  align-items: center;
  gap: 8px;
  padding-left: 12px;

  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;

  color: #FFFFFF;

  &::before {
    content: '';
    width: 10px;
    height: 9px;
    background-image: url('../../assets/v.svg');
    background-repeat: no-repeat;
  }

}


.uploading {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .uploading_row {
    display: flex;
    align-items: center;
    gap: 50px;

    .thumbnail_wrap {
      min-width: 55px;
      min-height: 55px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        border-radius: 4px;
      }
    }

    .uploading_file {
      flex-grow: 1;
      display: flex;
      gap: 5px;
      flex-direction: column;
      align-content: flex-start;
      justify-content: flex-end;
      align-items: flex-start;
    }

    .uploading_progress {
      width: 100%;
    }
  }
}