.items {
  // display: flex;
  // width: 1108px;
  // align-items: flex-start;
  // align-content: flex-start;
  // gap: 12px;
  // flex-wrap: wrap;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 12px;

  .item {
    display: flex;
    padding: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    flex: 1 0 0;

    border-radius: 8px;
    border: 1px solid rgba(162, 171, 201, 0.50);
    background: #FFF;
    align-items: stretch;


    .head {
      display: flex;
      justify-content: space-between;

      .date {
        color: var(--black, #222);
        font-family: Nunito Sans;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 140%;
        text-transform: uppercase;
      }

      .gate {
        color: var(--gray-text, #8289A1);
        font-family: Nunito Sans;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 140%;
      }
    }

    .btns {
      // width: 100%;

      .grid {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 10px;
      }
    }
  }

}