.content {
  margin-top: 24px;
  min-height: 1000px;

  border-radius: 8px;
  background: rgba(255, 255, 255, 0.80);

  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 48px;

  .title {
    color: var(--black, #222);

    /* h2 */
    font-family: Nunito Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
  }

  .next_btn {
    display: flex;
    justify-content: center;
  }

  .packing_option {
    display: flex;
    gap: 6px;
    flex-direction: column;
    width: 280px;

    .packing_options_title {
      color: var(--black, #222);
      font-family: Nunito Sans;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
      /* 16.8px */
      text-transform: uppercase;
    }
  }

  .btns {
    display: flex;
    gap: 8px;
    align-items: center;
  }
}