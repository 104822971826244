@import "../../../../../../../../../styles/colors.scss";

.checkbox {
  width: 20px;
  height: 20px;

  background-size: cover;
  background-repeat: no-repeat;

  background-image: url("../../../../../../../../../assets/icons/goods_list_checkbox.svg");

  &.checked,
  &:hover {
    background-image: url("../../../../../../../../../assets/icons/goods_list_checkbox_checked.svg");
  }
  &:hover {
    opacity: 0.3;
  }
  &.checked{
    opacity: 1;
  }
}