@import "../../../styles/fonts.scss";

.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0px;
  gap: 40px;

  white-space: nowrap;
  // margin-right: 202px;
}

.footer_txt {
  @extend %font_products_items;
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  text-align: right;
  color: #A2ABC9;

  display: flex;
  align-items: center;
  gap: 16px;

  .select {
    width: 100px;
  }

  // select {
  //   color: #A2ABC9;
  //   border: 0px;
  //   margin-left: 12px;
  //   outline: none;
  //   cursor: pointer;
  // }
}

.buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;
  
  padding-bottom: 5px;
  padding-top: 5px;
  overflow-x: auto;

  scrollbar-color: rgba($color-purple, .4) rgba($color-purple, .1);
  scrollbar-width: thin;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba($color-purple, .4);
    border-radius: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: rgba($color-purple, .1);
    border-radius: 8px;
  }
}

.footer_btn {
  min-width: 32px;
  height: 32px;
  cursor: pointer;
}

.footer_btn_page {
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 50%;
  background: $color-product-list-total-pages;

  &.selected {
    border: 1px solid;
  }
}

.footer_btn_forward__arrow,
.footer_btn_backward__arrow {
  cursor: pointer;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cg clip-path='url(%23clip0_2919_23278)'%3e%3cpath d='M16 31C24.2843 31 31 24.2843 31 16C31 7.71573 24.2843 1 16 1C7.71573 1 1 7.71573 1 16C1 24.2843 7.71573 31 16 31Z' stroke='%23A2ABC9' stroke-width='1.3' stroke-miterlimit='10'/%3e%3cpath d='M17 21L22 16L17 11' stroke='%23A2ABC9' stroke-width='1.3' stroke-linecap='round' stroke-linejoin='round'/%3e%3cpath d='M10 16H22' stroke='%23A2ABC9' stroke-width='1.3' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/g%3e%3cdefs%3e%3cclipPath id='clip0_2919_23278'%3e%3crect width='32' height='32' fill='white'/%3e%3c/clipPath%3e%3c/defs%3e%3c/svg%3e ");
}

.footer_btn_backward__arrow {
  transform: rotateY(180deg);
}