.wrapper {
  width: 100%;
  max-width: 700px;
  margin: 30px auto;

  user-select: none;
  // border-bottom: 4px solid #fff;
}

.list {
  list-style: none;
  padding: 0;
  margin: 0;

  display: flex;
  // justify-content: space-between;
  align-items: center;
}

.stepAccess {
  background-color: #9b7ffc;
  color: #fff;
}

.stepFail {
  background-color: transparent;
  border: 1px solid #9b7ffc;
  color: #9b7ffc;
}

.item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 400px;
  border-bottom: 4px solid #fff;



  & .active {
    border-bottom: 4px solid #9b7ffc;
    // font-weight: 600;
  }

  & span {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    // background-color: #9b7ffc;
    border-radius: 100%;
    margin-bottom: 12px;

    // color: #fff;
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
  }

  & p {
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #222222;
    opacity: 0.6;
  }
}

.itemActive {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 400px;
  border-bottom: 4px solid #9b7ffc;

  & span {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    // background-color: #9b7ffc;
    border-radius: 100%;
    margin-bottom: 12px;

    // color: #fff;
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
  }

  & p {
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #222222;
  }
}


.step_number {
  position: relative;

  img {
    position: absolute;
    right: -8px;
  }


}

.step_finished.itemActive {
  .step_number {
    opacity: 0.8;
  }

  p {
    opacity: 0.5;
  }
}