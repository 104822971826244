.wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  gap: 8px;
  padding: 4px 8px 4px 16px;
  user-select: none;
}

.checked {
  border-radius: 100px;
  border: 2px solid #A2ABC9;
}

.text {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 18px;
  color: #222222;
  white-space: nowrap;
}

.remove {
  width: 24px;
  height: 24px;
}