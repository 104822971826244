@import "./colors.scss";

%font_button {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 18px;
  color: $color-white;
  user-select: none;
}

%font_h2 {
  margin: 0;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: $color-input;
}

%font_h3 {
  margin: 0;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: $color-input;
}

%font_white_paragraph {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: white;
}

%font_input {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: $color-input;
}

%font_code_input {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  margin: 0;
  color: $color-input;
}

%font_placeholder {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: $color-placeholder-input;
}

%font_small_paragraph {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: $color-gray-blue;
  margin: 0;
}

%font_input_hint {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: $color-input;
}

%font_code_placeholder {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  color: $color-placeholder-input;
}

%font_gray_dark_paragraph {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: $color-gray-dark;
}

%font_purple_paragraph{
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: $color-purple;
}

%font_gray_paragraph {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  margin: 0;
  color: $color-gray-dark;
}

%font_hint {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: $color-gray-dark;
}

%font_gray_blue_p {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  margin: 0;
  line-height: 20px;
  text-align: center;
  color: $color-gray-blue;
}

%font_pink_error {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  margin: 0;
  color: $color-pink;
}

%font_trash_placeholder {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #222222;
}

%font_btn_navigate_goods_list {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #222222;
}

%font_button_navigate_goods_numbers {
  font-family: "Nunito Sans";
  font-size: 9px;
  font-weight: 700;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: center;
  color: #4773eb;
}

%font_products_items {
  font-family: "Nunito Sans";
  font-size: 13px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  color: #5b637e;
}
