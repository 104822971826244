@import '../../../../../styles/colors.scss';
@import '../../../../../styles/fonts.scss';

.headerContainer {
  display: flex;
  align-items: center;
}

.header {
  @extend %font_h3;
  color: $color-gray-dark;
  margin-right: 6px;
}
.hint {
  &:before {
    max-width: 500px;
  }
}

.radioButtonContainers {
  display: flex;
  justify-content: space-between;
  width: 188px;
  margin-top: 12px;
}

@media screen and (max-height: 770px) {
  .radioButtonContainers {
    margin-top: 6px;
  }
}