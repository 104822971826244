.wrap {
  // background: rgba(255, 255, 255, 0.8);
  // background: rgba(255, 255, 255, 1);
  // border-radius: 8px;

  // padding: 24px;

  user-select: none;
  padding-bottom: 100px;
}

.search {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;

  .input {
    width: 50%;
  }

  .text {
    margin-right: 100px;
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    text-align: right;
    color: #A2ABC9;
  }
}



.category {
  .title {
    min-height: 36px;

    display: flex;
    align-items: center;
    gap: 12px;

    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    // color: #222222;

    border-bottom: 1px solid #EBECFF;
    cursor: pointer;
  }

  .title.empty {
    background: #FFFFFF;
    cursor: auto;
    border-bottom: none;
  }

  .title img,
  .title.empty .gag {
    width: 12px;
    height: 6px;
  }

  &.open>.title img {
    rotate: 180deg;
  }

  .title .data {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-grow: 1;
  }

  .title .progress {
    display: flex;
    gap: 24px;
    align-items: center;
  }

  .title .progress .percent {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 140%;
    text-align: right;
    color: #222222;
  }
}

.lvl_0 .title {
  color: #5B637E;
}

.lvl_1 .title {
  padding-left: 24px;
  color: #5B637E;
}

.lvl_2 .title {
  padding-left: 48px;
  border-bottom: none;
  color: #222222;
}

.lvl_3 .title {
  padding-left: 48px;
  border-bottom: none;
  color: #222222;
}

.progress_bar {
  width: 300px;
}