.option_selector {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
  flex-grow: 1;
  user-select: none;

  .option {
    height: 51px;
    background: #EBECFF;
    border-radius: 4px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 16px;

    flex: none;
    align-self: stretch;
    flex-grow: 1;

    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    text-align: right;
    color: #222222;

    cursor: pointer;

    max-width: 120px;
  }

  .option:hover,
  .option.selected {
    background: #9B7FFC;
    color: #FFFFFF;
  }
}
