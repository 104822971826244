.prices {
  .info {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;

    color: #222222;

    margin-bottom: 32px;
  }

  .price_for_any {
    display: flex;
    align-items: center;
    gap: 24px;
  }

  .big_input {
    width: 30%;
  }

  .controls {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .colors_prices {
    margin-top: 25px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 32px;
  }

  .color_sizes {
    margin-top: 30px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 18px;
  }

  .empty_color_sizes {
    display: flex;

    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 120%;
    color: #A2ABC9;

    grid-column: 1/4;
    align-items: center;
    justify-content: center;
    // margin-top: 10px;
  }
}