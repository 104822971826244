.indicators {
  display: flex;
  justify-content: space-between;
  gap: 12px;

  .indicators_list {
    display: flex;
    flex-flow: row wrap;
    flex-grow: 1;
    gap: 12px;

    &.no_btn {
      gap: 24px;
    }

    .indicator {
      flex-grow: 1;
      width: calc(100%/6);
      transition: all .2s ease-in-out;

      font-family: 'Nunito Sans';
      font-style: normal;
      line-height: 140%;
      color: #222222;

      .indicator_message {
        font-weight: 700;
        font-size: 16px;
      }

      .indicator_growth {
        font-weight: 400;
        font-size: 14px;
        display: flex;
        align-items: center;
        gap: 6px;

        &::after {
          content: '';
          width: 14px;
          height: 15px;
          background-image: url('./assets/arrow_up.svg');
          background-position: center;
          background-repeat: no-repeat;
        }

        &.down {
          &::after {
            background-image: url('./assets/arrow_down.svg');
          }
        }
      }

      &:hover, .active {
        // filter: drop-shadow(4px 4px 40px rgba(155, 127, 252, 0.4));

        box-shadow: 4px 4px 40px rgba(155, 127, 252, 0.4);
      }
      &.selected {
        // background: linear-gradient(0deg, rgba(155, 127, 252, 0.12) 0%, rgba(155, 127, 252, 0.12) 100%), #FFF;
        // border: 1px solid black;
      }
    }
  }

  .indicators_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    background: linear-gradient(310.28deg, #CC8AFF 0%, #8AB9FF 100%);
    border-radius: 8px;
    width: 126px;

    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 140%;

    color: #FFFFFF;

    cursor: pointer;

    &::after {
      content: '';
      background-image: url('./assets/arrow.svg');
      background-repeat: no-repeat;
      width: 8px;
      height: 10px;
    }
  }


}