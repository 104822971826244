@import './src/styles/fonts';
@import "./src/styles/colors";

.searchFieldWrapper {
    // position: relative;
    // display: inline-block;
    width: 430px;
    // border-radius: 8px;
    // border: 1px solid #a2abc9;
    // input{
    //     @extend %font_placeholder  
    // }
   
}

.searchLogo {
    width: 17px;
    position: absolute;
    right: 10px;
    top: 16px;   
}

.searchField {
    width: '100%';
    padding: 12px 16px;
    border-radius: 8px;
    border: 1px solid white;
    outline: none;
    font-family: 'Nunito Sans';
}

.sort {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    color: #a2abc9;

    div {
        display: flex;
        align-items: center;
        margin: 0 20px 0 0;
    }

    p {
        margin: 0 10px 0 0;
        cursor: pointer;
    }
}
@media screen and (max-width: 400px) {
   .searchFieldWrapper{
    width: 300px;
}}
