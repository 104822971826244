.panel {

  font-family: 'Nunito Sans';
  font-style: normal;
  line-height: 140%;
  user-select: none;

  &.clickable {
    cursor: pointer;
  }

  .head {
    background: linear-gradient(310.28deg, #CC8AFF 0%, #8AB9FF 100%), linear-gradient(310.28deg, #CC8AFF 0%, #B08AFF 100%), #B08AFF;
    border-radius: 8px 8px 0px 0px;
    padding: 12px 16px;

    font-weight: 700;
    font-size: 12px;
    text-transform: uppercase;
    color: #FFFFFF;
  }

  .body {
    background: #F8F9FC;
    border-radius: 0px 0px 8px 8px;
    padding: 16px;

    font-weight: 400;
    font-size: 14px;
    color: #222222;
  }

  &.selected {
    .head {
      border-radius: 8px 8px 0px 0px;
      background: var(--gradient-1, linear-gradient(135deg, #FF8EF6 0%, #9B7FFC 100%));
    }

    .body {
      border: 1px solid #FF8EF6;
      border-radius: 0px 0px 8px 8px;
      background: linear-gradient(0deg, rgba(155, 127, 252, 0.12) 0%, rgba(155, 127, 252, 0.12) 100%), #FFF;
      //   border-color: #000000;
      // border-radius: 8px 8px 8px 8px;
    }
  }
}