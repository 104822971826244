@import "../../../../../styles/colors.scss";
@import "../../../../../styles/fonts.scss";

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
  top: 33px;
  background-color: rgba($color-white, 0.8);
  backdrop-filter: blur(8px);
  border-radius: 8px;
  padding: 64px 0 56px 0;
  width: 684px;
  height: 440px;
  margin-bottom: 150px;
}

.header {
  @extend %font_h2;
}

@media screen and (max-height: 800px) {
  .container {
    top: 100px;
  }
}

@media screen and (max-height: 645px) {
  .container {
    padding: 40px 10px 56px 10px;
    top: 60px;
    width: 465px;
    height: 300px;
  }

  .header {
    font-size: 21px;
  }
}