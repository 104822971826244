@import "../../../styles/fonts";

.wrapper {
  width: 100%;
}

.selectWrapper {
  width: 98%;
  position: relative;
  pointer-events: all;

  &::after {
    position: absolute;
    content: "";
    display: block;
    width: 12px;
    height: 12px;
    top: 45%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;

    background-image: url("./assets/openArrow.png");
    background-size: contain;
    background-repeat: no-repeat;
  }
}

.select {
  width: 100%;
  // min-width: 180px;
  // max-width: 542px;
  border: 1px solid rgba(162, 171, 201, 0.5);
  border-radius: 8px;
  padding: 7px 10px;
  height: 50px;
  outline: none;
  appearance: none;
  @extend %font_input;

  &:hover {
    cursor: pointer;
  }
}

.label {
  position: relative;
  font-size: 13px;
  color: #a2abc9;

  @extend %font_input;
}

.required {
  position: relative;
  font-size: 13px;
  color: #a2abc9;

  @extend %font_input;

  &::after {
    content: "*";
    position: absolute;
    top: 0;
    right: -10px;
    height: 0;
    color: red;
    z-index: 10;
  }
}

.option {
  @extend %font_input;
}
