@import "../../../../../../../styles/fonts.scss";
@import "../../../../../../../styles/colors.scss";

.wrapper {

  padding: 24px 0;
  min-height: 60vh;

  overflow-x: auto;
  box-sizing: border-box;
  height: 100%;
  background: $color-white;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  background-color: #fff;

  &::-webkit-scrollbar {
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #EBECFF;
    border-radius: 50px;
  }

  &::-webkit-scrollbar-thumb {
    background: linear-gradient(0deg, rgba(155, 127, 252, 0.4), rgba(155, 127, 252, 0.4)), #EBECFF;
    border-radius: 50px;
  }
}

.body {
  width: 100%;
  height: 100%;

  display: grid;
  border-collapse: collapse;
  min-width: 100%;
  //                      checkbox          фото              артикул           наименование        бренд             пол                тип               категория           подкатегория      размер           сезон             статус            штрихкод          дата создания           
  // grid-template-columns: minmax(60px, 2fr) minmax(60px, 2fr) minmax(100px, 4fr) minmax(120px, 5fr) minmax(60px, 3fr) minmax(70px, 3fr) minmax(70px, 3fr) minmax(90px, 4fr) minmax(100px, 5fr) minmax(60px, 3fr) minmax(60px, 3fr) minmax(80px, 3fr) minmax(100px, 4fr) minmax(90px, 4fr);
  //                      checkbox          фото              артикул           наименование        бренд             пол                тип               категория           подкатегория      размер           Цвет             Розничная цена       сезон             Остатки FBO       Остатки FBS         дата создания           
  grid-template-columns: minmax(60px, 2fr) minmax(60px, 2fr) minmax(140px, 4fr) minmax(120px, 5fr) minmax(120px, 4fr) minmax(70px, 3fr) minmax(70px, 3fr) minmax(90px, 4fr) minmax(100px, 5fr) minmax(60px, 3fr) minmax(120px, 4fr) minmax(120px, 4fr) minmax(60px, 3fr) minmax(100px, 4fr) minmax(100px, 4fr) minmax(120px, 120px);

  &.discount_tab {
    //                      checkbox          фото              артикул           наименование        бренд             пол                тип               категория           подкатегория      размер           Цвет             Розничная цена          Скидка %           сезон             Остатки FBO       Остатки FBS         дата создания           
    grid-template-columns: minmax(60px, 2fr) minmax(60px, 2fr) minmax(140px, 4fr) minmax(120px, 5fr) minmax(120px, 4fr) minmax(70px, 3fr) minmax(70px, 3fr) minmax(90px, 4fr) minmax(100px, 5fr) minmax(60px, 3fr) minmax(120px, 4fr) minmax(120px, 4fr) minmax(80px, 3fr) minmax(60px, 3fr) minmax(100px, 4fr) minmax(100px, 4fr) minmax(120px, 120px);
  }

  thead,
  tbody,
  tr {
    display: contents;
  }

  th,
  td {
    display: flex;
    align-items: center;

    overflow: hidden;
    text-overflow: ellipsis;
    // white-space: nowrap;
    border-bottom: 1px solid #EBECFF;
    text-align: left;
  }

  td {
    // height: 62px;
    min-height: 62px;
    padding: 8px 5px;
    margin-top: -1px;
  }

  th {
    height: 28px;
  }

  th.align_center {
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  th:first-child,
  td:first-child {
    padding-left: 24px;
    border-bottom: none;
    position: relative;

    &::after {
      content: "";
      background: #EBECFF;
      position: absolute;
      bottom: 0;
      right: 0;
      height: 1px;
      width: calc(100% - 24px);
    }
  }

  th:last-child,
  td:last-child {
    padding-right: 24px;
    border-bottom: none;
    position: relative;

    &::after {
      content: "";
      background: #EBECFF;
      position: absolute;
      bottom: 0;
      left: 0;
      height: 1px;
      width: calc(100% - 24px);
    }
  }
}

.title_wrap {
  display: flex;
  @extend %font_products_items;
  font-size: 12px;
  user-select: none;
  white-space: nowrap;

  // &.can_be_sorted {
  //   cursor: pointer;
  // }
  &.can_be_sorted {
    cursor: pointer;

    .title::after {
      background-image: url("../../../../../../../assets/icons/arrow_products_cetegoris_title.svg");
      margin-right: 5px;
    }
  }

  .title {
    display: flex;
    align-items: center;
    gap: 2px;
  }

  .title::after,
  .title::after {
    content: "";
    width: 8px;
    height: 10px;
    background-size: cover;
    background-repeat: no-repeat;
  }

  &.order_asc .title::after,
  &.order_desc .title::after {
    // background-image: url("../../../../../../../assets/icons/arrow_products_cetegoris_title.svg");
    background-image: url("../../../../../../../assets/icons/pink_arrow.svg");
  }

  &.order_asc .title::after {
    transform: scaleY(-1);
  }
}

.table_body {
  height: 50vh;
}

.arrow {
  margin-left: 6px;
}