@import '../../../../../styles/fonts.scss';

.headerContainer {
  display: flex;
  align-items: center;
}

.header {
  @extend %font_gray_paragraph;
  margin-right: 6px;
}

.input {
  width: 100%;
  height: 54px;
  margin-top: 4px;
}

.recomandation {
  margin-top: 12px;
  @extend %font_small_paragraph;
}
