.remaining_stock {
  .controls {
    display: flex;
    align-items: center;
    margin-bottom: 45px;

    .fbo_select {
      width: 300px;
    }

    .selects {
      display: flex;
      gap: 12px;
    }

    .build_report {
      margin-left: 24px;
    }

    .hint {
      margin-left: 200px;
    }

    .export_btn {
      margin-left: 17px;
    }
  }

  .storage_title {
    // height: 36px;
    // word-wrap: normal;
    // text-overflow: ellipsis;
    white-space: normal;
    word-wrap: normal;
    width: 100%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

}

.center {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}