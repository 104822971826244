@import '../../../../../styles/fonts.scss';

.title {
  @extend %font_h2;
  text-align: left;
  width: min-content;
}

.inputContainer {
  width: 400px;
  margin-bottom: 24px;
  margin-top: 32px;
}

.button {
  margin-top: 32px;
  width: 111px;
  height: 52px;
  justify-content: center;
}

.errorText {
  position: absolute;
  margin-top: 140px;
}

@media screen and (max-height: 900px) {
  .inputContainer {
    width: 300px;
  }
}
@media screen and (max-height: 770px) {
  .inputContainer {
    max-width: 230px;
    margin-bottom: 16px;
    margin-top: 24px;
    input {
      height: 40px !important;
      width: 100% !important;
    }
  }
  .button {
    height: 40px;
    width: 100px;
    padding: 16px 9px;
  }
  .errorText {
    margin-top: 120px;
    font-size: 12px !important;
  }
}