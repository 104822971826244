.footer {
  width: 100%;
  height: 120px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #FFFFFF;
  border-radius: 0 0 8px 8px;

  .buttons {
    margin-left: 24px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 24px;
    gap: 8px;
  }
}

.footer_items {
  display: flex;
  justify-content: center;
  align-items: center;
}



.arrow {
  margin-left: 6px;
}

.debug_btns_wrap {
  position: fixed;
  top: 100px;
  right: 0px;
  width: 10px;
  overflow: hidden;
  opacity: 0.6;
  transition: 0.2s ease-out;
  background-color: #FFFFFF;

  &:hover {
    opacity: 1;
    // right: 210px;
    width: 200px;
    // background-color: #FFFFFFFF;
  }

  .debug_btns {
    width: 200px;
    padding: 10px;
  }
}