.calendar_week {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.wrap {
  display: flex;
  flex-direction: column;
  gap: 4px;
  position: relative;

  min-height: 40vh;
}

.actions {
  padding-top: 46px;
  padding-bottom: 40px;
  z-index: 1;
}

.action {
  filter: drop-shadow(8px 8px 40px rgba(131, 103, 228, 0.2));
  width: 500px;
  margin-bottom: 8px;
  margin-left: 4px;

  cursor: pointer;

  .action_head {
    height: 54px;

    background: #9B7FFC;
    border-radius: 8px 8px 0px 0px;
    padding: 16px;

    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #FFFFFF;
  }

  .action_body {
    display: flex;
    flex-direction: column;
    gap: 10px;

    background: #FFFFFF;
    border-radius: 0px 0px 8px 8px;
    padding: 16px;


    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #222222;

    img {
      vertical-align: middle;
      margin-right: 4px;
    }
  }
}

.table {
  display: flex;
  gap: 4px;

  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.week_days {
  display: flex;
  gap: 4px;
}

.week_cell {
  flex-grow: 1;
  display: flex;
  gap: 4px;
  flex-direction: column;
  width: calc(100%/7 - 4px);
}

.day_head {
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;

  background: linear-gradient(0deg, rgba(155, 127, 252, 0.2), rgba(155, 127, 252, 0.2)), #EBECFF;
  border-radius: 0px;

  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;

  text-transform: uppercase;

  color: #9B7FFC;
}

.day_body {
  flex-grow: 1;
  background: #EBECFF;

  padding: 12px;


  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height */

  text-align: right;
  text-transform: uppercase;

  color: rgba(34, 34, 34, 0.7);
}

.wrap:last-child .week_cell.day_body:nth-child(1) {
  border-radius: 0px 0px 0px 8px;
}

.wrap:last-child .week_cell.day_body:nth-child(7) {
  border-radius: 0px 0px 8px 0px;
}



// compact view для месяца
.wrap.compact_view {
  min-height: 120px;

  .actions {
    padding-top: 52px;
  }

  .action_body {
    display: none;
  }

  .action_head {
    height: 30px;
    background: #9B7FFC;
    border-radius: 4px;

    padding: 4px 8px;


    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;

    color: #FFFFFF;
  }

  .is_another_month {
    color: rgba(34, 34, 34, 0.3);
  }

}





// grid
.col-1 {
  width: calc(14.286% - 8px);
}

.col-2 {
  width: calc(28.572% - 8px);
}

.col-3 {
  width: calc(42.858% - 8px);
}

.col-4 {
  width: calc(57.144% - 8px);
}

.col-5 {
  width: calc(71.430% - 8px);
}

.col-6 {
  width: calc(85.716% - 8px);
}

.col-7 {
  width: calc(100.002% - 8px);
}

.offset-0 {
  margin-left: calc(0.000% + 4px);
  max-width: calc(100.000% - 8px);
}

.offset-1 {
  margin-left: calc(14.286% + 4px);
  max-width: calc(85.714% - 8px);
}

.offset-2 {
  margin-left: calc(28.572% + 4px);
  max-width: calc(71.428% - 8px);
}

.offset-3 {
  margin-left: calc(42.858% + 4px);
  max-width: calc(57.142% - 8px);
}

.offset-4 {
  margin-left: calc(57.144% + 4px);
  max-width: calc(42.856% - 8px);
}

.offset-5 {
  margin-left: calc(71.430% + 4px);
  max-width: calc(28.570% - 8px);
}

.offset-6 {
  margin-left: calc(85.716% + 4px);
  max-width: calc(14.284% - 8px);
}