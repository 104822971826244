@import "../../../styles/backgrounds.scss";

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  @extend %bg_auth;
}

.logo {
  // position: fixed;
  top: 54.5px;
}
@media screen and (max-height: 645px) {
  .logo {
    top: 30px;
    width: 100px;
  }
}
