@import "../../../styles/backgrounds.scss";

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-right: 30px;
}

.body {
  background: rgba(255, 255, 255, 0.8);
  border-radius: 8px;
  padding: 31px 24px;
}

.row1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 48px;
  margin-bottom: 40px;

  &>* {
    // flex-grow: 1;
    width: 50%;
  }
}

.footer {
  margin-top: 32px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;

  .warning {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #FF5980;
    display: none;

    &.is_visible {
      display: block;
    }

    img {
      margin-right: 10px;
    }
  }

  .btns {
    gap: 32px;

    // .icon {
    //   width: 13px;
    //   height: 8px;
    // }
  }
}

.body_title {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;

  text-transform: uppercase;

  color: #222222;

  margin: 0 0 16px;
}

.btns {
  display: flex;
}