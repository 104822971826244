.new_orders {
  .table {
    font-family: Nunito Sans;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    th {
      align-items: center !important;
    }

    td {
      align-items: start;
    }

    .status {
      text-align: left;
      display: flex;
      flex-direction: column;
      gap: 6px;

      .track {
        color: #8289A1;
        font-family: Nunito Sans;
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
      }

      .statuses {

        color: #A2ABC9;
        font-family: Nunito Sans;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;

        display: flex;
        flex-direction: column;
        gap: 6px;

        .statuses_history_wrap {
          display: flex;
          gap: 3px;
          align-items: stretch;

          .line {
            width: 1px;
            // height: 100%;
            margin-bottom: 30px;
            background-image: linear-gradient(to top, #fff 40%, #A2ABC9 40%);
            background-size: 1px 15px;
            background-repeat: repeat-y;
          }

          .statuses_history {
            display: flex;
            flex-direction: column;
            gap: 20px;
            padding-left: 4px;

            // border-left: 1px dashed #A2ABC9;


            .status_name {
              color: var(--gray-text, #8289A1);
              font-size: 13px;
              font-weight: 600;
              margin-left: -10px;
            }
          }
        }

        .status_name {
          display: flex;
          gap: 3px;
          align-items: center;

          .dot {
            width: 6px;
            height: 6px;
            border-radius: 100px;
            background-color: #A2ABC9;
          }
        }


        .main_status {
          .status_name {
            color: #5EB9FC;
            font-size: 13px;
            font-weight: 600;

            .dot {
              background-color: #5EB9FC;
            }
          }

          .status_city,
          .status_time {
            color: #8289A1;
          }
        }
      }

      .toggle {
        color: var(--gray-text, #8289A1);
        font-family: Nunito Sans;
        font-size: 10px;
        font-style: normal;
        font-weight: 600;
        text-decoration-line: underline;
        cursor: pointer;
      }
    }

    .address,
    .dtype {
      text-align: left;
      color: var(--gray-text, #8289A1);
      font-family: Nunito Sans;
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
    }

    .docs {
      display: flex;
      flex-direction: column;
      gap: 6px;
      text-align: left;

      a {
        color: #9B7FFC;
        font-family: Nunito Sans;
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        text-decoration-line: underline;
      }
    }

    .id {
      text-align: left;

      .number {
        color: var(--black, #222);
      }

      .date {
        color: var(--gray-blue, #A2ABC9);
      }

      .boxes {
        color: var(--gray-blue, #A2ABC9);
        font-family: Nunito Sans;
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
      }
    }

    .dedlineDate {
      text-align: left;

      .date {
        color: var(--gray-text, #8289A1);

      }

      .status {
        color: var(--green-text, #39C286);

        &.expired {
          color: var(--pink, #FF5980);
        }
      }
    }

    .rates {
      display: flex;
      flex-direction: column;
      gap: 12px;

      .rate {
        text-align: left;

        display: flex;
        flex-direction: column;

        .product {
          color: #9B7FFC;
          text-decoration: underline;
        }

        .code {
          color: var(--gray-blue, #A2ABC9);
        }
      }
    }

    .cost {
      justify-content: center;
    }

    td.cost {
      color: var(--gray-text, #8289A1);
      text-align: center;
      font-family: Nunito Sans;
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
    }

    .empty_text {
      text-align: center;

      h1 {
        color: var(--gray-dark-text, #5B637E);
        text-align: center;

        margin: 6px;

        /* h1 */
        font-family: Nunito Sans;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        text-transform: uppercase;
      }

      span {
        color: var(--gray-blue, #A2ABC9);
        text-align: center;

        /* text */
        font-family: Nunito Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
      }
    }
  }

  .period {
    display: flex;
    flex-direction: column;
    gap: 6px;
    margin-bottom: 32px;

    .title {
      color: var(--black, #222);
      font-family: Nunito Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
    }

    .period_selector {
      display: flex;
      gap: 24px;
      align-items: center;

      .switch {
        flex-grow: 0;
      }

      .dates {
        display: flex;
        gap: 24px;
      }
    }
  }


  .footer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 55px;
  }
}