.statistic_by_category {


  .mode_selector {
    display: flex;
    justify-content: flex-end;
  }

  .period {
    display: flex;
    flex-direction: column;
    gap: 8px;

    margin-top: 48px;

    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #222222;

    .period_selector {
      display: flex;
      align-items: center;
      gap: 24px;

      .calendar_period_selector {
        flex-grow: 0;
      }
    }
  }

  .build_report {
    margin-top: 44px;
  }

  .statistic_by_barcode {

    h1 {
      text-align: left;
      margin-top: 72px;
      margin-bottom: 24px;
    }

    .barcode {
      display: flex;
      gap: 24px;
      align-items: center;
      max-width: 600px;

      .barcode_input {
        flex-grow: 1;

        input {
          height: 53px;
        }
      }
    }
  }
}