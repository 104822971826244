@import "../../../../../../../styles/fonts";

.selectEllipse {
  width: 248px;
  height: 248px;
  border: 1px dashed #a2abc9;
  border-radius: 50%;
  padding: 80px 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.selectText {
  display: flex;
  align-items: center;
  @extend %font_gray_dark_paragraph
}