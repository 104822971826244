@import "../../../styles/colors.scss";
@import "../../../styles/fonts.scss";

.question {
  position: relative;
  height: 16px;
  width: 16px;
  text-align: center;
  user-select: none;
  white-space: pre-wrap;
  background-image: url("../../../assets/icons/questionIco.svg");
  background-size: contain;
  transition: background 0.2s linear;
  transition-delay: 0.3s;

  margin-bottom: 1px;

  &:hover {
    transition-delay: 0s;
    background-image: url("../../../assets/icons/activeQuestionIco.svg");
  }

  &:before {
    z-index: 7;
    position: absolute;
    visibility: hidden;
    content: attr(data-hover);
    text-align: left;
    width: max-content;
    top: calc(100% + 8px);
    max-width: 555px;
    transition: all 0.2s linear;
    transition-delay: 0.3s;
    @extend %font_hint;
  }
}

.question:hover:before {
  height: fit-content;
  visibility: visible;
  background: $color-white;
  border-radius: 8px;
  padding: 24px;
  min-height: 15px;
  transition-delay: 0s;
  bottom: 10px;
  box-shadow: 8px 8px 40px $color-modal-shadow;
}

@media screen and (max-height: 770px) {
  .question:before {
    font-size: 10px;
    top: -40px;
    left: calc(100% + 8px);
  }
}
