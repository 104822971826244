@import "../../../../../styles/fonts.scss";
@import "../../../../../styles/colors.scss";

.table {
  display: grid;
  align-content: flex-start;
  margin-bottom: 56px;
  min-height: 100px;
  height: 100%;
  // column-gap: 16px;

  overflow-y: auto;
  scroll-behavior: smooth;
  scrollbar-color: rgba($color-purple, .4) rgba($color-purple, .1);
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    height: 8px;
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba($color-purple, .4);
    border-radius: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: rgba($color-purple, .1);
    border-radius: 8px;
  }

  thead,
  tbody,
  tr {
    display: contents;
  }

  .row {
    // width: 80vw;

    min-height: 60px;
    border-bottom: 1px solid $color-gray;
    @extend %font_products_items;
    padding: 10px 0px;

    // &:hover,
    // &.selected {
    &.deletion {
      td {
        background-color: rgba(153, 126, 252, 0.2);
        // opacity: 0.8;
      }
    }

    &.has_errors {
      td {
        background-color: rgba(254, 87, 171, 0.2); // #FE57AB;
      }
    }

    .size,
    .sizes,
    .season,
    .seasons,
    .barcodes,
    .period {
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
    }


    .retail_price,
    .discountValue,
    .discountPercent,
    .price,
    .fbo,
    .fbs,
    .discount_value,
    .brand,
    .sex,
    .size,
    .color,
    .season,
    .dateOfCreation,
    .editable_retail_price,
    .edited_price_diff,
    .edited_price_diff_percent,
    .center {
      align-items: center;
      justify-content: center;
    }



    .category {
      text-align: left;
    }

    .period span:nth-child(2) {
      opacity: 0.5;
    }

    td {
      min-height: 62px;
      padding: 8px 5px;
      margin-top: -1px;
    }

    input[type=text] {
      width: 100%;
      margin: 5%;
      height: 34px;
      border: 1px solid rgba(162, 171, 201, 0.5);
      border-radius: 4px;
    }

  }

  td,
  th {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
    border-bottom: 1px solid #EBECFF;
    // padding-left: 8px;
    // padding-right: 8px;
  }

  th {
    // height: 28px;
    min-height: 28px;
    align-items: flex-start !important;
    max-height: 40px;
  }

  th.barcode {
    height: 40px;
  }

  th.retail_price,
  th.discountValue,
  th.discountPercent,
  th.price,
  th.fbo,
  th.fbs,
  th.discount_value,
  th.brand,
  th.sex,
  th.size,
  th.color,
  th.season,
  th.dateOfCreation,
  th.editable_retail_price,
  th.edited_price_diff,
  th.edited_price_diff_percent,
  th.center {
    justify-content: center;
  }

  // .additionButtons {}
  .sortable {
    font-weight: bold;
  }


  .url,
  .photo {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .url img,
  .photo img {
    max-height: 45px;
    max-width: 100px;
  }

  .row_index {
    display: flex;
    justify-content: center;
  }

  .title_wrap {
    display: flex;
    @extend %font_products_items;
    font-size: 12px;
    user-select: none;
    white-space: nowrap;

    &.can_be_sorted {
      cursor: pointer;

      .title::after {
        background-image: url("../../assets/arrow.svg");
        margin-right: 5px;
      }
    }


    .title {
      display: flex;
      align-items: center;
      gap: 2px;
    }

    .title::after,
    .title::after {
      content: "";
      width: 8px;
      height: 10px;
      background-size: cover;
      background-repeat: no-repeat;
    }

    &.order_asc .title::after,
    &.order_desc .title::after {
      // background-image: url("../../assets/arrow.svg");
      background-image: url("../../assets/pink_arrow.svg");
    }

    &.order_asc .title::after {
      transform: scaleY(-1);
    }
  }

  .remove_btn {
    img {
      cursor: pointer;
    }
  }

  .total {

    .total_label {
      display: flex;
      justify-content: flex-end;
    }

    .total_label,
    .total_value {
      height: 38px;
      padding: 10px 16px;
      margin: 10px 10px 10px 0;
    }

    .total_label {
      background: var(--gray, #F0EEF9);

      color: var(--black, #222);
      font-size: 13px;
      font-family: Nunito Sans;
      font-weight: 700;
      text-transform: uppercase;
    }

    .total_value {
      // border: 1px solid var(--white, #FFF);
      background: var(--gray-1, #E1E2F8);

      color: var(--black, #222);
      font-size: 13px;
      font-family: Nunito Sans;
      font-weight: 600;
    }

  }

}

.empty_text {
  display: table-row !important;
  grid-column: 1/-1;
  border: none !important;
  padding-top: 120px;
  padding-bottom: 120px;

  td {
    border: none;
    margin: auto;
    display: block;
  }
}