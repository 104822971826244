@import "../../../../../styles/colors.scss";

.wrapper {
  background-color: $color-white;
  padding-top: 235px;
  padding-left: 118px;
  border-radius: 8px 30px 30px 8px;
  width: 50%;
}

@media screen and (max-height: 900px) {
  .wrapper {
    padding-top: 130px;
    padding-left: 69px;
    border-radius: 8px 30px 30px 8px;
  }
}

@media screen and (max-height: 770px) {
  .wrapper {
    padding-top: 80px;
    padding-left: 40px;
  }
}
