.packs {
  table {
    width: 100%;
    color: var(--black, #222);
    font-family: Nunito Sans;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    th,
    td {
      border-bottom: 1px solid var(--not-available, #EBECFF);
    }

    td {
      padding-top: 12px;
      padding-bottom: 12px;
    }

    .title {
      &.fade {
        color: var(--gray-blue, #A2ABC9);
      }
    }

    .docs {

      .content {
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        color: var(--unnamed, #9B7FFC);
        font-family: Nunito Sans;
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;

        a {
          text-decoration-line: underline;
        }

        .es_info {
          color: var(--gray-blue, #A2ABC9);
          font-size: 14px;
        }
      }



    }

    .sizes {
      width: 370px;

      .wrap {
        display: grid;
        // grid-template-columns: 1fr 1fr;

        grid-template-columns: 163px 163px;
        gap: 12px 24px;

        // .size {
        //   display: flex;
        //   // gap: 10px;
        //   // width: 163px;
        //   justify-content: space-between;
        //   align-items: center;
        //   color: var(--gray-blue, #A2ABC9);
        //   .size_input {
        //     width: 105px;
        //   }
        // }
      }
    }

    .weight {
      width: 130px;
    }

    .btns .wrap {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    .weight,
    .btns {
      vertical-align: top;
    }

    th {
      color: var(--gray-text, #A2ABC9);
      text-align: left;
      font-family: Nunito Sans;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
}