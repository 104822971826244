@import "../../../styles/colors.scss";
@import "../../../styles/fonts.scss";

.wrap {
  position: relative;
  display: inline-block;

  .icon {
    width: 20px;
    height: 20px;
    background-image: url("./assets/i.svg");
    background-repeat: no-repeat;
    background-size: contain;
    transition: background 0.2s linear;
    transition-delay: 0.3s;
  }

  &.active:hover .icon {
    transition-delay: 0s;
    background-image: url("./assets/i_hover.svg");
    background-repeat: no-repeat;
  }

  &.active:hover .hint {
    scale: 1;
  }

  .hint {
    position: absolute;
    margin-top: 7px;
    padding: 24px;
    background: #FFFFFF;
    box-shadow: 8px 8px 40px rgba(131, 103, 228, 0.2);
    border-radius: 8px;
    z-index: 11;

    scale: 0;
    transform-origin: top left;
    transition: scale 0.2s ease-in;
    transition-delay: 0.3s;

    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #5B637E;


    &.direction_right {
      right: 0;
      transform-origin: top right;
    }

    a {
      color: #9B7FFC;
      font-family: Nunito Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      text-decoration-line: underline;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
