.info_panel {

  background: #F8F9FC;
  border-radius: 8px;
  padding: 12px;

  font-family: 'Nunito Sans';
  font-style: normal;
  font-size: 14px;
  line-height: 140%;

  .head {
    font-weight: 600;
    color: #F35A91;

    display: flex;
    gap: 8px;
    align-items: center;
    margin-bottom: 8px;

    &::before {
      content: '';
      background-image: url('./assets/info.svg');
      width: 24px;
      height: 24px;
    }
  }

  .body {
    font-weight: 400;
    color: #5B637E;
  }
}