.wrap {
  background: #F8F9FC;
  border-radius: 8px;
  padding: 24px;
  margin-right: 30px;
  margin-bottom: 30px;

  font-family: 'Nunito Sans';
  font-style: normal;


  .info {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #FF5980;
  }

  h1 {
    text-align: left;
    margin: 24px 0;
  }

  .docs {
    margin-bottom: 55px;
    display: flex;
    flex-direction: column;
    gap: 36px;
  }

  .doc {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;

    .status_icon {
      content: '';
      width: 24px;
      height: 24px;

      // background-image: url('./assets/');
    }

    &.error .status_icon {
      background-image: url('./assets/error.svg');
    }

    &.ok .status_icon {
      background-image: url('./assets/ok.svg');
    }

    &.waiting .status_icon {
      background-image: url('./assets/waiting.svg');
    }

    .doc_name {
      width: 300px;
    }

    .doc_btns {
      display: flex;
      align-items: center;
      gap: 24px;
    }
  }

  .usn_doc {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 26px 32px;
    // gap: 108px;
    gap: 2%;

    margin-top: 32px;
    margin-bottom: 55px;

    width: 542px;
    // height: 90px;

    /* gray blue */

    border: 1px dashed #A2ABC9;
    border-radius: 8px;

    cursor: pointer;

    &.disabled {
      opacity: 0.5;
      cursor: default;
    }

    .usn_doc_upload {
      display: flex;
      align-items: center;
      gap: 14px;
      width: 48%;

      &::before {
        content: '';
        width: 24px;
        height: 24px;
        background-image: url('./assets/doc.svg');
        flex-shrink: 0;
      }
    }

    .usn_doc_info {
      width: 48%;
      // width: 250px;
      margin-left: 20px;
    }
  }

  .disabled .link {
    cursor: default;
  }

  .link {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;

    cursor: pointer;

    background: linear-gradient(310.28deg, #CC8AFF 0%, #8AB9FF 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }

  .btns {
    margin-top: 60px;
  }
}