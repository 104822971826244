@import "../../../../../styles/fonts.scss";
@import "../../../../../styles/colors.scss";

.law_info {

  font-family: 'Nunito Sans';
  font-style: normal;

  .contacts {
    margin-top: 55px;

    h1 {
      margin: 0 0 24px 0;
      text-align: left;
    }

    .panel {
      padding: 16px 16px 24px;
      background: #FFFFFF;
      box-shadow: 0px 0px 20px rgba(131, 103, 228, 0.1);
      border-radius: 8px;

      .controls {
        display: flex;
        gap: 16px;
      }
    }
  }

  .add_contact {
    margin-top: 40px;
  }

  .btns {
    margin-top: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
  }

  .row {
    display: flex;
    gap: 24px;
  }

  .col {
    width: 50%;

    &.reload {
      display: flex;
      align-items: center;
      gap: 14px;
    }
  }

  .nds {
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .reload_btn {
    display: flex;
    align-items: center;
    gap: 12px;

    cursor: pointer;

    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    background: linear-gradient(310.28deg, #CC8AFF 0%, #8AB9FF 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;

    &::before {
      content: '';
      width: 24px;
      height: 24px;
      background-image: url('../../assets/arrows.svg');
    }
  }

  .grid {
    display: grid;
    // grid-template-columns: 50% 50%;
    // gap: 24px;
    grid-template-columns: 49% 49%;
    gap: 2%;
  }
}

.info {
  display: flex;
  flex-direction: column;

  gap: 16px;
}

.modal_controls_wrap {
  display: flex;
  flex-direction: column;
  gap: 55px;
}

.variants {

  .variants_list {
    .item {
      display: flex;
      gap: 10px;
      text-align: left;
    }
  }
}

.uploads {
  h1 {
    margin: 0;
    text-align: left;
  }

  .uploads_grid {
    text-align: left;

    display: grid;
    grid-template-columns: 40% 40% 20%;
    // gap: 24px;
    align-items: center;

    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    border-bottom: 1px solid #F0EEF9;
    padding-top: 8px;
    padding-bottom: 8px;

    .name {
      font-size: 14px;
      line-height: 19px;
      color: #222222;
      padding-right: 16px;
    }

    .file {
      font-size: 13px;
      line-height: 18px;
      color: #67E8C1;
      padding-right: 16px;


    }

    &.has_error .file,
    &.uploading .file {
      color: #FF5980;
    }

    .controls {
      display: flex;
      gap: 10px;
      align-items: center;
    }
  }
}

// .update_warning {
//   width: 600px;
//   margin: 40px auto 0;

//   font-family: 'Nunito Sans';
//   font-style: normal;
//   font-weight: 600;
//   font-size: 14px;
//   line-height: 19px;
//   text-align: center;
//   color: #FF5980;
// }

// .modal_controls_wrap {
//   max-height: 50vh;
//   overflow-y: auto;
//   scroll-behavior: smooth;
//   scrollbar-color: rgba($color-purple, .4) rgba($color-purple, .1);
//   scrollbar-width: thin;

//   &::-webkit-scrollbar {
//     width: 8px;
//   }

//   &::-webkit-scrollbar-thumb {
//     background-color: rgba($color-purple, .4);
//     border-radius: 8px;
//   }

//   &::-webkit-scrollbar-track {
//     background-color: rgba($color-purple, .1);
//     border-radius: 8px;
//   }
// }