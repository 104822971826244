@import "src/styles/fonts";
@import "src/styles/colors";

.wrapper {
  position: fixed;
  top: 0;
  left: 0;

  text-align: center;
  color: $color-input;

  width: 100vw;
  height: 100vh;
  z-index: 100;
}

.tagsField {
  position: fixed;
  left: 50%;
  bottom: 10px;
  transform: translateX(-50%);
  width: 780px;
  height: 420px;
  padding: 40px;
  background-color: $color-white;

  box-shadow: 8px 8px 40px rgba(131, 103, 228, 0.2);
  border-radius: 8px;

  z-index: 1001;
}

.displayNone {
  display: none;
}

.fieldHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  text-transform: uppercase;
  @extend %font_placeholder;
  color: $color-black;
}

.fieldWrapper {
  width: 100%;
  margin: 20px 0;
}

.inputWrapper {
  position: relative;

  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    z-index: 1000;

    width: 15px;
    height: 15px;
    background-image: url("../../../../../../../assets/icons/search.svg");
    background-size: contain;
    background-repeat: no-repeat;
  }
}

.search {
  width: 100%;
  padding: 12px 16px;
  outline: none;

  border: 1px solid rgba(162, 171, 201, 0.5);
  border-radius: 8px;

  @extend %font_gray_paragraph;
}

.list {
  list-style: none;
  margin: 26px 0;
  padding: 0;

  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  align-content: flex-start;
  flex-wrap: wrap;

  height: 150px;
  overflow-y: auto;

  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #FFFFFF;

}

.includes {
  padding: 4px 12px;
  background: $color-blue;
  border-radius: 4px;
  color: $color-white;
  margin-bottom: 10px;

  cursor: pointer;

  // @extend %font_gray_blue_p;

  &:not(:last-child) {
    margin-right: 10px;
  }
}

.item {
  padding: 4px 12px;
  background: $color-gray;
  border-radius: 4px;
  color: $color-blue;
  margin-bottom: 10px;

  cursor: pointer;
  // @extend %font_gray_blue_p;

  &:not(:last-child) {
    margin-right: 10px;
  }
}

.btnBlock {
  display: flex;
  justify-content: center;
}

.readyBtn {
  position: relative;
  @extend %font_button;
  color: $color-white;

  padding: 10px 26px;
  margin: 0 auto;

  background-color: $color-purple;
  background-image: linear-gradient(92.93deg, $color-pink 2.99%, $color-purple 94.03%);

  border: 0;
  border-radius: 100px;

  z-index: 1;

  overflow: hidden;

  &::after {
    transition: opacity 0.2s ease-in-out;
    content: "";
    display: block;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(115.51deg, $color-blue 23.07%, $color-blue 90.77%);

    z-index: -1;
  }

  &:hover::after {
    opacity: 1;
  }

  &:disabled {
    color: rgba(71, 115, 235, 0.5);
  }

  &:disabled::after {
    background-image: linear-gradient(115.51deg, $color-gray 23.07%, $color-gray 90.77%);

    opacity: 1;
  }
}