@import "../../../styles/fonts.scss";
@import "../../../styles/colors.scss";

.button {
  @extend %font_gray_dark_paragraph;
  color: $color-lilac;
  font-size: 16px;
  text-decoration: underline;
  text-decoration-skip-ink: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  cursor: pointer;
}

.disabledButton {
  color: $color-gray-dark;
  pointer-events: none;
}
