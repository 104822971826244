.wrap {
  margin-right: 30px;
  margin-bottom: 30px;
  // max-width: calc(100vw - 310px);
}

.employees {

  background: #F8F9FC;
  border-radius: 8px;
  padding: 24px;
  min-height: 60vh;
  padding-bottom: 50px;

  .head {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .main_phone {
    width: 430px;
  }

  .list {

    margin-bottom: 60px;

    .row {
      display: grid;
      grid-template-columns: 120px 430px 100px;
      gap: 4%;
      margin: 0 40px;
      text-align: left;
      align-items: end;
      margin-top: 34px;

      .btns {
        display: flex;
        gap: 12px;
      }

      .role {
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 13px;
        line-height: 120%;
        color: #A2ABC9;
      }

      .name {
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 19px;
        color: #222222;

        margin-bottom: 8px;
      }

      .phone {
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        color: #222222;

        padding: 12px 107px 12px 16px;

        border: 1px solid rgba(162, 171, 201, 0.5);
        border-radius: 8px;

      }
    }
  }
}



.confirm {
  .head {
    margin: 0;
  }

  .sms {
    width: 360px;
    margin: auto;
  }

  .sms_btn_wrap {
    margin-top: 46px;
    margin-bottom: 46px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #222222;
  }

  .info {
    margin-top: 36px;

    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #5B637E;

    display: flex;
    flex-direction: column;
    gap: 21px;
  }

  .code_sended {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #F35A91;
  }

}

.add_employee {
  .row {
    display: grid;
    grid-template-columns: 48% 48%;
    gap: 4%;
    margin: 0 40px;
    text-align: left;
  }

  .sms_btn_wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;

    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #222222;
  }

  .info {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #5B637E;
    text-align: left;

    margin-top: 20px;
  }
}

// .code {
//   margin-top: 20px;

//   .inputs {
//     display: flex;
//     justify-content: space-between;

//     input {
//       padding: 12px 16px;
//       border: 1px solid rgba(162, 171, 201, 0.5);
//       border-radius: 8px;
//       width: 74px;

//       font-family: 'Nunito';
//       font-style: normal;
//       font-weight: 700;
//       font-size: 24px;
//       line-height: 33px;
//       color: #222222;
//       text-align: center;
//     }
//   }

//   &.error {
//     .inputs input {
//       border: 1px solid #DA79D5;
//     }

//     .error_text {
//       font-family: 'Nunito Sans';
//       font-style: normal;
//       font-weight: 600;
//       font-size: 14px;
//       line-height: 19px;
//       color: #DA79D5;
//     }
//   }
// }