@import '../../../styles/fonts.scss';
@import '../../../styles/colors.scss';

.container {
  height: 77px;
  width: 400px;
}

.title {
  @extend %font_h3;
  color: $color-gray-blue;
  margin-bottom: 4px;
}

.input {
  width: 100%;
}