@import "../../../../../../../styles/fonts";
@import "../../../../../../../styles/colors";


.controls {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  align-items: center;
  gap: 13px;

  .btns {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 238px;

    .scaleLogo {
      flex-shrink: 0;
    }
  }

  .viewControlIcons {
    display: flex;
    align-items: flex-start;
    stroke: $color-gray-blue;
    gap: 13px;

    // .icon {
    //   margin-left: 15px;
    // }

    .active {
      stroke: $color-lilac;
    }
  }

  .scaleLogo {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: none;
    padding: 0;

    span {
      font-family: "Nunito Sans";
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 19px;
      color: #9b7ffc;
      margin-left: 8px;
    }
  }
}