@import "../../../styles/fonts.scss";
@import "../../../styles/colors.scss";

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 380px;
  height: 54px;
}

.input {
  padding: 20px 16px;
  width: 80px;
  height: 54px;
  border-radius: 8px;
  text-align: center;
  border: 1px solid $color-light-border;
  outline: 0;
  

  @extend %font_code_input;
  &::placeholder {
    text-align: center;
    @extend %font_code_placeholder;
  }
}

.code_error {
  .input {
    border: 1px solid #FF5980;
  }
}
