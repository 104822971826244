.container {
  display: flex;
  justify-content: center;
  margin: 0 24px 0 0;
}

.wrapper {
  width: 100%;
  max-width: 921px;
  border-radius: 8px;

  &_side{
    width: 100%;
    max-width: 212px;
    margin-left: 23px;
  }
}

.inner {
  background: rgba(255, 255, 255, 0.8);
  border-radius: 8px;
  margin-top: 12px;
  padding: 24px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
}