.wrap {
  .btns {
    display: flex;
    justify-content: center;
    margin-top: 72px;
  }

  // .logo {
  //   height: 250px;
  //   width: 250px;
  //   display: flex;
  //   position: relative;

  //   .progress_img {
  //     position: absolute;
  //     width: 100%;
  //     height: 100%;
  //     background-size: contain;
  //     background-repeat: no-repeat;
  //   }

  //   .progress0 {
  //     background-image: url('../../assets/0.svg');

  //     &:hover {
  //       background-image: url('../../assets/0_.svg');
  //     }
  //   }

  //   .progress20 {
  //     background-image: url('../../assets/20.svg');

  //     &:hover {
  //       background-image: url('../../assets/20_.svg');
  //     }
  //   }

  //   .progress40 {
  //     background-image: url('../../assets/40.svg');

  //     &:hover {
  //       background-image: url('../../assets/40_.svg');
  //     }
  //   }

  //   .progress60 {
  //     background-image: url('../../assets/60.svg');

  //     &:hover {
  //       background-image: url('../../assets/60_.svg');
  //     }
  //   }

  //   .progress80 {
  //     background-image: url('../../assets/80.svg');

  //     &:hover {
  //       background-image: url('../../assets/80_.svg');
  //     }
  //   }

  //   .progress100 {
  //     background-image: url('../../assets/100.svg');

  //     &:hover {
  //       background-image: url('../../assets/100_.svg');
  //     }
  //   }

  //   .logo_img_wrap {
  //     width: 120px;
  //     height: 120px;
  //     position: absolute;
  //     right: 0;
  //     left: 0;
  //     top: 0;
  //     bottom: 0;
  //     margin: auto;
  //     background-color: #222222;
  //   }
  // }

  .about {
    display: flex;
    gap: 42px;


    .info {
      display: flex;
      flex-direction: column;
      gap: 16px;

      margin-top: 45px;
      margin-bottom: 30px;


      .more_btn {
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 140%;
        background: linear-gradient(310.28deg, #CC8AFF 0%, #8AB9FF 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        cursor: pointer;

        text-fill-color: transparent;
        // &:hover {
        //   text-decoration: underline;
        // }
      }

    }

    h1 {
      font-family: 'Nunito Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      text-transform: uppercase;
      color: #222222;
      margin: 63px 0 16px 0;
      text-align: left;
    }

    .col {
      width: 50%;

      .row {
        display: grid;
        gap: 0 5%;
        grid-template-columns: 30% 30% 30%;
      }
    }
  }
}

.info {
  display: flex;
  flex-direction: column;

  gap: 16px;

  // .info_panel {
  //   background: #F8F9FC;
  //   border-radius: 8px;
  //   padding: 12px;
  //   font-family: 'Nunito Sans';
  //   font-style: normal;
  //   font-size: 14px;
  //   line-height: 140%;
  //   .head {
  //     font-weight: 600;
  //     color: #F35A91;
  //     display: flex;
  //     gap: 8px;
  //     align-items: center;
  //     margin-bottom: 8px;
  //     &::before {
  //       content: '';
  //       background-image: url('../../assets/info.svg');
  //       width: 24px;
  //       height: 24px;
  //     }
  //   }
  //   .body {
  //     font-weight: 400;
  //     color: #5B637E;
  //   }
  // }
}


.lvl {
  display: flex;
  gap: 8px;

  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  color: #222222;

  .lvl_name {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 140%;
    color: #A984D5;
  }

  .lvl_hint {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #5B637E;
  }

}

.commission {
  display: flex;
  gap: 8px;
  align-items: center;

  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  color: #A2ABC9;

  .value {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 140%;
    text-align: right;
    color: #222222;
  }
}


.info_dialog {
  .content {
    display: flex;
    justify-content: space-between;

    .status_group {
      display: flex;
      flex-direction: column;
      gap: 24px;
      margin-top: 20px;
      align-items: center;
    }

    .info {
      width: 560px;
      font-family: 'Nunito Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      color: #222222;
      text-align: left;

    }
  }
}

// .logo {
//   position: relative;

//   .replace_btn_wrap,
//   .logo_wrap {
//     position: absolute;
//     left: 0;
//     right: 0;
//     top: 0;
//     bottom: 0;

//     display: flex;
//     justify-content: center;
//     align-items: center;

//     .gag {
//       cursor: pointer;
//       display: flex;
//       flex-direction: column;
//       align-items: center;
//       gap: 12px;

//       img {
//         width: 30px;
//         height: 30px;
//       }
//     }

//     .gag_text,
//     .uploading_info {
//       font-family: 'Nunito Sans';
//       font-style: normal;
//       font-weight: 700;
//       font-size: 14px;
//       line-height: 19px;
//       text-align: center;
//       background: linear-gradient(310.28deg, #CC8AFF 0%, #8AB9FF 100%);
//       -webkit-background-clip: text;
//       -webkit-text-fill-color: transparent;
//       background-clip: text;
//       text-fill-color: transparent;
//     }

//     img {
//       max-width: 150px;
//       max-height: 150px;
//     }
//   }

//   .replace_btn {
//     cursor: pointer;
//     opacity: 0;
//     background-color: #F0EEF9;
//     transition: 0.2s;
//     border-radius: 1000px;
//     width: 65%;
//     height: 65%;
//     display: flex;
//     justify-content: center;
//     align-items: center;

//     .replace_btn_text {
//       font-family: 'Nunito Sans';
//       font-style: normal;
//       font-weight: 700;
//       font-size: 14px;
//       line-height: 19px;
//       text-align: center;

//       background: linear-gradient(310.28deg, #CC8AFF 0%, #8AB9FF 100%);
//       -webkit-background-clip: text;
//       -webkit-text-fill-color: transparent;
//       background-clip: text;
//       text-fill-color: transparent;
//     }
//   }

//   &:hover .replace_btn {
//     opacity: 1;
//   }
// }