.radio_group {


  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;

  color: #222222;

  .head {
    display: flex;
    gap: 8px;
    margin-bottom: 8px;
  }

  .label {
    color: #5B637E;
    white-space: nowrap;
  }

  .btns {

    display: flex;
    gap: 24px;

    .btn {
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 10px;
      white-space: nowrap;

      &::before {
        content: '';
        width: 14px;
        height: 14px;
        background-repeat: no-repeat;
        background-image: url('./assets/radio.svg');
      }

      &.active::before {
        background-image: url('./assets/radio_active.svg');
      }
    }
  }

  &.column {
    .btns {
      display: flex;
      flex-direction: column;
    }
  }
}