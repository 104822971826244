.button {
  width: 165px;
}

.errorText {
  position: absolute;
  margin-top: 192px !important;
}

.info {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #5B637E;

  a {
    text-decoration: underline;
    cursor: pointer;
  }
}

@media screen and (max-height: 645px) {
  .container {
    padding-bottom: 50px;

    input {
      width: 270px !important;
      height: 45px !important;
      font-size: 12px !important;

      &::placeholder {
        font-size: 12px !important;
      }
    }
  }

  .button {
    width: 120px !important;
    height: 40px !important;
    padding: 8px 16px !important;
    font-size: 10px !important;
  }


  .errorText {
    // margin-top: 115px !important;
    font-size: 12px;
    position: relative;
    margin-top: 0 !important;
  }
}