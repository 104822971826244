.wrap {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #222222;
}

.disabled {
  opacity: 0.5;
}

.row {
  display: flex;
  gap: 24px;
  align-items: center;
  margin-bottom: 32px;
}

.controls1 {
  display: flex;
  justify-content: space-between;
}

.controls2 {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 40px;
}

.btns {
  display: flex;
  gap: 24px;
}

.count_info {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  text-transform: uppercase;
  color: #222222;

  display: inline-block;
  margin-bottom: 24px;
}

.count_info .link {
  color: #9B7FFC;
  text-decoration: underline;
  cursor: pointer;
}

.rado_btns {
  width: 160px;
  gap: 12px;
  flex-direction: column;
}

.text1 {
  width: 145px;
}

.discount_value {
  width: 180px;
}