.block {
  width: 60px;
  height: 30px;
}

.list {
  list-style: none;
  margin: 0;
  padding: 0;

  display: flex;
  flex-wrap: wrap;
  align-items: center;

  width: 580px;

  li {
    padding: 3px 10px;
    background: #4773eb;
    border-radius: 4px;

    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    color: #ffffff;

    margin-bottom: 5px;

    &:not(:last-child) {
      margin-right: 5px;
    }
  }
}

.btn {
  background: none;
  border: 0;
  padding: 0;
  white-space: nowrap;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: #9b7ffc;
  cursor: pointer;
}
