.schedule {
  background: #F8F9FC;
  border-radius: 8px;
  margin-right: 30px;
  margin-bottom: 30px;
  padding: 24px;

  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;

  h1 {
    text-align: left;
    margin: 0;
  }

  article {
    margin-bottom: 56px;
  }

  .info,
  .text,
  .warning,
  .big_warning {
    margin-top: 16px;
    margin-bottom: 24px;
  }

  .warning {
    display: flex;
    gap: 5px;


    &::before {
      content: '!';
      color: #FF5980;
    }
  }

  .big_warning {
    background: #FF5980;
    box-shadow: 0px 0px 20px rgba(131, 103, 228, 0.1);
    border-radius: 8px;
    display: inline-block;

    padding: 12px;
    // margin-top: 16px;
    // margin-bottom: 24px;

    border: 1px solid #FF5980;
    
    // color: #FFFFFF;
    // background: #FF5980;
    // box-shadow: 0px 0px 20px rgba(131, 103, 228, 0.1);

    // &.active {
      color: #FF5980;
      background: rgba(254, 87, 171, 0.1);
      box-shadow: 0px 0px 20px rgba(254, 87, 171, 0.05);
    // }
  }

  .info {
    color: #5B637E;
  }

  .time_input {
    max-width: 190px;
  }

  .day_selector {
    display: flex;
    gap: 8px;
    width: 542px;
    color: #FFFFFF;

    .day {
      border-radius: 4px;
      padding: 16px;
      flex-grow: 1;
      cursor: pointer;

      display: flex;
      align-items: center;
      justify-content: center;

      background: #F0EEF9;
      color: #222222;

      &.selected {
        background: linear-gradient(310.28deg, #CC8AFF 0%, #8AB9FF 100%);
        color: #FFFFFF;
      }
    }
  }

  .btns {
    display: flex;
    gap: 24px;
  }
}