@import "src/styles/fonts";
@import "src/styles/animations";

.modalContainer {
  position: fixed;
  top: 0;
  left: 0;

  font-family: 'Nunito Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;

  text-align: center;
  color: #222222;

  width: 100vw;
  height: 100vh;
  z-index: 100;
}

.modalUnderflow {
  position: relative;
  display: block;

  width: 100vw;
  height: 100vh;

  background-color: rgba(213, 213, 213, 0.9);
  backdrop-filter: blur(5px);

  z-index: 101;
}

.content {
  animation: slider-open 0.5s forwards;
  position: fixed;
  top: 10%;
  left: 50%;
  transform: translateX(-50%) scale(0);
  width: 530px;

  padding: 40px;

  background-color: #FFFFFF;
  box-shadow: 8px 8px 40px rgba(131, 103, 228, 0.2);
  border-radius: 8px;

  z-index: 200;
}

.text {
  display: flex;
  justify-content: center;
  align-items: center;

  max-height: 320px;

  margin-bottom: 40px;
}

.title {
  font: inherit;
}
