@import "../../../../../../../../../styles/fonts.scss";

.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0px;
  gap: 40px;

  margin-right: 202px;
}

.footer_txt {
  @extend %font_products_items;
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  text-align: right;
  color: #A2ABC9;

  white-space: nowrap;

  display: flex;
  gap: 8px;
  align-items: center;

  .select {
    width: 80px;
  }

  // flex-direction: ;
  // select {
  //   color: #A2ABC9;
  //   border: 0px;
  //   margin-left: 12px;
  //   outline: none;
  //   cursor: pointer;
  // }
}

.buttons {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;

  .page_btns {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;

    max-width: 660px;
    overflow: auto;
    user-select: none;


    scroll-behavior: smooth;
    scrollbar-color: rgba($color-purple, .4) rgba($color-purple, .1);
    scrollbar-width: thin;

    .dots {
      width: 32px;
      text-align: center;
    }

    &::-webkit-scrollbar {
      height: 8px;
      width: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba($color-purple, .4);
      border-radius: 8px;
    }

    &::-webkit-scrollbar-track {
      background-color: rgba($color-purple, .1);
      border-radius: 8px;
    }
  }
}

.footer_btn {
  width: 32px;
  height: 32px;
  cursor: pointer;
}

.footer_btn_page {
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 50%;
  background: $color-product-list-total-pages;

  flex-shrink: 0;

  &.selected {
    border: 1px solid;
  }
}

.footer_btn_forward__arrow,
.footer_btn_backward__arrow {
  cursor: pointer;
}

.footer_btn_backward__arrow {
  transform: rotateY(180deg);
}