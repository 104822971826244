@import "../../../../../styles/fonts";
@import "../../../../../styles/colors";

.inner {
  flex-grow: 1;

  .list {
    height: 600px;

    overflow-x: hidden;
    overflow-y: scroll;
    scroll-behavior: smooth;
    scrollbar-color: rgba($color-purple, .4) rgba($color-purple, .1);
    scrollbar-width: thin;
  }
}