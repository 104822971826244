.tagsListWrapper {
    
}

.tagsList {
    
}

.tag {
    padding: 14px 0 0 0;
    list-style-type: none;
    color: #4773EB;
    border-bottom: 1px solid rgb(228, 224, 224);
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-style: italic;
    font-weight: 600;

    span {
        color: #000;
        margin-right: 50px;
    }
}