.variants {
  // background-color: #fff;
  margin-bottom: 10px;
  padding: 10px;

  display: flex;
  gap: 30px;
  align-items: center;

  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;

  .title {
    margin-bottom: 0;
  }
}

.variant {
  display: flex;
  gap: 8px;
  white-space: nowrap;
  padding: 3px;
  border-bottom: 2px solid #ffffff00;

  &.selected {
    border-bottom: 2px solid #9B7FFC;
  }

  cursor: pointer;
  align-items: center;
}

.add_color {
  opacity: 0.5;
  cursor: pointer;
}