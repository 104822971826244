.wrap {
  font-family: 'Nunito Sans';
  font-style: normal;

  width: 424px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .btns {
    margin-top: 50px;
    display: flex;
    gap: 24px;
  }

  .update {
    display: flex;
    align-items: center;
    gap: 12px;
    white-space: nowrap;

    cursor: pointer;

    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    background: linear-gradient(310.28deg, #CC8AFF 0%, #8AB9FF 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;

    &::before {
      content: '';
      width: 24px;
      height: 24px;
      background-image: url('../../assets/arrows.svg');
    }
  }
}