.additional_color {
  display: flex;
}

.controls {
  margin-left: 12px;
  width: 100px;
  display: flex;
  gap: 12px;
  align-items: center;

  .btn {
    width: 24px;
    height: 24px;
    cursor: pointer;
    margin-bottom: 5px;
  }
}

.additional_colors {
  display: flex;
  flex-direction: column;
  gap: 5px;

  margin-top: 32px;
  min-height: 190px;

  .list {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .btn_add,
  .btn_remove {
    width: 32px;
    height: 32px;
    cursor: pointer;
  }
}