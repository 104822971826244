.dp {

  color: #222;
  font-family: Nunito Sans;
  font-size: 10px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;

  .head,
  .body {
    padding: 24px;
  }

  .head {
    border-bottom: 1px solid #E1E2F8;
  }

  .title {
    font-size: 14px;
    font-weight: 700;
  }

  .coordinates {
    color: #9B7FFC;
    font-family: Nunito Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-decoration-line: underline;
    cursor: pointer;
  }
}