.footer {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;

  .btns_group {
    display: flex;
    gap: 16px;
  }
}

.select_product_btn {
  margin-bottom: 24px;
}

.count_info {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  text-transform: uppercase;
  color: #222222;

  display: inline-block;
  margin-bottom: 24px;
}

.count_info .link {
  color: #9B7FFC;
  text-decoration: underline;
  cursor: pointer;
}

.controls1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.banner21_info {
  display: flex;
}

.banner21_hint {
  width: 460px;
  margin-right: 50px;
}

.banner21 {
  display: flex;
  gap: 16px;

  img {
    width: 86px;
    height: 67px;
  }

  span {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 140%;
    color: #222222;

    width: 210px;
    height: 40px;
  }
}

.d21TableTitle {
  display: flex;
  align-items: center;
  gap: 10px;

  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  text-transform: uppercase;
  color: #222222;

  margin-top: 35px;
  margin-bottom: 10px;

  img {
    cursor: pointer;
  }
}

.d21controls {
  display: flex;
  justify-content: space-between;
  margin-top: 60px;
}

.hint21::before {
  width: 400px;
}