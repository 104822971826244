.pack_value {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .label {
    color: var(--gray-blue, #A2ABC9);
  }

  .size_input {
    width: 105px;
  }
}