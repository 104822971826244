@import "../../../styles/backgrounds.scss";

.container {
  display: flex;
  flex-direction: column;
  gap: 3vh;
  justify-content: center;
  align-items: center;
  height: 100vh;
  @extend %bg_auth;
}

.logo {
  // position: fixed;
  // top: 43.4px;
}

.card {
  display: flex;
  position: relative;
  width: 1156px;
  height: 763px;
  // top: 80px;
  backdrop-filter: blur(8px);
  border-radius: 8px;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)), #ebecff;
}

@media screen and (max-height: 900px) {
  .card {
    // top: 50px;
    width: 900px;
    height: 600px;
    border-radius: 16px;
  }
}

@media screen and (max-height: 770px) {
  .logo {
    // top: 30px;
    // width: 100px;
  }
  .card {
    // top: 50px;
    width: 600px;
    height: 430px;
    border-radius: 16px;
  }
}
