.content {
  display: flex;
  flex-direction: column;
  align-items: center;

  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 600;
  line-height: 1;

  h1 {
    margin-bottom: 8px;
  }

  .info {
    color: #5B637E;
    text-align: center;
    font-size: 14px;
  }

  .old_phone {
    margin-top: 24px;
    color: #8289A1;
    font-size: 32px;
  }

  .lost_phone_cb {
    margin-top: 12px;
    margin-bottom: 12px;
  }

  .lost_phone_info {
    height: 40px;
    color: #5B637E;
    text-align: center;
    font-size: 14px;
    margin-bottom: 48px;
    line-height: 1.3;
  }

  .warning {
    color: #FF5980;
    text-align: center;
    font-size: 14px;
    margin-bottom: 18px;
  }

  &.lost_phone {
    .old_phone {
      color: rgba(130, 137, 161, 0.20);
    }
  }

  .code_input {
    width: 360px;
    margin-top: 48px;
    margin-bottom: 12px;
  }

  .send_sms_timer {
    color: rgba(91, 99, 126, 0.20);
    text-align: center;
    font-family: Nunito Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    height: 52px;
  }

  .resend_sms_btn {
    color: var(--unnamed, #9B7FFC);
    text-align: center;
    font-family: Nunito Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-decoration-line: underline;
    height: 57px;
    cursor: pointer;
  }

  .wrong_code {
    height: 20px;
  }

  .new_phone,
  .new_email {
    width: 380px;
    margin-top: 67px;
    margin-bottom: 137px;
  }

  .success_text {
    color: var(--green-text, #39C286);
    text-align: center;

    font-family: Nunito Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;

    margin-top: 24px;
    margin-bottom: 67px;
  }

  .email_sended_info {
    color: #5B637E;
    text-align: center;

    /* text */
    font-family: Nunito Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    line-height: 1;
    
    margin-top: 51px;
    margin-bottom: 117px;

    .email {
      color: #9B7FFC;
      font-family: Nunito Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }

}