
.logo {
  height: 250px;
  width: 250px;
  display: flex;
  position: relative;

  .replace_btn_wrap,
  .logo_wrap {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    .gag {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 12px;

      img {
        width: 30px;
        height: 30px;
      }
    }

    .gag_text,
    .uploading_info {
      font-family: 'Nunito Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 19px;
      text-align: center;
      background: linear-gradient(310.28deg, #CC8AFF 0%, #8AB9FF 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }

    img {
      max-width: 150px;
      max-height: 150px;
    }
  }

  .replace_btn {
    cursor: pointer;
    opacity: 0;
    background-color: #F0EEF9;
    transition: 0.2s;
    border-radius: 1000px;
    width: 65%;
    height: 65%;
    display: flex;
    justify-content: center;
    align-items: center;

    .replace_btn_text {
      font-family: 'Nunito Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 19px;
      text-align: center;

      background: linear-gradient(310.28deg, #CC8AFF 0%, #8AB9FF 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
  }

  &:hover .replace_btn {
    opacity: 1;
  }


  .progress_img {
    position: absolute;
    width: 100%;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .progress0 {
    background-image: url('./assets/0.svg');

    &:hover {
      background-image: url('./assets/0_.svg');
    }
  }

  .progress20 {
    background-image: url('./assets/20.svg');

    &:hover {
      background-image: url('./assets/20_.svg');
    }
  }

  .progress40 {
    background-image: url('./assets/40.svg');

    &:hover {
      background-image: url('./assets/40_.svg');
    }
  }

  .progress60 {
    background-image: url('./assets/60.svg');

    &:hover {
      background-image: url('./assets/60_.svg');
    }
  }

  .progress80 {
    background-image: url('./assets/80.svg');

    &:hover {
      background-image: url('./assets/80_.svg');
    }
  }

  .progress100 {
    background-image: url('./assets/100.svg');

    &:hover {
      background-image: url('./assets/100_.svg');
    }
  }

  .logo_img_wrap {
    width: 120px;
    height: 120px;
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    background-color: #222222;
  }
}
