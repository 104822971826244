.limits {
  display: flex;

  .limit {
    border-right: 1px solid rgba(162, 171, 201, 0.50);
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 185px;
    padding: 16px;


    color: var(--black, #222);
    font-family: Nunito Sans;
    font-size: 12px;
    font-style: normal;
    line-height: 140%;

    .date {
      font-weight: 700;
      /* 16.8px */
    }

    .values {
      display: flex;
      flex-direction: column;
      // justify-content: center;
      // align-items: center;
      gap: 4px;
      flex-shrink: 0;

      .value {
        display: flex;
        gap: 4px;

        .title {
          font-weight: 400;
          text-transform: uppercase;
        }

        .value {
          color: var(--gray-dark-text, #5B637E);
        }
      }
    }
  }
}