@import "../../../../../styles/colors";
@import "../../../../../styles/fonts";

.card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-items: stretch;
  padding: 24px;
  gap: 12px;

  background: #FFFFFF;
  border-radius: 8px;

  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #222222;

  position: relative;
  min-height: 100%;

  transition: all .1s ease-in-out;

  &.selected {
    box-shadow: 8px 8px 40px rgba(131, 103, 228, 0.2);
    max-height: 387px;
    z-index: 1;
    // height: auto;

    .content .text {
      max-height: 209px;
      overflow-y: auto;
    }
  }

  .head {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  .body {
    display: flex;
    flex-direction: row;
    // align-items: flex-start;
    align-items: stretch;
    padding: 16px;
    gap: 16px;
    flex-grow: 1;

    border: 1px solid rgba(162, 171, 201, 0.5);
    border-radius: 8px;

    .item {
      flex-shrink: 0;
      width: 40px;
      height: 40px;

      border-radius: 2px;

      background-size: contain;
      background-repeat: no-repeat;

      cursor: pointer;
    }
  }

  .user {
    display: flex;
    gap: 5px;
    align-items: center;
    font-weight: 600;

  }

  .stars {
    display: flex;
    align-items: center;
    gap: 5px;
    font-weight: 700;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .text {
      max-height: 76px;
      overflow: hidden;
      white-space: pre-wrap;

      transition: all .1s ease-in-out;

      scrollbar-color: rgba($color-purple, .4) rgba($color-purple, .1);
      scrollbar-width: thin;
      scroll-behavior: smooth;

      &::-webkit-scrollbar {
        width: 6px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: rgba($color-purple, .4);
        border-radius: 8px;
      }

      &::-webkit-scrollbar-track {
        background-color: rgba($color-purple, .1);
        border-radius: 8px;
      }
    }

    h3 {
      font-family: 'Nunito Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 19px;
      text-transform: uppercase;
      color: #222222;

      margin-top: 0;
      margin-bottom: 8px;

      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .date {
    margin-top: 8px;
    color: #A2ABC9;
  }
}