.gear_sheet {
  .btns {
    display: flex;
    gap: 12px;
    margin-bottom: 42px;
  }

  .list {
    td {
      color: var(--gray-text, #8289A1);
      font-family: Nunito Sans;
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      align-items: start;
    }

    th {
      align-items: center !important;
    }

    .delivery_cost,
    .cost {
      justify-content: center;
      text-align: center;
    }

    .address {
      text-align: left;
    }

    td.docs,
    .id {
      text-align: left;

      .number {
        color: var(--black, #222);
      }
    }

    td.docs {
      color: var(--gray-blue, #A2ABC9);
      
      .error {
        color: var(--pink, #FF5980);
        font-family: Nunito Sans;
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
      }
      .loader {
        display: flex;
        gap: 6px;
        justify-content: center;
        align-items: center;
        color: #8289A1;
      }

      &.docs_collected {
        display: flex;
        flex-direction: column;
        gap: 6px;
        text-align: left;


        a {
          color: #9B7FFC;
          font-family: Nunito Sans;
          font-size: 13px;
          font-style: normal;
          font-weight: 600;
          text-decoration-line: underline;
        }
      }
    }

    .products {
      text-align: left;

      .product {
        .name {
          color: #8289A1;
          text-decoration: underline;
        }

        .code {
          color: var(--gray-blue, #A2ABC9);
        }
      }
    }
  }

  .footer {
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    justify-content: center;
    align-items: center;

    .info {
      width: 344px;
      color: var(--gray-blue, #A2ABC9);
      text-align: center;

      /* text */
      font-family: Nunito Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
    }
  }
}