@import "../../../../../styles/fonts";
@import "../../../../../styles/colors";

.inner{
  background: rgba(255, 255, 255, 0.8);
  border-radius: 8px;
  margin-top: 12px;
  .title{
    padding: 12px 2px 12px;
    display: inline-grid;
    align-items: flex-start;
    grid-template-columns: auto auto;
    align-items: flex-start;
    @extend %font_pink_error;
    .icon {
      position: relative;
      width: 20px;
      height: 20px;
      margin-left: 10px;
      background-image: url("./../../assets/info.svg");
      background-size: contain;
      background-repeat: no-repeat;
    }
    p{
      margin: 0 12px;
    }
  }
  .description{
    padding: 12px;
    margin-top: -12px;
    font-weight: 400;
    @extend %font_gray_paragraph;
    a{
      @extend %font_purple_paragraph
    }
  }
}