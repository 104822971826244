.wrap {
  margin-right: 30px;
  margin-bottom: 30px;

  background: rgba(255, 255, 255, 0.8);
  border-radius: 8px;

  padding: 24px;

  max-width: calc(100vw - 310px);
  min-width: 1156px;
  user-select: none;
}

.action {
  display: flex;
  flex-direction: column;


  .action_title {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 16px;
    gap: 10px;

    height: 54px;
    background: #9B7FFC;
    border-radius: 8px 8px 0px 0px;

    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #FFFFFF;
  }

  .action_body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    gap: 10px;

    border-radius: 0px 0px 8px 8px;

    border: 1px solid #9B7FFC;
    border-radius: 0 0 8px 8px;

    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #222222;
  }

  .period {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  .description {
    h3 {
      margin-top: 12px;
      margin-bottom: 4px;

      font-family: 'Nunito Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: #222222;
    }

    span {
      font-family: 'Nunito Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: #A2ABC9;
    }
  }
}

.controls {
  margin-top: 32px;
  display: flex;
  flex-direction: row;
  gap: 40px;

  .count {
    max-width: 450px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}

.banner {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;

  .hint {
    width: 200px;
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 140%;
    color: #222222;
  }
}

.banner_preview {
  position: relative;
  width: 86px;
  height: 67px;
}

.buttons {
  margin-top: 56px;
}

.items_count_info {
  margin-top: 24px;

  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  text-transform: uppercase;
  color: #222222;

  .link {
    color: #9B7FFC;
    text-decoration: underline;
    cursor: pointer;
  }
}

.list_wrap {
  margin-top: 24px;
}

.footer {
  margin-top: 50px;

  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.hint21::before {
  width: 380px;
}

.d21TableTitle {
  display: flex;
  align-items: center;
  gap: 10px;

  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  text-transform: uppercase;
  color: #222222;

  margin-top: 35px;
  margin-bottom: 10px;

  img {
    cursor: pointer;
  }
}

.banner21_info {
  margin-top: 42px;
  margin-bottom: 60px;
}