.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
  gap: 24px;
}

.sorting_select {
  width: 300px !important;
}

.justify_content_flex_start {
  justify-content: flex-start;
  gap: 24px;
}