@import "../../../styles/fonts.scss";
@import "../../../styles/colors.scss";

.header {
  @extend %font_h2;
  color: $color-gray-blue;
  font-size: 14px;
  line-height: 19px;
  margin-bottom: 4px;
  text-align: left;
}

.body {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 54px;
  margin: 0;
  border: 1px solid $color-light-border;
  border-radius: 8px;
  padding: 12px 16px;
  text-align: left;
  @extend %font_input;
}

.disabledTest  {
  color: $color-placeholder-input;
}