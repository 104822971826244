@import "src/styles/fonts";

.tags {
  position: relative;

  .title {
    color: var(--black, #222);
    font-family: Nunito Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}