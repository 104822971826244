@import "../../../../../styles/fonts";
@import "../../../../../styles/colors";

.upload_form {
  width: 368px;
  box-sizing: border-box;

  input {
    width: 100%;
    align-items: flex-start;
    padding: 12px 16px;
    border: 1px solid rgba(162, 171, 201, 0.5);
    border-radius: 8px;
    background: none;
    text-align: left;
    @extend %font_gray_dark_paragraph;
  }
}
.disabled {
  cursor: default;
  pointer-events: none;
}
.selectLogo {
  box-sizing: border-box;
  margin: 24px 0;
  width: 368px;
  height: 368px;
  border: 1px solid rgba(162, 171, 201, 0.5);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  img {
    width: 368px;
    height: 368px;
    border-radius: 8px;
  }
}
.remove {
  position: absolute;
  top: 12px;
  right: 12px;
  button {
    background: none;
    border: none;
  }
  img {
    width: 28px;
    height: 28px;
  }
}

.upload {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 15px 0;
  fill: $color-purple;
  @extend %font_purple_paragraph;

  label{
    width: 100%;
  }

  .icon{
    margin-right: 10px;
  }
}

.inactive{
  fill: $color-gray-blue;
  color: $color-gray-blue;
}

.loadImage {
  display: flex;
  align-items: center;
  text-align: center;
  margin-top: 40px;
  justify-content: center;
  color: rgba(235, 236, 255, 1);
  span {
    margin-left: 5px;
  }
  &:hover {
    color: rgba(155, 127, 252, 1);
  }
}

.logoImageNote {
  width: 340px;
  height: 19px;
  margin: 16px 0 40px 0;
  font-weight: 700;
  @extend %font_pink_error;
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 24px 0;
}
.submit {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 24px;
  background: $purpleGradient;
  border: none;
  border-radius: 50px;
  @extend %font_white_paragraph;

  &:disabled {
    background: rgba($color-lilac, .1);
    color: rgba($color-lilac, .5);
  }
}
.cancel {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  margin-left: 12px;
  border-radius: 50px;
  border: none;
  background: transparent;
  color: $color-gray-blue;
  @extend %font_gray_paragraph;
}

.errorText {
  text-align: center;
  @extend %font_pink_error;
}
