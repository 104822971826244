.remainings {
  font-family: Nunito Sans;
  font-style: normal;
  display: inline-table;
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;

  tr.error {
    background-color: #ffdfe7;
  }
  th {
    color: var(--gray-text, #A2ABC9);
    font-size: 12px;
    font-weight: 700;
    text-align: left;
    padding-bottom: 12px;
  }


  tbody tr:first-child td{
    border-top: 1px solid #EBECFF;
  }
  td {
    color: var(--black, #222);
    font-size: 13px;
    font-weight: 600;
    
    border-bottom: 1px solid #EBECFF;
    padding-top: 25px;
    padding-bottom: 25px;
    padding-right: 25px;
  }

  .item {
    .name {
      color: var(--gray-dark-text, #5B637E);
      font-family: Nunito Sans;
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      text-decoration-line: underline;
    }

    .code {
      color: var(--gray-blue, #A2ABC9);
      font-family: Nunito Sans;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
    }
  }

  td.warehouse {
    border-left: 1px solid #EBECFF;
    padding-left: 27px;
  }

  .shipment {
    display: flex;
    justify-content: center;
    width: 250px;

    .input {
      width: 80px;
      text-align: center;
    }
  }

  th.warehouse {
    padding-left: 27px;
  }

  // td+td {
  //   width: auto;
  // }


  .shipment,
  .balance,
  .count {
    text-align: center;
  }
}