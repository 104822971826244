@import "src/styles/fonts";
@import "src/styles/animations";


.button {
  @extend %font_button;

  .icon {
    width: 16px;
    height: 16px;
  }

  &.big {
    padding: 16px 50px;
  }

  &.small {
    padding: 12px 16px;
  }

  padding: 12px 24px;
}

.button.default {
  display: flex;
  gap: 8px;
  align-items: center;

  position: relative;
  color: $color-white;


  // background-color: $color-purple;
  // background-image: linear-gradient(92.93deg, #FF8EF6 2.99%, #9265FB 94.03%);
  background: var(--lilac, linear-gradient(315deg, #CC8AFF 0%, #8AB9FF 100%));

  border: 0;
  border-radius: 100px;

  z-index: 1;

  overflow: hidden;

  // &.ok,
  // &.yes {}

  &.cancel,
  &.no {
    background: #A2ABC9;
  }

  // &.close {}

  &::after {
    transition: opacity 0.2s ease-in-out;
    content: "";
    display: block;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // background-image: linear-gradient(115.51deg, #4773EB 23.07%, #4773EB 90.77%);
    background-color: #9B7FFC;

    z-index: -1;
  }

  &:hover,
  &.active {
    &::after {
      opacity: 1;
    }
  }

  &:disabled {
    // color: rgba(71, 115, 235, 0.5);
    // background-image: linear-gradient(115.51deg, #EBECFF 23.07%, #EBECFF 90.77%);
    color: #9B7FFC80;
    background: #F0EEF9;
  }

  &:disabled::after {
    // background-image: linear-gradient(115.51deg, #EBECFF 23.07%, #EBECFF 90.77%);
    background: #F0EEF9;
    opacity: 1;
  }
}

.button.style1 {
  position: relative;
  overflow: visible;
  z-index: 1;
  border-radius: 1000px;
  color: #8AB9FF;
  border: none;

  &::after {
    content: '';
    position: absolute;
    top: 1px;
    bottom: 1px;
    left: 1px;
    right: 1px;
    background: #fff;
    border-radius: 1000px;
    z-index: -1;
    transition: all 0.2s ease-in-out;
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background: linear-gradient(310.28deg, #CC8AFF 0%, #8AB9FF 100%);
    border-radius: 1000px;
    z-index: -1;
  }

  &:hover,
  &.active {
    &::after {
      background: #9B7FFC;
    }

    &::before {
      background: #9B7FFC;
    }

    color: #FFFFFF;
  }

  // &::after {
  //   transition: opacity 0.2s ease-in-out;
  //   content: "";
  //   display: block;
  //   opacity: 0;
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 100%;
  //   background-image: #9B7FFC;

  //   z-index: -1;
  // }

  &:hover,
  &.active {
    &::after {
      // opacity: 1;
    }
  }
}


.button.style2 {
  height: 51px;
  padding: 16px;
  cursor: pointer;

  background: #EFEAF4;
  border: 0;
  border-radius: 8px;

  transition: background-color 0.5s ease;
  position: relative;
  z-index: 1;

  overflow: hidden;


  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #222222;


  &::after {
    transition: opacity 0.2s ease-in-out;
    content: "";
    display: block;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(310.28deg, #CC8AFF 0%, #8AB9FF 100%);

    z-index: -1;
  }

  &:hover,
  &.active {
    color: #FFFFFF;

    &::after {
      opacity: 1;
    }
  }

  .link {
    color: #A984D5;
  }
}