.content {
  margin-top: 24px;
  min-height: 1000px;

  border-radius: 8px;
  background: rgba(255, 255, 255, 0.80);

  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 30px;

  .period {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #222222;

    .period_selector {
      display: flex;
      gap: 24px;
      align-items: center;
      margin-top: 8px;

      .calendar_period_selector {
        flex-grow: 0;
      }
    }
  }

  .controls {
    display: flex;
    align-items: center;
    gap: 24px;
    margin-top: 32px;

    .fbo_select {
      width: 300px;
    }
  }

  .radio_btns,
  .chart_info {
    display: flex;
    justify-content: flex-end;
  }

  .chart_info {

    display: flex;
    gap: 11px;

    color: var(--black, #222);
    font-family: Nunito Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;

    /* 19.6px */
    .clients_count {
      color: var(--unnamed, #9B7FFC);
      font-family: Nunito Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
      /* 19.6px */
    }

  }

  .tooltip {
    display: flex;
    flex-direction: column;
    .value {
      color: #222;
      font-family: Nunito Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    .date {
      color: #222;
      font-family: Nunito Sans;
      font-size: 10px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
    }
  }
  .chart_wrap {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
}