@import "../../../styles/fonts";


.wrap {
  display: flex;
  flex-direction: column;

  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #222222;

  .label {
    margin-bottom: 6px;

    .label_text {
      font-family: 'Nunito Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 13px;
      line-height: 120%;
      color: #A2ABC9;
      align-self: end;

      display: flex;
      gap: 5px;

      user-select: none;
    }
  }

  &.has_hint .label {
    display: flex;
    gap: 10px;
    align-items: center;
  }

  .input {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 15px;


    border: 1px solid rgba(162, 171, 201, 0.5);
    border-radius: 8px;

    outline: none;

    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #222222;

    cursor: text;

    &.readonly_value {
      border-top: none;
      border-left: none;
      border-right: none;
      border-radius: 0px;

      min-height: 40px;

      padding: 10px;
      padding-left: 0;
    }
  }

  .error {
    height: 20px;

    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #FF5980;
    text-align: left;
  }

  &.required:not(.readonly) {

    .label_text {
      // @extend %font_input;

      &::after {
        content: "*";
        top: 0;
        right: -10px;
        height: 0;
        color: red;
        z-index: 10;
      }
    }
  }

  &.disabled .input_value {
    opacity: 0.5;
  }


  &.has_error {
    .input {
      border-color: #FF5980;
    }
  }

  &.is_empty {

    .input,
    .input::placeholder {
      color: rgba(91, 99, 126, 0.5) !important;
      // font-style: italic;
    }
  }

  // &.hide_error {
  //   min-height: 60px;
  // }

}