@import "../../../../../../../../../styles/fonts.scss";

.wrapper {
  td {

    @extend %font_products_items;

    grid-column: 1/end;
    margin: 20vh auto;
    
    border-bottom: none !important;
    &::after, &::before {
      content: "";
      background: none !important;
    }
  }
}