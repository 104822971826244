@import "../../../styles/fonts";


.title {
  margin-bottom: 16px;

  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  text-transform: uppercase;
  color: #222222;
}

.wrap {
  max-width: calc(100vw - 310px);
  min-width: 1130px;

  // overflow-x: auto;
  // user-select: none;

  scroll-behavior: smooth;
  scrollbar-color: rgba($color-purple, .4) rgba($color-purple, .1);
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    height: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba($color-purple, .4);
    border-radius: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: rgba($color-purple, .1);
    border-radius: 8px;
  }

  background: rgba(255, 255, 255, 0.8);
  border-radius: 8px;
  padding: 24px;
}

.grid {
  display: grid;
  gap: 50px 2%;
  grid-template-columns: 49% 49%;
}

.category,
.variant_selector,
.prices {
  grid-column: 1 / 3;
}

.seasons_collection {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.footer {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 60px;
  margin-bottom: 40px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.colors_error {
  grid-column: 1 / 3;
  margin: 16px auto;
  text-align: center;
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #FF5980;
}

// =========================


.wrap.card {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)), #EBECFF;

  display: flex;
  padding: 0;
  margin-bottom: 40px;

  .head {

    .edit_btn {
      display: flex;
      gap: 8px;

      font-family: 'Nunito Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 19px;
      text-align: center;
      color: #9B7FFC;

      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }

      &::before {
        content: '';
        display: block;
        background-image: url('./assets/edit.svg');
        width: 16px;
        height: 16px;
      }
    }

    h1 {
      font-family: 'Nunito Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 33px;
      text-transform: uppercase;
      color: #222222;

      text-align: left;
    }
  }

  .column {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    border-radius: 8px;
    gap: 35px;

    padding: 34px 24px;
    background: rgba(255, 255, 255, 0.8);
  }

  .photos_wrap {
    padding: 40px;
    min-width: 470px;
  }

  .footer {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-top: 60px;
    margin-bottom: 40px;
    justify-content: flex-start;
    gap: 24px;
  }

  .category {
    display: flex;
    flex-direction: column;
  }


  .price {
    display: flex;
    align-items: center;
    gap: 16px;

    margin-top: 25px;
    // margin-bottom: 35px;


    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 130%;
    color: #222222;

    p {
      margin: 0;
    }

    .discount {
      display: flex;
      align-items: center;
      gap: 10px;

      font-family: 'Nunito Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      color: #FF5980;
    }

  }

  // .seasons_collection {
  //   // display: flex;
  //   // flex-direction: column;
  //   // gap: 16px;
  // }
}