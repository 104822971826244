@import "../../../../../styles/fonts.scss";

.title {
  @extend %font_h2;
  text-align: left;
  width: min-content;
}

.info {
  max-width: 400px;
  margin-top: 80px;
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #5B637E;

  a {
    text-decoration: underline;
    cursor: pointer;
  }
}

.textInput {
  width: 400px;
  margin-bottom: 24px;
  margin-top: 32px;
}

.errorText {
  margin-top: 24px;
}

@media screen and (max-height: 900px) {
  .textInput {
    width: 300px;
  }
}


@media screen and (max-height: 770px) {
  .textInput {
    width: 230px;
    margin-bottom: 16px;
    margin-top: 24px;
    input {
      height: 40px !important;
      width: 100% !important;
    }
  }
  .errorText {
    margin-top: 30px;
    font-size: 12px !important;
  }
}

@media screen and (max-height: 645px) {
  .errorText {
    margin-top: 25px;
  }
}