// .title {
//   margin-bottom: 16px;

//   font-family: 'Nunito Sans';
//   font-style: normal;
//   font-weight: 700;
//   font-size: 16px;
//   line-height: 22px;
//   text-transform: uppercase;
//   color: #222222;
// }

.package_sizes {
  display: grid;
  grid-template-columns: auto 90px 90px 90px;
  gap: 24px;
  align-items: start;
}

.package_type {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 12px;
}