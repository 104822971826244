@import "../../../../../styles/colors";
@import "../../../../../styles/fonts";

h1 {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  text-transform: uppercase;
  color: #222222;
}

.info {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #5B637E;

  margin-bottom: 30px;
}

.textarea_label,
.title_label {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 18px;
  color: #A2ABC9;

  text-align: left;
  margin-bottom: 8px;
  margin-top: 16px;
}

.textarea {
  width: 840px;
  height: 120px;

  resize: none;
  padding: 16px;

  border: 1px solid rgba(162, 171, 201, 0.5);
  border-radius: 8px;
  outline: none;

  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #222222;
}

.templates {
  margin-top: 32px;
  display: flex;
  white-space: nowrap;
  gap: 16px;
  align-items: center;

  margin-bottom: 35px;

  .templates_label {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    color: #000000;
  }

  .list {
    display: flex;
    gap: 16px;
    align-items: center;
    overflow-y: auto;

    scrollbar-color: rgba($color-purple, .4) rgba($color-purple, .1);
    scrollbar-width: thin;
    scroll-behavior: smooth;

    user-select: none;

    &::-webkit-scrollbar {
      height: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba($color-purple, .4);
      border-radius: 8px;
    }

    &::-webkit-scrollbar-track {
      background-color: rgba($color-purple, .1);
      border-radius: 8px;
    }

    .template_label {
      font-family: 'Nunito Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 19px;
      color: #9B7FFC;

      display: flex;
      align-items: center;
      position: relative;
      overflow: hidden;
      flex-shrink: 0;

      padding: 8px 16px;
      gap: 8px;

      border: 2px solid #9B7FFC;
      border-radius: 100px;

      cursor: pointer;

      // img
      .x_icon {
        width: 24px;
        height: 24px;

        background-image: url('../../assets/x.svg');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
      }



      &::after {
        transition: opacity 0.2s ease-in-out;
        content: "";
        display: block;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        // background-image: linear-gradient(115.51deg, #4773EB 23.07%, #4773EB 90.77%);
        background-color: #9B7FFC;

        z-index: -1;
      }

      &:hover,
      &.active {
        &::after {
          opacity: 1;
        }

        background-color: #9B7FFC;
        border-color: #9B7FFC;
        color: #FFFFFF;

        .x_icon {
          width: 24px;
          background-image: url('../../assets/x_a.svg');
        }
      }

    }

  }
}