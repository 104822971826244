@import "../../../styles/backgrounds.scss";

.wrap {
  padding: 24px;
  display: flex;
  gap: 24px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 8px;

  margin-right: 30px
}

.upload {
  display: flex;
  gap: 32px;

  .info {
    display: flex;
    gap: 8px;
    flex-direction: row;
    align-items: center;

    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #5B637E;

    .icon {
      width: 24px;
      height: 24px;
    }
  }
}

.btns {
  display: flex;
  gap: 24px;
}

.footer {
  display: flex;
  justify-content: center;
}