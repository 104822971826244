.planning {

  .controls_row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .cost_of_delivery {
      display: flex;
      min-width: 237px;
      gap: 6px;
      padding: 16px;
      justify-content: space-between;
      align-items: center;

      border-radius: 8px;
      border: 1px solid rgba(162, 171, 201, 0.50);

      color: var(--gray-blue, #A2ABC9);
      font-family: Nunito Sans;
      font-size: 13px;
      font-style: normal;
      font-weight: 600;

      .cost {
        color: var(--gray-text, #8289A1);
        font-family: Nunito Sans;
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
      }
    }
  }

  .selector {
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;
  }

  .head {
    display: flex;
    gap: 72px;
    align-items: center;

    h2 {
      color: var(--black, #222);

      /* h2 */
      font-family: Nunito Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      text-transform: uppercase;
      text-align: left;
    }

    .map_btn {
      color: #9B7FFC;
      font-family: Nunito Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      text-decoration-line: underline;
      cursor: pointer;
    }
  }

  .variants {
    display: inline-flex;
    align-items: flex-start;
    flex-wrap: wrap;
    align-items: stretch;
    gap: 12px;
  }

  .controls {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 200px;
  }

  .panel {
    border-radius: 8px;
    border: 1px solid #9B7FFC;
    cursor: pointer;
    user-select: none;

    width: 300px;


    display: inline-flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    background: #FFF;


    &.selected,
    &:hover {
      background: linear-gradient(0deg, rgba(155, 127, 252, 0.12) 0%, rgba(155, 127, 252, 0.12) 100%), #FFF;
    }

    .title {
      color: var(--black, #222);
      font-family: Nunito Sans;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      /* 16.8px */
      text-transform: uppercase;
    }

    .text {
      color: var(--gray-dark-text, #5B637E);
      font-family: Nunito Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      /* 19.6px */
    }
  }
}