.dropzone {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 26px 32px;

  border: 1px dashed #A2ABC9;
  border-radius: 8px;

  // padding: 35px;

  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;

  cursor: pointer;

  .file {
    display: flex;
    align-items: center;
    gap: 10px;


    background: linear-gradient(310.28deg, #CC8AFF 0%, #8AB9FF 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;

    &::before {
      content: '';
      width: 24px;
      height: 24px;
      background: url('./assets/upload.svg');
    }
  }
}