@import "src/styles/fonts";
@import "src/styles/animations";

.modalContainer {
  position: fixed;
  top: 0;
  left: 0;

  font-family: 'Nunito Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;

  text-align: center;
  color: #222222;

  width: 100vw;
  height: 100vh;
  z-index: 100;
}

.modalUnderflow {
  position: relative;
  display: block;

  width: 100vw;
  height: 100vh;

  background-color: rgba(213, 213, 213, 0.9);
  backdrop-filter: blur(5px);

  z-index: 101;
}

.body {
  animation: slider-open 0.5s forwards;
  position: fixed;
  top: 10%;
  left: 50%;
  transform: translateX(-50%) scale(0);
  width: 920px;
  height: 80vh;

  padding: 40px;

  background-color: #FFFFFF;
  box-shadow: 8px 8px 40px rgba(131, 103, 228, 0.2);
  border-radius: 8px;

  max-height: 80vh;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  z-index: 200;
}

.close_btn {
  position: absolute !important;
  right: 32px;
  top: 32px;
}

.content {
  width: 520px;
  flex-grow: 1;

  overflow: auto;
}

.loading {
  font-size: 40px;
}

.title {
  margin-top: 80px;

  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  text-transform: uppercase;
  color: #222222;

  margin-bottom: 9px;
}

.period {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #222222;
}

.conditions {
  margin-top: 40px;

  overflow: auto;

  h3 {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #222222;
  }

  p {
    text-align: left;
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #5B637E;

    margin-top: 4px;
    margin-bottom: 12px;
  }
}

.conditions_title {
  margin-bottom: 12px;
}

.conditions_body {
  text-align: left;
}

.categories {
  h3 {
    margin-top: 12px;
    margin-bottom: 4px;
  }
}

.offer {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #5B637E;

  margin-top: 65px;
}

.kind {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #222222;
}

.discount_percent {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #5B637E;
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
  margin-top: 40px;
}