.packaging {
  display: flex;
  flex-direction: column;
  gap: 50px;

  .head {
    display: flex;
    justify-content: space-between;

    .dedline {
      color: var(--gray-dark-text, #5B637E);
      text-align: center;

      /* text */
      font-family: Nunito Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;

      .value {
        color: var(--black, #222);
      }
    }

    .cost_of_delivery {
      display: flex;
      gap: 10px;

      display: flex;
      padding: 16px;
      justify-content: space-between;
      align-items: center;

      border-radius: 8px;
      border: 1px solid rgba(162, 171, 201, 0.50);

      color: var(--gray-blue, #A2ABC9);
      font-family: Nunito Sans;
      font-size: 13px;
      font-style: normal;
      font-weight: 600;

      .cost {
        color: var(--gray-text, #8289A1);
      }
    }
  }

  .orders {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }

  .controls {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}