@import "../../../../../styles/colors";
@import "../../../../../styles/fonts";

.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px;
  gap: 12px;

  background: #FFFFFF;
  border-radius: 8px;

  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #222222;

  position: relative;
  height: 100%;

  transition: all .1s ease-in-out;

  &.selected {
    box-shadow: 8px 8px 40px rgba(131, 103, 228, 0.2);
    max-height: 387px;
    z-index: 1;
    height: auto;

    // .body textarea.text {
    //   max-height: 209px;
    //   overflow-y: auto;
    // }
  }

  .head {
    .shop {
      display: flex;
      align-items: center;
      gap: 16px;
    }

    .tmplates {
      display: flex;
      align-items: center;
      gap: 5px;
      height: 32px;

      .label {
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 19px;
        color: #222222;
      }

      .list {
        height: 35px;
        margin-bottom: -10px;

        display: flex;
        gap: 5px;
        overflow-x: auto;

        scrollbar-color: rgba($color-purple, .4) rgba($color-purple, .1);
        scrollbar-width: thin;
        scroll-behavior: smooth;

        &::-webkit-scrollbar {
          height: 6px;
        }

        &::-webkit-scrollbar-thumb {
          background-color: rgba($color-purple, .4);
          border-radius: 8px;
        }

        &::-webkit-scrollbar-track {
          background-color: rgba($color-purple, .1);
          border-radius: 8px;
        }
      }
    }
  }

  .body {
    flex-grow: 1;
    padding: 16px;
    border: 1px solid rgba(162, 171, 201, 0.5);
    border-radius: 8px;

    .text {
      max-height: 76px;
      overflow: hidden;
      white-space: pre-wrap;

      scrollbar-color: rgba($color-purple, .4) rgba($color-purple, .1);
      scrollbar-width: thin;
      scroll-behavior: smooth;

      &::-webkit-scrollbar {
        width: 6px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: rgba($color-purple, .4);
        border-radius: 8px;
      }

      &::-webkit-scrollbar-track {
        background-color: rgba($color-purple, .1);
        border-radius: 8px;
      }
    }

    textarea.text {
      width: 100%;
      height: 100%;
      resize: none;

      max-height: 209px;
      overflow-y: auto;

      // border: 1px solid rgba(162, 171, 201, 0.5);
      // border-radius: 8px;
      border: none;
      outline: none;

      font-family: 'Nunito Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      color: #222222;
      // padding: 16px;

      scrollbar-color: rgba($color-purple, .4) rgba($color-purple, .1);
      scrollbar-width: thin;
      scroll-behavior: smooth;

      &::-webkit-scrollbar {
        width: 6px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: rgba($color-purple, .4);
        border-radius: 8px;
      }

      &::-webkit-scrollbar-track {
        background-color: rgba($color-purple, .1);
        border-radius: 8px;
      }
    }
  }

  .row1 {
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: space-between;

    .warning {
      color: $color-pink;
    }

    .edit_btn {
      font-family: 'Nunito Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      color: #9B7FFC;

      display: flex;
      gap: 5px;
      align-items: center;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .shop {
    display: flex;
    gap: 5px;
    align-items: center;
    font-weight: 600;
  }

  .send_btn {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;

    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    color: #4773EB;

    &:hover {
      text-decoration: underline;
    }
  }

  .date {
    margin-top: 8px;
    color: #A2ABC9;
  }

  .footer {
    display: flex;
    justify-content: flex-start;
  }

  .controls {
    display: flex;
    gap: 5px;
    flex-direction: row;
  }
}