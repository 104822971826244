.passes {
  color: var(--gray-dark-text, #5B637E);
  font-family: Nunito Sans;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  table {
    margin-bottom: 24px;
    width: 100%;
  }

  th,
  td {
    border-bottom: 1px solid var(--gray, #EBECFF);
  }

  td {
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 40px;
  }

  th {
    color: var(--gray-text, #A2ABC9);
    font-size: 12px;
    font-weight: 700;
    // height: 30px;

    text-align: left;
    // vertical-align: middle;

    &.v {
      width: 50px;
      // width: 20px;
      height: 20px;
      background-image: url('./assets/v.svg');
      background-repeat: no-repeat;
      background-position-y: center;
      background-position-x: left;
    }

    &.fio {
      min-width: 255px;
    }

    &.car {
      min-width: 219px;
    }

    &.car_n {
      min-width: 150px;
    }

    &.phone {
      min-width: 150px;
    }

  }

}