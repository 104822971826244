@import "../../../../../../../styles/fonts.scss";
@import "../../../../../../../styles/colors.scss";
@import "../../../../../../../styles/backgrounds.scss";
@import "../../../../../../../styles/animations.scss";

.wrapper {
  flex-grow: 1;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px dotted $color-lilac;
  cursor: pointer;
  background: $bg_goods_list_btns_add;
  text-decoration: none;

  &:hover {
    animation: btn_add_goods 0.5s forwards;
    .txt {
      color: $color-white;
    }
  }
}
// .icons {}

.txt {
  @extend %font_button;
  text-align: center;
  height: 38px;
  width: 110px;
  color: $color-lilac;
}
