.banner {
  position: absolute;
  width: 86px;
  height: 67px;
  left: 0px;
  top: 0px;
}

.banner_text {
  position: absolute;
  width: 100%;
  font-family: 'Nunito Sans';
  font-style: normal;
  text-align: center;
  color: #FFFFFF;
}

.banner.xFFD75E .banner_text {
  color: #222222 !important;
}

// --------- square
.banner.square .banner_text {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 900;
  margin-top: 14%;
}

.banner.square.percent .banner_text {
  font-size: 24px;
}

.banner.square.text .banner_text {
  font-size: 20px;
}

// --------- circle
.banner.circle svg {
  margin-right: 4px; // fix svg
}

.banner.circle .banner_text {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 900;
  text-align: center;
  padding-right: 4px;
}

.banner.circle.percent .banner_text {
  font-size: 20px;
  margin-top: 20%;
}

.banner.circle.text .banner_text {
  margin-top: 20%;
  font-size: 15px;
}

// --------- triangle
.banner.triangle .banner_text {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 900;
  margin-top: 14%;
  font-size: 20px;
  transform: rotate(-45deg) translate(-12px, 4px);
}

// .banner.triangle.percent .banner_text {}
// .banner.triangle.text .banner_text {}