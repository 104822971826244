.remove {
  width: 420px;

  .head {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .info {
    margin-top: 16px;
    margin-bottom: 24px;


    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #222222;
  }

  .reason {
    margin-bottom: 60px;

    textarea {
      height: 120px;
    }
  }

  .warning {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #F35A91;
  }

  .btns {
    margin-top: 16px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 24px;
  }
}