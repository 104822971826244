.newboxbuilder_body {
  // display: flex;
  // flex-direction: column;
  // gap: 53px;
  // align-items: center;

  .inputs {
    display: flex;
    gap: 24px;
    flex-wrap: wrap;

    .input {
      width: 105px;
    }
  }
  .box_type {
    width: 234px;
  }
}