@import "../../../styles/fonts";

.root {
  position: relative;
  width: 30px;
  height: 30px;
  background-image: url("../../../assets/icons/trash2.png");
  background-size: contain;
  background-repeat: no-repeat;

  transition: background 0.2s linear;

  &:hover {
    background-image: url("../../../assets/icons/trash1.png");
    cursor: pointer;

    & .title {
      opacity: 1;
      visibility: visible;
    }
  }
}

.title {
  position: absolute;
  bottom: -72px;
  right: 0;
  background-color: #fff;
  box-shadow: 8px 8px 40px rgba(131, 103, 228, 0.2);
  border-radius: 8px;
  color: #5b637e;
  padding: 20px;
  white-space: nowrap;
  visibility: hidden;
  opacity: 0;
  z-index: 1000;

  @extend %font_input;

  transform: opacity 0.2s linear;
}
