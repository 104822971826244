@import "src/styles/fonts";
@import "src/styles/colors";

.btn {
  position: relative;
  width: 30px;
  height: 30px;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 30px;
    height: 2px;
    background-color: gray;
    transform: translate(-50%, -50%) rotate(-45deg);

    transform-origin: background-color 0.2s linear;
  }

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 30px;
    height: 2px;
    background-color: gray;
    transform: translate(-50%, -50%) rotate(45deg);

    transform-origin: background 0.2s linear;
  }

  &:hover {
    cursor: pointer;
    &::before {
      background-color: $color-pink;
    }

    &::after {
      background-color: $color-pink;
    }
  }
}