@import "../../../../../styles/colors.scss";
@import "../../../../../styles/fonts.scss";

.wrapper {
  box-sizing: border-box;
  height: 42px;
  border: 1px solid $color-light-border;
  border-radius: 5px;

  display: flex;
  justify-content: space-around;
  align-items: center;
  background: $color-white;
  border-radius: 8px;
}

.body {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
}

.input {
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;

  @extend %font_input;
  font-size: 14px;
  line-height: 19px;

  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  outline: 0 !important;
}

.btn_clear {
  cursor: pointer;

  width: 16px;
  height: 16px;

  background-image: url("../../../../../assets/icons/cancel_product_search_icon.svg");

  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.btn_clear.disabled {
  background-image: url("../../../../../assets/icons/search_icon.svg");
}