@import "../../../../../styles/colors";
@import "../../../../../styles/fonts";

.wrap {
  gap: 24px;
  display: flex;
  align-items: center;
  min-width: 0;

  user-select: none;
}

.menu_wrap {
  position: relative;
}

.menu {
  position: absolute;
  width: 545px;

  background: #FFFFFF;
  box-shadow: 8px 8px 40px rgba(131, 103, 228, 0.2);
  border-radius: 8px;

  padding: 24px;
  margin-top: 8px;

  z-index: 100;

  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 18px;
  color: #222222;

  &.questions {
    width: 272px;
  }

  .content {
    display: flex;
    gap: 10px;
  }

  .col {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;

    flex: 1 1 0;
  }
}

.list {
  display: flex;
  gap: 24px;

  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 18px;
  color: #222222;

  overflow-x: auto;

  scrollbar-color: rgba($color-purple, .4) rgba($color-purple, .1);
  scrollbar-width: thin;
  scroll-behavior: smooth;

  user-select: none;

  &::-webkit-scrollbar {
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba($color-purple, .4);
    border-radius: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: rgba($color-purple, .1);
    border-radius: 8px;
  }

  .item {
    border: 1px solid black;

  }
}

.underflow {
  position: fixed;
  left: 0;
  top: 0;
  display: block;
  width: 100%;
  height: 100%;

  z-index: -1;
}

.filter_label {
  height: 40px;
  display: flex;
  align-items: center;
  gap: 5px;
  white-space: nowrap;
  cursor: pointer;

  &.selected {
    padding-left: 16px;
    padding-right: 16px;
    border: 2px solid #A2ABC9;
    border-radius: 100px;
  }

  .deselect_btn {
    cursor: pointer;
  }
}

.list .filter_label.selected {
  border: none;
}

.filter_label_value {
  opacity: 0.5;
}