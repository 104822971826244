@import "../../../../../../styles/fonts";
@import "../../../../../../styles/colors";

.modal {
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 8;
  justify-content: center;
  display: flex;
  align-items: center;
  transform: scale(0);
}

.active {
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  justify-content: center;
  display: flex;
  align-items: center;
  transform: scale(1);
}

.modalContent {
  position: relative;
  width: 530px;
  height: 194px;
  border-radius: 8px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  div {
    margin: 40px 0;
    text-align: center;
    @extend %font_trash_placeholder;
  }
  .buttons {
    display: flex;

    button {
      width: 132px;
      height: 52px;
      background: $purpleGradient;
      border-radius: 50px;
      border: none;
      justify-content: center;
      align-items: center;
      padding: 16px 32px;
      margin: 0 8px;
      pointer-events: initial;
      @extend %font_white_paragraph;
    }

    .cancelBtn {
      background: $color-gray-blue;
    }
  }
}
