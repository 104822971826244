.wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // align-items: flex-end;
  gap: 32px;
  padding-top: 22px;
}

.body {
  border: 1px dashed #A2ABC9;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0 100px;
  // width: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  color: #9B7FFC;

  &.disabled {
    color: #A2ABC9;
  }
  .error {
    color: red;
  }
}

.footer {
  display: flex;
  flex-direction: row-reverse;
}

.text {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: center;
  margin-bottom: 24px;
}

.icon {
  width: 24px;
  height: 24px;
}

.uploaded_file {
  height: 40px;
  border: 2px solid #A2ABC9;
  border-radius: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding: 0 16px;

  .remove_button {
    width: 24px;
    height: 24px;
    cursor: pointer;
    background-image: url("data:image/svg+xml,%3Csvg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_2869_18717)'%3E%3Cpath d='M12.5 21C17.4706 21 21.5 16.9706 21.5 12C21.5 7.02944 17.4706 3 12.5 3C7.52944 3 3.5 7.02944 3.5 12C3.5 16.9706 7.52944 21 12.5 21Z' stroke='%23A2ABC9' stroke-width='2' stroke-miterlimit='10'/%3E%3Cpath d='M15.5 9L9.5 15' stroke='%23A2ABC9' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M15.5 15L9.5 9' stroke='%23A2ABC9' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_2869_18717'%3E%3Crect width='24' height='24' fill='white' transform='translate(0.5)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
  }
}