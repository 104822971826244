@import "../../../styles/colors.scss";
@import "../../../styles/fonts.scss";

.input {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 380px;
  height: 54px;
  padding: 12px 16px;
  border: 1px solid $color-light-border;
  border-radius: 8px;
  outline: none;

  &::placeholder {
    @extend %font_placeholder;
  }
  @extend %font_input;
}

.input.error {
  border: 1px solid #FF5980;
}