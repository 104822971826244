@import '../../../../../../../styles/visually-hidden';
@import './src/styles/fonts';

// .famousTags {
//     background-color: #ffffff;
//     padding: 30px 25px;
//     border-radius: 10px;
// }

.btn {
    margin: 45px 0 0 0;
    border: none;
    background-color: inherit;
    color: #9b7ffc;
    font-family: 'Nunito Sans';
    font-weight: 700;
}


.tagsNotFound {
    margin: 30px;
    display: flex;
    justify-content: center;
}


.dates {
    display: flex;
    gap: 16px;
}

.dates_wrap {
    display: flex;
    gap: 50px;
    align-items: center;
    margin-bottom: 32px;

    .label {
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        color: #222222;
    }
}

// .selectDateBlockBtn {
//     font-family: 'Nunito Sans';
//     font-weight: 700;
//     width: 145px;
//     max-height: 50px;
//     margin-left: 24px;
//     padding: 12px 24px;
//     border: none;
//     border-radius: 100px;
//     background-color: $color-gray;
//     height: 42px;
// }

// .selectDateBlockBtn:hover {
//     position: relative;
//     color: $color-white;
//     max-height: 50px;

//     background-color: transparent;
//     background-image: linear-gradient(
//         92.93deg,
//         $color-pink 2.99%,
//         $color-purple 94.03%
//     );

//     border: 0;
//     border-radius: 100px;

//     overflow: hidden;
// }