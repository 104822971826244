.grid {
  display: grid;
  gap: 24px;
  grid-template-columns: 1fr 100px 100px;
}

.compound {
  margin-bottom: 32px;
}

.compound_head {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 120%;

  color: #A2ABC9;

  user-select: none;

  margin-bottom: 6px;
}

// .compound_name::after {
//   content: "*";
//   height: 0;
//   color: red;
//   font-family: 'Nunito Sans';
//   font-style: normal;
//   font-weight: 700;
//   font-size: 13px;
//   line-height: 120%;
//   margin-left: 5px;
// }

.component {
  // display: flex;

  .controls {
    display: flex;
    gap: 12px;
    align-items: center;
    // height: 55px;


  }
}

.materials {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.add_btn {
  width: 32px;
  height: 32px;
  cursor: pointer;
  // margin-bottom: 5px;
}