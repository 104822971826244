@import "../../../styles/backgrounds.scss";

.wrapper__controls {
  display: flex;
  gap: 2%;
  margin-bottom: 3%;
  margin-right: 30px;
}

.body__controls {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  align-content: stretch;
  justify-content: space-between;
  gap: 2vh;
}

.wrapper__warning {
  width: 50%;
}

.wrapper__btns_navigate {
  margin-right: 30px;
}

.footer {
  height: 100px;
}