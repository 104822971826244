.delivery_orders {
  .controls {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .search {
      width: 450px;
    }

    .btns {
      display: flex;
      gap: 12px;
    }
  }

  .info {
    margin-top: 24px;
    color: #5B637E;

    /* text */
    font-family: Nunito Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    a {
      cursor: pointer;
      color: #9B7FFC;
      font-family: Nunito Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-decoration-line: underline;
    }
  }

  .tab_btns {
    display: flex;
    align-items: center;
    user-select: none;

    color: #A2ABC9;
    font-family: Nunito Sans;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;


    .tab_btn {
      cursor: pointer;
      display: flex;
      gap: 4px;
      align-items: center;
      padding: 2px 16px;
      color: var(--black, #222);
      border-radius: 20px;
      border: 2px solid #A2ABC900;
      transition: border 0.1s;
      color: #A2ABC9;

      .count {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 19px;
        height: 19px;
        background-color: rgba(162, 171, 201, 0.20);;
        border-radius: 100px;

        color: #8289A1;
        font-size: 10px;
        font-weight: 600;
      }

      &.selected {
        border: 2px solid #A2ABC9;
        color: #222;

        .count {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 19px;
          height: 19px;
          background-color: #A2ABC9;
          border-radius: 100px;

          color: #FFF;
        }
      }


    }
  }

  .content {
    margin-top: 23px;
  }
}