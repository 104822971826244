.wrap {

  .isuniq {
    margin-top: 24px;
    margin-bottom: 64px;
    text-align: center;
  }


  .statistic {
    position: relative;

    .iscategory {
      position: absolute;
      right: 10px;
      top: 10px;

      font-family: 'Nunito Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 140%;
      text-align: right;
      color: #5B637E;
    }

    h1 {
      font-family: 'Nunito Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 33px;
      text-transform: uppercase;
      text-align: left;

      color: #222222;

      margin-top: 48px;
      margin-bottom: 24px;

    }

    .panels {
      // display: flex;
      // gap: 24px;
      // justify-content: space-between;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 24px 24px;
      justify-content: space-between;

      .panel {
        display: flex;
        flex-direction: column;

        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        color: #222222;
        flex-grow: 1;
        flex-shrink: 0;
        // flex-basis: 0;

        .panel_head {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          padding: 16px;
          gap: 16px;

          height: 52px;

          background: linear-gradient(310.28deg, #CC8AFF 0%, #8AB9FF 100%);
          border-radius: 8px 8px 0px 0px;


          font-weight: 700;
          font-size: 14px;
          line-height: 140%;
          text-transform: uppercase;
          color: #FFFFFF;
        }

        .panel_body {
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          box-sizing: border-box;

          background: #FFFFFF;
          border: 1px solid rgba(162, 171, 201, 0.5);
          border-radius: 0px 0px 8px 8px;

          .group {
            padding: 16px;
            // flex-grow: 1;
            // flex-shrink: 0;
            // min-height: 50%;

            &:first-child {
              border-bottom: 1px solid #A2ABC980;
            }

            .panel_datarow {
              display: flex;
              gap: 6px;
              align-items: center;

              &.growth::after,
              &.reduction::after {
                content: '';
                width: 10px;
                height: 10px;
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
              }

              &.growth::after {
                background-image: url('./assets/g.svg');
              }

              &.reduction::after {
                background-image: url('./assets/r.svg');
              }
            }
          }
        }
      }
    }
  }
}