.rating {
  display: flex;
  gap: 10px;
  height: 51px;
  padding: 16px;

  background: #FFD75E;
  border-radius: 8px;

  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #222222;

  white-space: nowrap;

  .value {
    font-weight: 700;
  }
}