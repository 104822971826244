@import "../../../../../../../../styles/fonts";
@import "../../../../../../../../styles/colors";

.inner {
  display: flex;

  .wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $color-gray;
    border-radius: 8px;
    flex: none;
    margin-bottom: 16px;
    box-sizing: border-box;
    transition: 0.5s;

    &.selected {
      border-radius: 12px;
      border: 2px solid $color-purple;
    }

    &:hover {
      .edit {
        opacity: 1;
        pointer-events: inherit;
      }
    }

    .logo {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      img {
        width: 80px;
        height: 80px;
        margin: 12px;
      }

      div {
        font-weight: 600;
        @extend %font_h3
      }
    }

    .edit {
      position: absolute;
      top: 10px;
      right: 10px;
      width: 18px;
      height: 18px;
      opacity: 0;
      cursor: pointer;
      pointer-events: none;
      transition: ease .4s;
      stroke: $color-gray-dark;

      .active {
        stroke: $color-pink;
      }

      &:hover {
        .info {
          opacity: 1;
          transform: translateY(0);
          pointer-events: inherit;
        }
      }

      .info {
        position: absolute;
        top: 29px;
        left: 0;
        width: 164px;
        height: 70px;
        padding: 24px;
        background: white;
        box-shadow: 8px 8px 40px rgba(131, 103, 228, 0.2);
        border-radius: 8px;
        opacity: 0;
        pointer-events: none;
        transform: translateY(-10%);
        transition: ease-in .2s;
      }
    }
  }

  .title {
    margin: 10px 0 0 16px;

    .name{
      @extend %font_h3
    }

    .setLogoForProduct {
      padding: 8px 0;
      margin: 0;
      cursor: pointer;
      @extend %font_purple_paragraph
    }
  }
}