.wrap {
  position: relative;
  height: 8px;
  display: flex;

  background-color: #EBECFF;
  background-image: linear-gradient(270deg, #FE57AB 0%, #FFD75D 33.33%, #47F2A7 66.67%, #32BBFC 100%);

  border-radius: 12px;

  margin-right: 10px;
  overflow: hidden;
}

.progress_over {
  flex-grow: 1;
  background-color: #EBECFF;
}
.progress {
  height: 100%;
  border-radius: 12px;
  background-color: transparent;
}

